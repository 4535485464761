import { useCallback } from 'react'

import { computeAgeService } from '~/services/computeAgeService'

import { Evaluation } from '../evalutation/types'

export function useIMCFormula(evaluation?: Evaluation) {
  const calcIMC = useCallback((): number => {
    if (!evaluation?.currentWeight || !evaluation?.stature) return 0
    return +(
      evaluation.currentWeight /
      (evaluation.stature / 100) ** 2
    ).toFixed(1)
  }, [evaluation?.currentWeight, evaluation?.stature])

  const calcIMCClassificacao = useCallback((): Classification => {
    const imc = calcIMC()

    if (imc < 18.5) return { label: 'Baixo peso', color: 'lime' }
    else if (imc < 24.9) return { label: 'Normal', color: 'green' }
    else if (imc < 29.9) return { label: 'Sobrepeso', color: 'orange' }
    else if (imc < 34.9) return { label: 'Obesidade I', color: 'amber' }
    else if (imc < 39.9) return { label: 'Obesidade II', color: 'red' }
    return { label: 'Obesidade Mórbida', color: 'purple' }
  }, [calcIMC])

  const calcICM_CC_Classificacao = useCallback((): Classification => {
    const imc = calcIMC()

    if (!evaluation?.waist) {
      return {
        label: 'Extremamente alto',
        color: 'purple',
      }
    }

    if (imc < 24.9) return { label: 'Sem Risco', color: 'none' }

    if (imc < 29.9) {
      if (evaluation?.student.sex === 'masculino' && evaluation.waist <= 102) {
        return { label: 'Aumentado', color: 'amber' }
      }
      if (evaluation.waist <= 88) {
        return { label: 'Aumentado', color: 'amber' }
      }
      return { label: 'Alto', color: 'orange' }
    }

    if (imc < 34.9) {
      if (evaluation?.student.sex === 'masculino' && evaluation.waist <= 102) {
        return { label: 'Alto', color: 'orange' }
      }
      if (evaluation.waist <= 88) {
        return { label: 'Alto', color: 'orange' }
      }
      return { label: 'Muito alto', color: 'red' }
    }

    if (imc < 39.9) {
      return {
        label: 'Muito alto',
        color: 'red',
      }
    }

    return {
      label: 'Extremamente alto',
      color: 'purple',
    }
  }, [calcIMC, evaluation])

  const calcIRCQ = useCallback((): Classification => {
    if (!evaluation?.hip || !evaluation?.waist)
      return { label: '...', color: 'none', value: 0 }

    const ircq = +(evaluation.waist / evaluation.hip).toFixed(2)

    const age = computeAgeService(evaluation?.student.birthday)

    if (age <= 29) {
      if (evaluation?.student.sex === 'masculino') {
        if (ircq <= 0.83) {
          return { label: 'Baixo', color: 'lime', value: ircq }
        }
        if (ircq <= 0.88) {
          return { label: 'Moderado', color: 'green', value: ircq }
        }
        if (ircq <= 0.94) {
          return { label: 'Alto', color: 'amber', value: ircq }
        }
      }

      if (ircq <= 0.71) {
        return { label: 'Baixo', color: 'lime', value: ircq }
      }
      if (ircq <= 0.77) {
        return { label: 'Moderado', color: 'green', value: ircq }
      }
      if (ircq <= 0.82) {
        return { label: 'Alto', color: 'amber', value: ircq }
      }

      return { label: 'Muito Alto', color: 'red', value: ircq }
    }

    if (age <= 39) {
      if (evaluation?.student.sex === 'masculino') {
        if (ircq <= 0.84) {
          return { label: 'Baixo', color: 'lime', value: ircq }
        }
        if (ircq <= 0.91) {
          return { label: 'Moderado', color: 'green', value: ircq }
        }
        if (ircq <= 0.96) {
          return { label: 'Alto', color: 'amber', value: ircq }
        }
      }

      if (ircq <= 0.72) {
        return { label: 'Baixo', color: 'lime', value: ircq }
      }
      if (ircq <= 0.78) {
        return { label: 'Moderado', color: 'green', value: ircq }
      }
      if (ircq <= 0.84) {
        return { label: 'Alto', color: 'amber', value: ircq }
      }

      return { label: 'Muito Alto', color: 'red', value: ircq }
    }

    if (age <= 49) {
      if (evaluation?.student.sex === 'masculino') {
        if (ircq <= 0.88) {
          return { label: 'Baixo', color: 'lime', value: ircq }
        }
        if (ircq <= 0.95) {
          return { label: 'Moderado', color: 'green', value: ircq }
        }
        if (ircq <= 1.0) {
          return { label: 'Alto', color: 'amber', value: ircq }
        }
      }

      if (ircq <= 0.73) {
        return { label: 'Baixo', color: 'lime', value: ircq }
      }
      if (ircq <= 0.79) {
        return { label: 'Moderado', color: 'green', value: ircq }
      }
      if (ircq <= 0.87) {
        return { label: 'Alto', color: 'amber', value: ircq }
      }

      return { label: 'Muito Alto', color: 'red', value: ircq }
    }

    if (age <= 59) {
      if (evaluation?.student.sex === 'masculino') {
        if (ircq <= 0.9) {
          return { label: 'Baixo', color: 'lime', value: ircq }
        }
        if (ircq <= 0.96) {
          return { label: 'Moderado', color: 'green', value: ircq }
        }
        if (ircq <= 1.02) {
          return { label: 'Alto', color: 'amber', value: ircq }
        }
      }

      if (ircq <= 0.74) {
        return { label: 'Baixo', color: 'lime', value: ircq }
      }
      if (ircq <= 0.81) {
        return { label: 'Moderado', color: 'green', value: ircq }
      }
      if (ircq <= 0.88) {
        return { label: 'Alto', color: 'amber', value: ircq }
      }

      return { label: 'Muito Alto', color: 'red', value: ircq }
    }

    if (evaluation?.student.sex === 'masculino') {
      if (ircq <= 0.91) {
        return { label: 'Baixo', color: 'lime', value: ircq }
      }
      if (ircq <= 0.98) {
        return { label: 'Moderado', color: 'green', value: ircq }
      }
      if (ircq <= 1.03) {
        return { label: 'Alto', color: 'amber', value: ircq }
      }
    }

    if (ircq <= 0.76) {
      return { label: 'Baixo', color: 'lime', value: ircq }
    }
    if (ircq <= 0.83) {
      return { label: 'Moderado', color: 'green', value: ircq }
    }
    if (ircq <= 0.9) {
      return { label: 'Alto', color: 'amber', value: ircq }
    }

    return { label: 'Muito Alto', color: 'red', value: ircq }
  }, [evaluation])

  return {
    targetVO2: evaluation?.targetVO2,
    calcIMC,
    calcIMCClassificacao,
    calcICM_CC_Classificacao,
    calcIRCQ,
  }
}
