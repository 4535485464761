import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { menuStateAtom } from '../state'

export const ProfileButtons: React.FC = () => {
  const navigate = useNavigate()

  const handleGoBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleHideMenu = useCallback(() => {
    menuStateAtom.set(false)
  }, [])
  return (
    <>
      <li className='flex w-full'>
        <Link
          replace
          onClick={handleHideMenu}
          to={'/'}
          className='font-normal p-4 w-full'
        >
          Painel de alunos
        </Link>
      </li>
      <div className='flex gap-2 justify-center'>
        <button
          className='bg-white rounded-md px-3 active:bg-slate-100 hover:bg-slate-50 text-black hidden md:block'
          onClick={handleGoBack}
        >
          <span className='text-black font-medium pointer-events-none'>
            Voltar
          </span>
        </button>
      </div>
    </>
  )
}
