import { AnimatePresence, motion } from 'framer-motion'
import { useCallback } from 'react'
import { FiMenu, FiX } from 'react-icons/fi'
import { Link, Outlet, Route, Routes } from 'react-router-dom'

import GPALogo from '~/assets/img/gpaLogo.png'
import { signOut } from '~/modules/authentication/services'
import { useAtom } from '~/observers/jotai'
import {
  personalHomeRoute,
  personalTrainerProfileRoute,
  studentEvaluationsRoute,
} from '~/routes/routes'
import { app_version } from '~/utils/version'

import { DropDownMenu } from './components/DropDownMenu'
import { RegisterStudentButton } from './components/RegisterStudentButton'
import { EvaluationButtons } from './controls/EvaluationButtons'
import { ProfileButtons } from './controls/ProfileButtons'
import { menuStateAtom } from './state'

export const TopBar: React.FC = () => {
  const [showMenu, setShowMenu] = useAtom(menuStateAtom)

  const handleToggleMenu = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu, setShowMenu])

  const handleSignOut = useCallback(() => {
    signOut()
    setShowMenu(false)
  }, [setShowMenu])

  return (
    <Routes>
      <Route
        path='/*'
        element={
          <nav className='sticky top-0 flex justify-end gap-3 px-6 shadow-sm py-2.5 bg-white z-20 transition-all duration-700 md:flex-row flex-col md:items-center'>
            <div className='flex flex-1'>
              <Link to={personalHomeRoute} className='group relative'>
                <span className='absolute p-1 px-2 rounded-md bg-black bg-opacity-80 opacity-0 text-white translate-x-4 translate-y-5 group-hover:opacity-100 group-hover:translate-y-11 group-hover:delay-1000 transform-gpu duration-200'>
                  {app_version}
                </span>
                <img
                  src={GPALogo}
                  alt='GPA'
                  className='h-10 mr-auto w-20 object-contain'
                />
              </Link>
              <button
                onClick={handleToggleMenu}
                className='md:hidden p-2 ml-auto'
              >
                <FiMenu className='text-xl cursor-pointer' />
              </button>
            </div>

            {/* {showMenu ? (
              <div className='md:hidden space-y-3'>
                <SideBarMenu />
                <Outlet />
              </div>
            ) : null} */}

            <div className='md:hidden'>
              <AnimatePresence>
                {showMenu ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{
                        ease: [0, 0.71, 0.2, 1],
                      }}
                      onClick={handleToggleMenu}
                      className='fixed -top-3 left-0 bottom-0 right-0 z-[999] bg-black bg-opacity-40 backdrop-blur-sm'
                    />
                    <motion.div
                      initial={{
                        transform: 'translate3d(-100%, 0px, 0)',
                        boxShadow: '10px 0px 10px rgba(0,0,0,0)',
                      }}
                      animate={{
                        transform: 'translate3d(0%, 0px, 0) ',
                        boxShadow: '10px 0px 10px rgba(0,0,0,0.2)',
                      }}
                      exit={{
                        transform: 'translate3d(-100%, 0px, 0)',
                        boxShadow: '10px 0px 10px rgba(0,0,0,0)',
                      }}
                      transition={{
                        ease: [0, 0.71, 0.2, 1],
                      }}
                      className='group fixed -top-3 py-2 bottom-0 left-0 w-full max-w-[320px] bg-white z-[999]'
                    >
                      <ul>
                        <li className='flex w-full p-4 focus-within:bg-slate-100'>
                          <button
                            onClick={handleToggleMenu}
                            className='flex flex-1 outline-none'
                          >
                            <span className='text-xl font-semibold'>Menu</span>
                            <FiX size={24} className='ml-auto' />
                          </button>
                        </li>

                        <Outlet />

                        <li className='flex w-full focus-within:bg-slate-100'>
                          <button
                            onClick={handleSignOut}
                            className='flex-1 text-left outline-none p-4'
                          >
                            Sair do GPA
                          </button>
                        </li>
                      </ul>
                    </motion.div>
                  </>
                ) : null}
              </AnimatePresence>
            </div>

            <div className='hidden md:flex gap-3'>
              <DropDownMenu />
              <Outlet />
            </div>
          </nav>
        }
      >
        <Route path='/*/painel' element={<RegisterStudentButton />} />
        <Route
          path={`/*/${studentEvaluationsRoute}/*`}
          element={<EvaluationButtons />}
        />
        <Route
          path={`/*/${personalTrainerProfileRoute}/*`}
          element={<ProfileButtons />}
        />
      </Route>
    </Routes>
  )
}
