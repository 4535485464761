import ApexChart from 'react-apexcharts'

import { Evaluation } from '~/modules/evalutation/types'

type Props = {
  data: Evaluation | undefined
}

export const ChartAnthropometricMeasurements: React.FC<Props> = ({ data }) => {
  const categories = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
  ]

  const series = [
    {
      name: 'series',
      data: [
        data?.shoulder || 0, // 1
        data?.chestInflated || 0, // 2
        data?.chestRegular || 0, // 3
        data?.hip || 0, // 4
        data?.abdomen || 0, // 5
        data?.waist || 0, // 6
        data?.rightThigh || 0, // 7
        data?.leftThigh || 0, // 8
        data?.rightLeg || 0, // 9
        data?.leftLeg || 0, // 10
        data?.rightArmContracted || 0, // 11
        data?.leftArmContracted || 0, // 12
        data?.rightArmRelaxed || 0, // 13
        data?.leftArmRelaxed || 0, // 14
        data?.rightForearm || 0, // 15
        data?.leftForearm || 0, // 16
      ],
    },
  ]

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      id: 'basic-bar',
    },

    legend: {
      show: false,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    colors: [
      '#9c4c4c',
      '#FFA000',
      '#388E3C',
      '#689F38',
      '#00B8D4',
      '#0c94e9',
      '#170ce9',
      '#730ce9',
      '#b20ce9',
      '#e90cd3',
      '#e90c59',
      '#c72626',
      '#88ad38',
      '#46a23c',
      '#3da077',
      '#3a6196',
      '#3b3993',
      '#8d3b86',
    ],
    plotOptions: {
      bar: {
        columnWidth: '65%',
        distributed: true,
      },
    },
  }

  return (
    <div className='h-96 w-full max-w-[190mm]'>
      <ApexChart
        type='bar'
        height={320}
        width='100%'
        series={series}
        options={chartOptions}
      />
    </div>
  )
}
