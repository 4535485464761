import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { Link } from 'react-router-dom'

import { Button } from '~/components/Button'
import { personalTrainerProfileRoute } from '~/routes/routes'

import { menuStateAtom } from '../../state'
import { RegisterStudentModal } from './RegisterStudentModal'

export const RegisterStudentButton: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setIsModalOpen((state) => !state)
  }, [])

  const handleHideMenu = useCallback(() => {
    menuStateAtom.set(false)
  }, [])

  return (
    <>
      <li className='flex md:hidden'>
        <button
          className='bg-gpa-blue-500 hover:bg-blue-500 w-full p-2 text-left font-semibold text-white'
          onClick={handleToggleModal}
        >
          Novo Aluno
        </button>
      </li>

      {/* <li className='flex w-full'>
        <Link
          onClick={handleHideMenu}
          to={personalTrainerProfileRoute}
          className='font-normal p-4 w-full'
        >
          Perfil
        </Link>
      </li> */}

      <Button
        onClick={handleToggleModal}
        className='hidden md:flex md:mx-auto my-auto'
      >
        Novo Aluno
      </Button>
      {createPortal(
        <RegisterStudentModal
          onClose={handleToggleModal}
          isOpen={isModalOpen}
        />,
        document.getElementById('modals')!,
      )}
    </>
  )
}
