import { ChangeEvent, useCallback, useState } from 'react'

import { Button } from '~/components/Button'
import { divideSeries } from '~/modules/evalutation/services'
import { strengthMacrocycleAtom } from '~/modules/standardizedSeries/data'
import { useAtom } from '~/observers/jotai'

export const Strength: React.FC = () => {
  const [data] = useAtom(strengthMacrocycleAtom)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [inputValue, setInputValue] = useState<number>(1)

  const handleIncrementePage = useCallback(() => {
    setCurrentPage((state) => {
      if (state >= data.length - 1) return state
      setInputValue(state + 2)
      return state + 1
    })
  }, [data])

  const handleDecrementPage = useCallback(() => {
    setCurrentPage((state) => {
      if (state === 0) return state
      setInputValue(state)
      return state - 1
    })
  }, [])

  const handleUpdatePage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = +e.target.value.replace(/\D/, '')

      if (value > data.length) {
        setInputValue(data.length)
        setCurrentPage(data.length - 1)
      } else if (value <= 0) {
        setInputValue(value)
      } else {
        setInputValue(value)
        setCurrentPage(value - 1)
      }
    },
    [data.length],
  )

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex items-center gap-6 self-end'>
        <Button onClick={handleDecrementPage}>Anterior</Button>

        <div className='flex items-center gap-2'>
          <input
            className='border-slate-200 border-2 w-16 rounded-md h-[30px] px-2 outline-none focus:border-gpa-blue-500'
            value={inputValue}
            type='text'
            onChange={handleUpdatePage}
          />
          <span>/ {data.length}</span>
        </div>
        <Button onClick={handleIncrementePage}>Próximo</Button>
      </div>
      {data.slice(currentPage, currentPage + 1).map((d, index) => (
        <div key={`session-${index + 1}`}>
          {d.map((s) => (
            <div
              key={`session-${s.letter}-${index}`}
              className='mb-6 border border-slate-300 rounded-md overflow-hidden'
            >
              <p className='bg-slate-100 font-bold text-center py-2'>
                Mesociclo {s.mesocycle} / Microciclo {s.microcycle} - Série{' '}
                {s.letter} Sessão - {currentPage + 1}
              </p>
              <div className='flex w-full overflow-x-auto'>
                <table className='w-full min-w-[860px]'>
                  <thead>
                    <tr className='bg-slate-300'>
                      <td className='pl-2'>Exercício</td>
                      <td className='pl-2'>Carga</td>
                      <td className='pl-2'>Séries</td>
                      <td className='pl-2'>Total Rep.</td>
                      <td className='pl-2'>Execução da Rep.</td>
                      <td className='pl-2'>Intervalo</td>
                      <td className='pl-2'>Velocidade</td>
                      <td className='pl-2'>Ajuste/Obs</td>
                    </tr>
                  </thead>
                  <tbody>
                    {s.exercises.map((e, ei) => (
                      <tr
                        key={`${index + 1}-${s.letter}-${ei}`}
                        className='even:bg-slate-100'
                      >
                        <td className='pl-2'>{e.name}</td>
                        <td className='pl-2'>{e.initialCharge}</td>
                        <td className='pl-2'>{e.numSeries}</td>
                        <td className='pl-2'>{e.repetitions}</td>
                        <td className='pl-2'>
                          {divideSeries(e.repetitions, e.numSeries)}
                        </td>
                        <td className='pl-2'>{e.interval}</td>
                        <td className='pl-2'>{e.speed}</td>
                        <td className='pl-2'>{e.adjustments}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
