import { format } from 'date-fns'
import { useCallback, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { FiX } from 'react-icons/fi'

import { listPersonalTrainerLicenseChanges } from '~/modules/admin/services'
import { PersonalTrainerLicense } from '~/modules/admin/types'

import { Button } from '../Button'
import { Modal } from '../Modal'
import { context } from './contex'
import { EditPersonalTrainerModal } from './EditPersonalTrainerModal'
import { RegisterLicenseModal } from './RegisterLicenseModal'

type Props = {
  onDismiss: () => void
}

let abortController = new AbortController()

export const DetailsModal: React.FC<Props> = ({ onDismiss }) => {
  const { personalTrainer } = useContext(context)
  const [loading, setLoading] = useState<boolean>(true)
  const [licenses, setLicenses] = useState<PersonalTrainerLicense[]>([])

  const [showNewLicenseModal, setShowNewLicenseModal] = useState<boolean>(false)
  const handleToggleLicenseModal = useCallback(() => {
    setShowNewLicenseModal((state) => !state)
  }, [])

  const [showEditPersonalTrainer, setShowEditPersonalTrainer] =
    useState<boolean>(false)
  const handleToggleEditPersonalTrainer = useCallback(() => {
    setShowEditPersonalTrainer((state) => !state)
  }, [])

  const handleUpdateLicenses = useCallback(
    (licenseChange: PersonalTrainerLicense) => {
      setLicenses((state) => [licenseChange, ...state])
    },
    [],
  )

  useEffect(() => {
    ;(async () => {
      abortController = new AbortController()
      setLoading(true)
      await listPersonalTrainerLicenseChanges(
        personalTrainer.id,
        abortController,
      ).then((data) => {
        data && setLicenses(data)
        setLoading(false)
      })
    })()

    return () => {
      abortController.abort()
    }
  }, [personalTrainer.id])

  return (
    <>
      <div className='flex items-center justify-between mb-4'>
        <h1 className='text-xl'>Personal Trainer &bull; Detalhes</h1>
        <button
          type='button'
          className='p-2 rounded-full hover:bg-slate-50'
          onClick={onDismiss}
        >
          <FiX size={26} />
        </button>
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-4 flex-wrap'>
          <div className='flex-1'>
            <span>
              <b>Nome</b>
            </span>
            <p>{personalTrainer.name}</p>
          </div>
          <div className='flex-1'>
            <span>
              <b>CPF</b>
            </span>
            <p>{personalTrainer.documentNumber}</p>
          </div>
        </div>
        <div className='flex gap-4 flex-wrap'>
          <div className='flex-1'>
            <span>
              <b>E-mail</b>
            </span>
            <p>{personalTrainer.email}</p>
          </div>
          <div className='flex-1'>
            <span>
              <b>Limite da Licença</b>
            </span>
            <p>{personalTrainer.personalTrainerLicense[0].finalAmount}</p>
          </div>
        </div>

        <Button onClick={handleToggleEditPersonalTrainer}>
          Atualizar Personal Trainer
        </Button>

        <div className='h-0.5 w-full bg-slate-200'></div>

        {loading ? (
          <div className='w-full min-w-[270px] overflow-x-auto p-4 bg-blue-50 rounded-lg'>
            <div className='mx-auto border-4 border-gpa-blue-500 border-r-transparent h-8 w-8 rounded-full animate-spin'></div>
          </div>
        ) : (
          <div className='flex pb-4 w-full min-w-[270px] overflow-x-auto'>
            <table className='w-full rounded-md overflow-hidden'>
              <thead className='bg-blue-300'>
                <tr className='text-sm table-fixed'>
                  <th className='whitespace-nowrap py-1 px-2'>Criação</th>
                  <th className='whitespace-nowrap px-2'>Operação</th>
                  <th className='whitespace-nowrap px-2'>Qnt. Inicial</th>
                  <th className='whitespace-nowrap px-2'>Qnt. Alterada</th>
                  <th className='whitespace-nowrap px-2'>Qnt. Final</th>
                  <th className='whitespace-nowrap px-2'>Origem</th>
                </tr>
              </thead>
              <tbody>
                {licenses.slice(0, 10).map((license) => (
                  <tr key={license.id} className='text-sm even:bg-blue-50'>
                    <td className='px-2 py-2'>
                      {format(new Date(license.createdAt), 'dd/MM/yyyy')}
                    </td>
                    <td className='px-2'>{license.operation}</td>
                    <td className='px-2'>{license.previousAmount}</td>
                    <td
                      data-decrement={
                        license.operation === 'dec_number_of_customers'
                      }
                      className='px-2 data-[decrement=true]:text-gpa-red'
                    >
                      {license.amountChanged *
                        (license.operation === 'dec_number_of_customers'
                          ? -1
                          : 1)}
                    </td>
                    <td className='px-2'>{license.finalAmount}</td>
                    <td className='px-2'>{license.origin}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <EditPersonalTrainerModal
          visible={showEditPersonalTrainer}
          onDismiss={handleToggleEditPersonalTrainer}
        />

        <Button type='button' onClick={handleToggleLicenseModal}>
          Incluir nova alteração na licença
        </Button>
        {createPortal(
          <Modal visible={showNewLicenseModal}>
            <RegisterLicenseModal
              onDismiss={handleToggleLicenseModal}
              onSubmit={handleUpdateLicenses}
            />
          </Modal>,
          document.getElementById('modals')!,
        )}
      </div>
    </>
  )
}
