import zod from 'zod'

export const directSchema = zod.object({
  direct: zod.number().min(1),
})
export const pollockJacksonTrifoldSchema = zod.object({
  triceptal: zod.array(zod.number().min(1)).length(3),
  suprailiac: zod.array(zod.number().min(1)).length(3),
  medialThigh: zod.array(zod.number().min(1)).length(3),
})
export const pollockJacksonSevenfoldSchema = zod.object({
  subscapular: zod.array(zod.number().min(1)).length(3),
  triceptal: zod.array(zod.number().min(1)).length(3),
  chest: zod.array(zod.number().min(1)).length(3),
  axilla: zod.array(zod.number().min(1)).length(3),
  suprailiac: zod.array(zod.number().min(1)).length(3),
  abdominal: zod.array(zod.number().min(1)).length(3),
  medialThigh: zod.array(zod.number().min(1)).length(3),
})
export const wetmanColSchema = zod.object({
  abdomen: zod.number().min(1),
})
export const faulknerSchema = zod.object({
  subscapular: zod.array(zod.number().min(1)).length(3),
  triceptal: zod.array(zod.number().min(1)).length(3),
  suprailiac: zod.array(zod.number().min(1)).length(3),
  abdominal: zod.array(zod.number().min(1)).length(3),
})
export const coteWilmoreSchema = zod.object({
  abdomen: zod.number().min(1),
  rightFist: zod.number().min(1),
})
export const penroeNelsonFischerSchema = zod.object({
  abdomen: zod.number().min(1),
  hip: zod.number().min(1),
})
