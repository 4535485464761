import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useState,
} from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'

import { ITextInputProps } from './ITextInputProps'

const Input: ForwardRefRenderFunction<HTMLInputElement, ITextInputProps> = (
  {
    label,
    type = 'text',
    error,
    disabled,
    mode = 'vertical',
    noError = false,
    rightIconArea,
    className,
    containerClassName,
    ...rest
  },
  ref,
) => {
  const [hidePassword, setHidePassword] = useState<boolean>(true)

  const handleHidePassword = useCallback(() => {
    setHidePassword(() => true)
  }, [])

  const handleShowPassword = useCallback(() => {
    setHidePassword(() => false)
  }, [])

  return (
    <div className='flex flex-1 flex-col'>
      <div
        data-mode={mode}
        aria-disabled={disabled}
        className={
          'flex flex-col data-[mode=horizontal]:flex-row data-[mode=horizontal]:items-center flex-1 gap-3 min-w-[80px] aria-disabled:opacity-50 aria-disabled:cursor-not-allowed'
        }
      >
        {label ? (
          <span
            data-error={!!error}
            data-mode={mode}
            className='data-[error=true]:text-gpa-red data-[mode=horizontal]:max-w-[140px] data-[mode=horizontal]:w-[fit-content]'
          >
            {label}
          </span>
        ) : null}
        <label
          data-error={!!error}
          data-disabled={disabled}
          className={`flex  bg-white items-center flex-row gap-2 px-2 py-2 h-10 border-500 group focus-within:border-gpa-blue-500 border-2 rounded-md data-[error=true]:!border-gpa-red data-[disabled=true]:pointer-events-none ${containerClassName}`}
        >
          <div className='flex flex-1'>
            <input
              {...rest}
              disabled={disabled}
              ref={ref}
              type={type === 'password' && !hidePassword ? 'text' : type}
              autoComplete='off'
              className={`w-full h-full flex-1 outline-none bg-transparent autofill:!bg-transparent ${className}`}
            />
          </div>

          {rightIconArea?.length ? (
            <div>{rightIconArea?.map((icon) => icon)}</div>
          ) : null}
          {type === 'password' ? (
            <button
              type='button'
              className='flex justify-center items-center select-none'
              onClick={hidePassword ? handleShowPassword : handleHidePassword}
            >
              {hidePassword ? (
                <FiEye
                  size={23}
                  className={
                    '' + (error ? 'text-gpa-red' : 'text-gpa-gray-800')
                  }
                />
              ) : (
                <FiEyeOff
                  size={23}
                  className={
                    '' + (error ? 'text-gpa-red' : 'text-gpa-gray-800')
                  }
                />
              )}
            </button>
          ) : null}
        </label>
      </div>
      {noError ? null : (
        <span
          className={`flex text-xs text-gpa-red ${
            error ? 'visible' : 'invisible'
          }`}
        >
          {error}&nbsp;
        </span>
      )}
    </div>
  )
}

export const TextInput = forwardRef(Input)
