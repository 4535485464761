import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { RadioButtonArray } from '~/components/RadioButtonArray'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  patchEvaluation,
  patchEvaluationAtom,
} from '~/modules/evalutation/services'
import { useAtom } from '~/observers/jotai'
import { formsLayoutContext } from '~/pages/authenticated/personalTrainer/studentEvaluation/layout'
import { EvaluationRouteParams } from '~/routes/types'

import { ParQFormType, parqFormSchema } from './schemas'
import { questionsRefs } from './variables'

export const ParQForm: React.FC = () => {
  const [defaultValues] = useAtom(evaluationAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const { scrollToTop } = useContext(formsLayoutContext)
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ParQFormType>({
    defaultValues,
    resolver: zodResolver(parqFormSchema),
  })

  const handleFormSubmit = useCallback(
    async (data: ParQFormType) => {
      if (!student_id || !evaluation_id) return
      setLoading(true)

      const success = await patchEvaluation(student_id, evaluation_id, data)
      if (success) {
        scrollToTop()
        navigate('?subform=fatorDeRiscoCoronariano', {
          replace: true,
        })
      }
      setLoading(false)
    },
    [student_id, evaluation_id, navigate, scrollToTop],
  )

  const handleGoBack = useCallback(() => {
    navigate('?subform=geral', {
      replace: true,
    })
    scrollToTop()
  }, [navigate, scrollToTop])

  useEffect(() => {
    const { unsubscribe } = watch((data) => {
      patchEvaluationAtom(data as any)
    })

    return () => {
      unsubscribe()
    }
  }, [watch])

  useEffect(() => {
    const keys = Object.keys(errors) as (keyof ParQFormType)[]
    if (keys[0]) {
      questionsRefs.get(keys[0])?.current?.scrollIntoView({ block: 'center' })
    }
  }, [errors])

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className='flex flex-col gap-6'
    >
      <QuestionFrame
        ref={questionsRefs.get('supervisedPhysicalActivity')}
        title='1) Algum médico já disse que você possui algum problema de coração e que só deveria realizar atividade física supervisionada por profissionais de saúde?'
        errors={!!errors.supervisedPhysicalActivity}
        isValid={
          parqFormSchema.pick({ supervisedPhysicalActivity: true }).safeParse({
            supervisedPhysicalActivity: watch('supervisedPhysicalActivity'),
          }).success
        }
      >
        <Controller
          control={control}
          name='supervisedPhysicalActivity'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.supervisedPhysicalActivity?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('chestPain')}
        title='2) Você sente dores no peito quando pratica atividade física?'
        errors={!!errors.chestPain}
        isValid={
          parqFormSchema.pick({ chestPain: true }).safeParse({
            chestPain: watch('chestPain'),
          }).success
        }
      >
        <Controller
          control={control}
          name='chestPain'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.chestPain?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('chestPainLastMonth')}
        errors={!!errors.chestPainLastMonth}
        title='3) No último mês, você sentiu dores no peito quando praticava atividade física?'
        isValid={
          parqFormSchema.pick({ chestPainLastMonth: true }).safeParse({
            chestPainLastMonth: watch('chestPainLastMonth'),
          }).success
        }
      >
        <Controller
          control={control}
          name='chestPainLastMonth'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.chestPainLastMonth?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('dizziness')}
        errors={!!errors.dizziness}
        title='4) Você apresenta desequilíbrio devido à tontura e/ou a perda de consciência?'
        isValid={
          parqFormSchema.pick({ dizziness: true }).safeParse({
            dizziness: watch('dizziness'),
          }).success
        }
      >
        <Controller
          control={control}
          name='dizziness'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.dizziness?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('boneAche')}
        errors={!!errors.boneAche}
        title='5) Você possui algum problema ósseo ou articular que poderia ser piorado pela atividade física?'
        isValid={
          parqFormSchema.pick({ boneAche: true }).safeParse({
            boneAche: watch('boneAche'),
          }).success
        }
      >
        <Controller
          control={control}
          name='boneAche'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.boneAche?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('bloodPressureMedication')}
        errors={!!errors.bloodPressureMedication}
        title='6) Você toma atualmente algum medicamento para pressão arterial e/ou problema de coração?'
        isValid={
          parqFormSchema.pick({ bloodPressureMedication: true }).safeParse({
            bloodPressureMedication: watch('bloodPressureMedication'),
          }).success
        }
      >
        <Controller
          control={control}
          name='bloodPressureMedication'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.bloodPressureMedication?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('reasonNotToExercise')}
        errors={!!errors.reasonNotToExercise}
        title='7) Sabe de alguma razão pela qual você não deve realizar atividade física?'
        isValid={
          parqFormSchema.pick({ reasonNotToExercise: true }).safeParse({
            reasonNotToExercise: watch('reasonNotToExercise'),
          }).success
        }
      >
        <Controller
          control={control}
          name='reasonNotToExercise'
          render={({ field }) => (
            <RadioButtonArray
              value={field.value}
              onChange={field.onChange}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
          )}
        />
        <span className='text-xs text-gpa-red'>
          {errors?.reasonNotToExercise?.message}
        </span>
      </QuestionFrame>

      <div className='flex justify-between'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button loading={loading} disabled={loading}>
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </form>
  )
}
