import { Toaster } from '~/components/Toaster'

import { PersonalTrainerExercisesGetter } from './components/PersonalTrainerExercisesGetter'
import { initiateSession } from './modules/authentication/services'
import { AppRouter } from './routes'
import { app_version } from './utils/version'

import 'swiper/css'
import 'swiper/css/scrollbar'

initiateSession()

function App() {
  return (
    <>
      <AppRouter />
      <Toaster />
      <PersonalTrainerExercisesGetter />
      <span className='hidden'>versão {app_version}</span>
    </>
  )
}

export default App
