import { useCallback } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { RegisterStudentForm } from '~/forms/RegisterStudentForm'
import { RegisterStudentDTO } from '~/modules/students/schemas'
import { registerStudent } from '~/modules/students/services'

import { RegisterStudentModalProps } from '../../types'

export const RegisterStudentModal: React.FC<RegisterStudentModalProps> = ({
  isOpen,
  onClose,
}) => {
  const handleSubmit = useCallback(
    async (payload: RegisterStudentDTO) => {
      const success = await registerStudent(payload)

      if (success) {
        onClose()
      }
    },
    [onClose],
  )

  return (
    <Modal visible={isOpen}>
      <div className='flex justify-between items-center mb-2'>
        <h2 className='text-xl font-medium'>Registrar Aluno</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={onClose}
        >
          <FiX size={24} />
        </button>
      </div>
      <main className='flex flex-col gap-3'>
        <RegisterStudentForm onSubmit={handleSubmit} />
        <Button
          type='button'
          mode='outlined'
          className='w-full'
          onClick={onClose}
        >
          Cancelar
        </Button>
      </main>
    </Modal>
  )
}
