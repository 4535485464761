import zod from 'zod'

import {
  cholesterolEnum,
  dailyPhysicalActivityEnum,
  hereditarianismEnum,
  sexAndAgeEnum,
  smokeEnum,
  weightEnum,
} from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/anamnesisForm/components/coronarianRiskFactorForm/variables'
import {
  alcoholicDrinksEnum,
  availableTimeEnum,
  availableWeekDaysEnum,
  cholesterolLevelsEnum,
  diabetesEnum,
  dietEnum,
  diseasesEnum,
  efforTestEnum,
  exerciseIntensityLevelEnum,
  exercisesEnum,
  exercisesLocaleEnum,
  glicemyLevelEnum,
  goalsEnum,
  jointMuscularDamageEnum,
  litersPerDayEnum,
  medicationEnum,
  pedigreeEnum,
  periodEnum,
  presentPhysicalConditioningEnum,
  regularMealsEnum,
  tabagismEnum,
  triglyceridesLevelsEnum,
} from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/anamnesisForm/components/generalForm/variables'

export const generalSchema = zod.object({
  goals: zod.enum(goalsEnum).array().nonempty(),
  presentPhysicalConditioning: zod.enum(presentPhysicalConditioningEnum),
  physicalConditioningTimePeriod: zod.object({
    period: zod.enum(periodEnum),
    quantity: zod.number().nonnegative().min(0),
  }),
  availableDaysPerWeek: zod.number().min(1).max(7),
  availableTime: zod.enum(availableTimeEnum),
  availableWeekDaysForPhysical: zod
    .array(zod.enum(availableWeekDaysEnum))
    .nonempty(),
  currentExercises: zod
    .object({
      exercises: zod.array(zod.enum(exercisesEnum)),
      other: zod.string(),
    })
    .refine(({ exercises, other }) => exercises.length || other.length)
    .refine(
      ({ exercises, other }) =>
        (exercises.length === 1 &&
          exercises.includes('Nenhum') &&
          !other.length) ||
        ((exercises.length || other.length) && !exercises.includes('Nenhum')),
    ),
  exercisesIntensity: zod.enum(exerciseIntensityLevelEnum),
  exercisesLocale: zod.enum(exercisesLocaleEnum),
  regularMeals: zod.array(zod.enum(regularMealsEnum)).nonempty(),
  diet: zod.enum(dietEnum),
  suplements: zod.string().optional(),
  effortTest: zod.enum(efforTestEnum),
  alcoholicDrinks: zod.enum(alcoholicDrinksEnum),
  litersPerDay: zod.enum(litersPerDayEnum),
  diabetes: zod.enum(diabetesEnum),
  glicemyLevel: zod.enum(glicemyLevelEnum),
  cholesterolLevels: zod.enum(cholesterolLevelsEnum),
  triglyceridesLevels: zod.enum(triglyceridesLevelsEnum),
  tabagism: zod.enum(tabagismEnum),
  diseases: zod
    .object({
      diseases: zod.array(zod.enum(diseasesEnum)),
      other: zod.string(),
    })
    .refine(({ diseases, other }) => diseases.length || other.length)
    .refine(
      ({ diseases, other }) =>
        (diseases.length === 1 &&
          diseases.includes('Nenhuma') &&
          !other.length) ||
        ((diseases.length || other.length) && !diseases.includes('Nenhuma')),
    ),
  regularMedications: zod
    .object({
      medications: zod.array(zod.enum(medicationEnum)),
      other: zod.string(),
    })
    .refine(({ medications, other }) => other.length || medications.length)
    .refine(
      ({ medications, other }) =>
        (medications.length === 1 &&
          medications.includes('Nenhum') &&
          !other.length) ||
        ((medications.length || other.length) &&
          !medications.includes('Nenhum')),
    ),
  jointMuscularDamage: zod
    .array(zod.enum(jointMuscularDamageEnum))
    .nonempty()
    .refine(
      (value) =>
        (value.length === 1 && value.includes('Nenhuma')) ||
        (value.length && !value.includes('Nenhuma')),
    ),
  pedigree: zod
    .array(zod.enum(pedigreeEnum))
    .nonempty()
    .refine(
      (value) =>
        (value.length === 1 && value.includes('Nenhuma')) ||
        (value.length && !value.includes('Nenhuma')),
    ),
  commentary: zod.string().optional(),
})

export const anamnesisSchema = zod
  .object({
    goals: zod.enum(goalsEnum).array().nonempty(),
    presentPhysicalConditioning: zod.enum(presentPhysicalConditioningEnum),
    physicalConditioningTimePeriod: zod.object({
      period: zod.enum(periodEnum),
      quantity: zod.number().nonnegative().min(0),
    }),
    availableDaysPerWeek: zod.number().min(1).max(7),
    availableTime: zod.enum(availableTimeEnum),
    availableWeekDaysForPhysical: zod
      .array(zod.enum(availableWeekDaysEnum))
      .nonempty(),
    currentExercises: zod
      .object({
        exercises: zod.array(zod.enum(exercisesEnum)),
        other: zod.string(),
      })
      .refine(({ exercises, other }) => exercises.length || other.length)
      .refine(
        ({ exercises, other }) =>
          (exercises.length === 1 &&
            exercises.includes('Nenhum') &&
            !other.length) ||
          ((exercises.length || other.length) && !exercises.includes('Nenhum')),
      ),
    exercisesIntensity: zod.enum(exerciseIntensityLevelEnum),
    exercisesLocale: zod.enum(exercisesLocaleEnum),
    regularMeals: zod.array(zod.enum(regularMealsEnum)).nonempty(),
    diet: zod.enum(dietEnum),
    suplements: zod.string().optional(),
    effortTest: zod.enum(efforTestEnum),
    alcoholicDrinks: zod.enum(alcoholicDrinksEnum),
    litersPerDay: zod.enum(litersPerDayEnum),
    diabetes: zod.enum(diabetesEnum),
    glicemyLevel: zod.enum(glicemyLevelEnum),
    cholesterolLevels: zod.enum(cholesterolLevelsEnum),
    triglyceridesLevels: zod.enum(triglyceridesLevelsEnum),
    tabagism: zod.enum(tabagismEnum),
    diseases: zod
      .object({
        diseases: zod.array(zod.enum(diseasesEnum)),
        other: zod.string(),
      })
      .refine(({ diseases, other }) => diseases.length || other.length)
      .refine(
        ({ diseases, other }) =>
          (diseases.length === 1 &&
            diseases.includes('Nenhuma') &&
            !other.length) ||
          ((diseases.length || other.length) && !diseases.includes('Nenhuma')),
      ),
    regularMedications: zod
      .object({
        medications: zod.array(zod.enum(medicationEnum)),
        other: zod.string(),
      })
      .refine(({ medications, other }) => other.length || medications.length)
      .refine(
        ({ medications, other }) =>
          (medications.length === 1 &&
            medications.includes('Nenhum') &&
            !other.length) ||
          ((medications.length || other.length) &&
            !medications.includes('Nenhum')),
      ),
    jointMuscularDamage: zod
      .array(zod.enum(jointMuscularDamageEnum))
      .nonempty()
      .refine(
        (value) =>
          (value.length === 1 && value.includes('Nenhuma')) ||
          (value.length && !value.includes('Nenhuma')),
      ),
    pedigree: zod
      .array(zod.enum(pedigreeEnum))
      .nonempty()
      .refine(
        (value) =>
          (value.length === 1 && value.includes('Nenhuma')) ||
          (value.length && !value.includes('Nenhuma')),
      ),
    commentary: zod.string().optional(),
    bloodPressureMedication: zod.boolean(),
    boneAche: zod.boolean(),
    chestPain: zod.boolean(),
    chestPainLastMonth: zod.boolean(),
    dizziness: zod.boolean(),
    reasonNotToExercise: zod.boolean(),
    supervisedPhysicalActivity: zod.boolean(),
    lackingAppetite: zod.boolean(),
    highRestingHeartRate: zod.boolean(),
    highBloodPressure: zod.boolean(),
    gastrointestinalDysfunction: zod.boolean(),
    muscularDamage: zod.boolean(),
    lackingFocus: zod.boolean(),
    insomnia: zod.boolean(),
    lowerLibido: zod.boolean(),
    irritability: zod.boolean(),
    dailyPhysicalActivity: zod.enum(dailyPhysicalActivityEnum),
    weight: zod.enum(weightEnum),
    sexAndAge: zod.enum(sexAndAgeEnum),
    smoke: zod.enum(smokeEnum),
    diastolicArterialPressure: zod.number().min(1).nonnegative(),
    systolicArterialPressure: zod.number().min(1).nonnegative(),
    hereditarianism: zod.enum(hereditarianismEnum),
    cholesterol: zod.enum(cholesterolEnum),
  })
  .refine((value) => {
    if (
      value.availableDaysPerWeek < value.availableWeekDaysForPhysical.length
    ) {
      return false
    }
    return true
  })
