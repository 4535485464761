import { SelectInput } from '~/components/SelectInput'
import { AerobicCapabilitiesProtocol } from '~/modules/aerobicCapabilities/types'
import { patchEvaluationAtom } from '~/modules/evalutation/services'
import { Option } from '~/types/types'

import { BrunoBalk } from './BrunoBalk'
import { Cooper } from './Cooper'
import { Duke } from './Duke'
import { Margaria } from './Margaria'
import { Medical } from './Medical'
import { Polar } from './Polar'
import { Rockport } from './Rockport'
import { Slope } from './Slope'
import { UKK } from './UKK'

const protocols = new Map<AerobicCapabilitiesProtocol, JSX.Element>([
  ['Bruno Balk - Teste de Pista', <BrunoBalk key='protocol-brunobalk' />],
  ['Cooper - Teste de Pista', <Cooper key='protocol-cooper' />],
  ['Margaria - Teste de Pista', <Margaria key='protocol-margaria' />],
  ['Rockport - Teste de Caminhada', <Rockport key='protocol-rockport' />],
  ['UKK - Pessoas Sedentárias', <UKK key='protocol-ukk' />],
  ['Médico - VO2 e Freq. Máxima', <Medical key='protocol-medical' />],
  ['Questionário de Duke', <Duke key='protocolo-duke' />],
  ['Rampa Manual Individualizada', <Slope key='protocolo-slope' />],
  ['Polar - OwnIndex', <Polar key='protocol-polar' />],
])

const protocolOptions: Option<AerobicCapabilitiesProtocol>[] = [
  {
    label: 'Bruno Balk - Teste de Pista',
    value: 'Bruno Balk - Teste de Pista',
  },
  {
    label: 'Cooper - Teste de Pista',
    value: 'Cooper - Teste de Pista',
  },
  {
    label: 'Margaria - Teste de Pista',
    value: 'Margaria - Teste de Pista',
  },
  {
    label: 'Rockport - Teste de Caminhada',
    value: 'Rockport - Teste de Caminhada',
  },
  {
    label: 'UKK - Pessoas Sedentárias',
    value: 'UKK - Pessoas Sedentárias',
  },
  {
    label: 'Médico - VO2 e Freq. Máxima',
    value: 'Médico - VO2 e Freq. Máxima',
  },
  {
    label: 'Questionário de Duke',
    value: 'Questionário de Duke',
  },
  {
    label: 'Rampa Manual Individualizada',
    value: 'Rampa Manual Individualizada',
  },
  {
    label: 'Polar - OwnIndex',
    value: 'Polar - OwnIndex',
  },
]

type Props = {
  protocol?: AerobicCapabilitiesProtocol
}

export const Protocols: React.FC<Props> = ({ protocol }) => {
  return (
    <>
      <SelectInput
        label='Teste de Capacidade Aeróbica'
        options={protocolOptions}
        value={protocol}
        onChange={(v: AerobicCapabilitiesProtocol) =>
          patchEvaluationAtom({
            aerobicCapabilitiesProtocol: v,
          })
        }
      />
      {protocols.get(protocol || 'Bruno Balk - Teste de Pista')}
    </>
  )
}
