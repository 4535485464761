import zod from 'zod'

import {
  convertToUTC,
  normalizeDate,
  validateDate,
} from '~/utils/normalizeDate'

export const createInvitationLinkSchema = zod.object({
  name: zod.string().min(3, 'Deve ter ao menos 3 letras').trim(),
  dueDate: zod
    .string()
    .superRefine((val, ctx) => {
      const normalizedDate = normalizeDate(val)
      if (!validateDate(normalizedDate)) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: 'Formato de data inválido',
        })
      }
      const utcDate = convertToUTC(normalizedDate)
      if (utcDate < new Date()) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: 'Não pode ser uma data passada',
        })
      }
    })
    .transform((val) => convertToUTC(normalizeDate(val)).toISOString()),
  createdBy: zod.string().uuid(),
  registrationsLimit: zod
    .number({ invalid_type_error: 'Deve ser um número' })
    .int(),
  licensesAmount: zod
    .number({ invalid_type_error: 'Deve ser um número' })
    .int(),
})

export type CreateInvitationLinkDTO = zod.infer<
  typeof createInvitationLinkSchema
>
