import { Option } from '~/types/types'

import { ListStudentsDTO, MaritalStatus, Sex } from './types'

export const defaultListValue: ListStudentsDTO = {
  meta: {
    page: 0,
    perPage: 10,
    totalPages: 0,
  },
  students: [],
}

export const maritalStatusEnum = [
  'solteiro(a)',
  'casado(a)',
  'divorciado(a)',
  'separado(a)',
  'viúvo(a)',
] as const

export const maritalStatusOptions: Option<MaritalStatus>[] =
  maritalStatusEnum.map((ms) => ({ label: ms, value: ms }))

export const genderEnum = ['masculino', 'feminino'] as const

export const genderOptions: Option<Sex>[] = genderEnum.map((g) => ({
  label: g,
  value: g,
}))
