import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiX } from 'react-icons/fi'

import {
  createPersonalTrainerLicenseChangeSchema,
  PersonalTrainerLicenseChangeDTO,
} from '~/modules/admin/schemas'
import { registerPersonalTrainerLicenseChange } from '~/modules/admin/services'
import { PersonalTrainerLicense } from '~/modules/admin/types'
import { licenseOperationsOptions } from '~/modules/admin/variables'

import { Button } from '../Button'
import { SelectInput } from '../SelectInput'
import { TextInput } from '../TextInput'
import { context } from './contex'

type Props = {
  onDismiss: () => void
  onSubmit: (data: PersonalTrainerLicense) => void
}

let abortController = new AbortController()

export const RegisterLicenseModal: React.FC<Props> = ({
  onDismiss,
  onSubmit,
}) => {
  const { personalTrainer } = useContext(context)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PersonalTrainerLicenseChangeDTO>({
    resolver: zodResolver(createPersonalTrainerLicenseChangeSchema),
  })

  const handleFormSubmit = useCallback(
    async (payload: PersonalTrainerLicenseChangeDTO) => {
      abortController = new AbortController()
      const data = await registerPersonalTrainerLicenseChange(
        personalTrainer.id,
        payload,
        abortController,
      )

      data && onSubmit && onSubmit(data)
      onDismiss && onDismiss()
    },
    [personalTrainer.id, onSubmit, onDismiss],
  )

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <>
      <div className='flex items-center justify-between mb-4'>
        <h1 className='text-xl'>Registrar Mudança na Licensa</h1>
        <button
          type='button'
          className='p-2 rounded-full hover:bg-slate-50'
          onClick={onDismiss}
        >
          <FiX size={26} />
        </button>
      </div>

      <form
        className='flex flex-col gap-4'
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className='flex gap-3'>
          <div className='flex-1'>
            <Controller
              control={control}
              name='operation'
              render={({ field }) => (
                <SelectInput
                  label='Operação'
                  placeholder='Selecione o valor da operação'
                  options={licenseOperationsOptions}
                  error={errors.operation?.message}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div>
            <TextInput
              label='Quantidade'
              {...register('amountChanged', { valueAsNumber: true })}
              error={errors.amountChanged?.message}
            />
          </div>
        </div>
        <Button loading={isSubmitting}>Confirmar</Button>
      </form>
    </>
  )
}
