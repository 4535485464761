import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import zod from 'zod'

import { Button } from '~/components/Button'
import { MaskedInput } from '~/components/TextInput'

const schema = zod.object({
  weight: zod.number({
    required_error: 'Campo obrigatório',
    invalid_type_error: 'Campo obrigatório',
  }),
  numRepetitions: zod
    .number({
      invalid_type_error: 'Campo obrigatório',
      required_error: 'Campo obrigatório',
    })
    .min(1, 'Valod deve ser maior ou igual a 1')
    .max(10, 'Valor deve ser menor ou igual a 10'),
})

export type FormType = zod.infer<typeof schema>

type Props = {
  onSubmit: (payload: FormType) => void
}

export const StrengthTestForm: React.FC<Props> = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormType>({
    resolver: zodResolver(schema),
  })

  const onFormSubmit = useCallback(
    (payload: FormType) => {
      onSubmit(payload)
    },
    [onSubmit],
  )

  return (
    <div className='flex flex-col gap-3 p-3 bg-blue-50 rounded-md'>
      <h2 className='font-semibold text-lg'>
        Definir Carga Máx. Utilizando teste de força
      </h2>
      <p>
        Com estas informações o GPA poderá sugerir um valor de carga máxima para
        este exercício
      </p>
      <form
        className='flex flex-col gap-3'
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Controller
          control={control}
          name='weight'
          render={({ field }) => (
            <MaskedInput
              mask='float'
              inputMode='numeric'
              containerClassName='bg-white'
              label='Carga Utilizada'
              error={errors.weight?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name='numRepetitions'
          render={({ field }) => (
            <MaskedInput
              mask='float'
              inputMode='numeric'
              containerClassName='bg-white'
              label='Número de repetições'
              error={errors.numRepetitions?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Button className='self-end'>Utilizar</Button>
      </form>
    </div>
  )
}
