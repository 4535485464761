import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import { patchPersonalTrainerExercises } from '~/modules/personalTrainerExercises/services'
import { defaultAerobicExercises } from '~/modules/personalTrainerExercises/variables'

type Props = {
  data: string[] | undefined
  visible: boolean
  onClose: () => void
}

export const RemoveActivityModal: React.FC<Props> = ({
  data,
  visible,
  onClose,
}) => {
  const [query, setQuery] = useState<string>('')
  const [loading, setLoating] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    onClose()
    setQuery('')
  }, [onClose])

  const handleRemoveActivity = useCallback(
    async (activity: string) => {
      const list = data
      if (!list) return
      const index = list.findIndex((i) => i === activity)
      list.splice(index, 1)
      return patchPersonalTrainerExercises({ aerobicExercises: list }).then(
        () => {
          onClose()
        },
      )
    },
    [onClose, data],
  )

  const handleUpdateQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value)
    },
    [],
  )

  const handleRecoverExercises = useCallback(() => {
    if (data?.length) {
      setLoating(true)
      const newActivities = defaultAerobicExercises.filter(
        (item) => !data.includes(item),
      )

      patchPersonalTrainerExercises({
        aerobicExercises: [...data, ...newActivities],
      }).then(() => {
        setLoating(false)
        onClose()
      })
    }
  }, [onClose, data])

  const filteredList = useMemo(() => {
    const list = data
    if (!query) return list
    return list?.filter((i) =>
      i
        .toLowerCase()
        .includes(query.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
    )
  }, [query, data])

  return (
    <Modal visible={visible}>
      <div className='flex items-center justify-between mb-3'>
        <h2 className='text-xl font-medium'>Excluir Exercício Aeróbico</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={handleClose}
        >
          <FiX size={24} />
        </button>
      </div>

      <TextInput
        onChange={handleUpdateQuery}
        placeholder='Filtrar exercício por nome'
      />

      <div className='flex flex-wrap gap-3'>
        {filteredList?.map((activity, index) => (
          <ExerciseTag
            key={`remove-activity-${index}`}
            exercise={activity}
            onDelete={() => handleRemoveActivity(activity)}
          />
        ))}
      </div>
      <div className='flex flex-col w-full mt-4'>
        <Button
          loading={loading}
          disabled={loading}
          onClick={handleRecoverExercises}
        >
          Recuperar exercícios padrão
        </Button>
      </div>
    </Modal>
  )
}
