import { TextInput } from '~/components/TextInput'
import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

type Props = {
  field:
    | 'abdominal'
    | 'medialThigh'
    | 'suprailiac'
    | 'chest'
    | 'triceptal'
    | 'axilla'
    | 'subscapular'
}

export const Mean: React.FC<Props> = ({ field }) => {
  const [data] = useAtom(evaluationAtom)

  return (
    <TextInput
      readOnly
      value={(
        (data?.[field].reduce((p, c) => p + c, 0) || 0) /
        (data?.[field].length ?? -1)
      ).toFixed(2)}
    />
  )
}
