import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FiX } from 'react-icons/fi'
import zod from 'zod'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import { patchPersonalTrainerExercises } from '~/modules/personalTrainerExercises/services'
import { insertToast } from '~/modules/toasts/services'

type Props = {
  visible: boolean
  onClose: () => void
}

type ActivityForm = {
  activity: string
}

const schema = zod.object({
  activity: zod
    .string({ required_error: 'Campo obrigatório' })
    .trim()
    .nonempty('Campo obrigatório')
    .max(15, 'Deve ter no máximo 15 caracteres'),
})

export const NewActivityModal: React.FC<Props> = ({ visible, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ActivityForm>({
    resolver: zodResolver(schema),
  })

  const onSumbitForm = useCallback(
    async ({ activity }: ActivityForm) => {
      const oldAerobicExercises =
        personalTrainerExercisesAtom.get()?.aerobicExercises
      if (!oldAerobicExercises) return
      if (oldAerobicExercises.includes(activity)) {
        insertToast({
          duration: 3,
          title: 'Atenção!',
          message: 'Atividade aeróbica já cadastrada',
          type: 'warn',
        })
        return
      }

      await patchPersonalTrainerExercises({
        aerobicExercises: [...oldAerobicExercises, activity],
      })

      reset()
      onClose()
    },
    [reset, onClose],
  )

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [reset, onClose])

  return (
    <Modal visible={visible}>
      <div className='flex items-center justify-between mb-3'>
        <h2 className='text-xl font-medium'>Novo Exercício Aeróbico</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={handleClose}
        >
          <FiX size={24} />
        </button>
      </div>
      <form
        onSubmit={handleSubmit(onSumbitForm)}
        className='flex flex-col gap-3'
      >
        <TextInput {...register('activity')} error={errors.activity?.message} />
        <Button disabled={isSubmitting} loading={isSubmitting}>
          Adicionar
        </Button>
      </form>
    </Modal>
  )
}
