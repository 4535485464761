import { AbdominalInput } from './components/AbdominalInput'
import { AxillaInput } from './components/AxillaInput'
import { ChestInput } from './components/ChestInput'
import { MedialThighInput } from './components/MedialThighInput'
import { SubscapularInput } from './components/SubscapularInput'
import { SuprailiacInput } from './components/SuprailiacInput'
import { TriceptalInput } from './components/TriceptalInput'

export const PollockJacksonSevenFold: React.FC = () => {
  return (
    <div className='grid flex-1'>
      <div className='grid grid-rows-7 xl:grid-rows-8 gap-5 self-start'>
        <div className='hidden xl:grid grid-cols-5 gap-3 items-end'>
          <div></div>
          <div className='font-bold'>Medida 1</div>
          <div className='font-bold'>Medida 2</div>
          <div className='font-bold'>Medida 3</div>
          <div className='font-bold'>Médida</div>
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <SubscapularInput />
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <TriceptalInput />
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <ChestInput />
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <AxillaInput />
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <SuprailiacInput />
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <AbdominalInput />
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <MedialThighInput />
        </div>
      </div>
    </div>
  )
}
