import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '~/components/Button'
import { createEvaluation } from '~/modules/evalutation/services'

import { studentCardContext } from '..'

import { studentEvaluationsRoute } from '~/routes/routes'

export const NewEvaluationButton: React.FC = () => {
  const navigate = useNavigate()
  const { student } = useContext(studentCardContext)
  const [loading, setLoading] = useState<boolean>(false)

  const handleCreateEvaluation = useCallback(async () => {
    setLoading(true)
    const evaluation = await createEvaluation(student.id)
    setLoading(false)

    if (evaluation) {
      navigate(
        studentEvaluationsRoute
          .replace(':student_id', student.id)
          .replace(':evaluation_id', evaluation.id),
      )
    }
  }, [student, navigate])

  return (
    <Button
      className='flex-1'
      loading={loading}
      onClick={handleCreateEvaluation}
    >
      Nova avaliação
    </Button>
  )
}
