import { useCallback, useEffect, useState } from 'react'

import { PageNavigation } from '~/components/PageNavigation'
import { PersonalTrainerCard } from '~/components/PersonalTrainerCard'
import { personalTrainersAtom } from '~/modules/admin/data'
import { listPersonalTrainer } from '~/modules/admin/services'
import { PersonalTrainerQuery } from '~/modules/admin/types'
import { useAtom } from '~/observers/jotai'

import { context } from './context'
import { Header } from './Header'

export const AdminHome: React.FC = () => {
  const [data] = useAtom(personalTrainersAtom)
  const [filter, setFilter] = useState<PersonalTrainerQuery>({
    page: 0,
  })

  const handleIncrementPage = useCallback((page: number) => {
    setFilter((state) => ({ ...state, page }))
  }, [])

  const handleChangeSearch = useCallback((search: string) => {
    setFilter((state) => ({ ...state, name: search, documentNumber: search }))
  }, [])

  useEffect(() => {
    listPersonalTrainer(filter)
  }, [filter])

  return (
    <context.Provider value={{ handleChangeSearch }}>
      <Header />
      <div className='flex flex-col flex-1 overflow-y-hidden bg-slate-100'>
        <div className='flex p-5 flex-wrap gap-4 overflow-y-auto'>
          {data?.personalTrainers.map((p) => (
            <PersonalTrainerCard key={p.id} data={p} />
          ))}
        </div>
        <div className='self-end mt-auto p-5'>
          <PageNavigation
            currentPage={filter.page ?? 0}
            numPages={data?.meta.totalPages ?? 7}
            onPageChange={handleIncrementPage}
          />
        </div>
      </div>
    </context.Provider>
  )
}
