export const categories = [
  'Ombro',
  'Peitoral Insipirado',
  'Peitoral Normal',
  'Quadril',
  'Abdômen',
  'Cintura',
  'Coxa Proximal Dir.',
  'Coxa Proximal Esq.',
  'Perna Direita',
  'Perna Esquerda',
  'Braço Dir. contraído',
  'Braço Esq. contraído',
  'Braço Dir. relaxado',
  'Braço Esq. relaxado',
  'Antebraço direito',
  'Antebraço esquerdo',
  'Punho Dir.',
  'Punho Esq.',
]

export const chartOptions: ApexCharts.ApexOptions = {
  chart: {
    id: 'basic-bar',
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories,
    labels: {
      style: {
        fontSize: '0px',
      },
    },
  },
  colors: [
    '#9c4c4c',
    '#e9d70c',
    '#7be90c',
    '#0ce91f',
    '#0ce9c4',
    '#0c94e9',
    '#170ce9',
    '#730ce9',
    '#b20ce9',
    '#e90cd3',
    '#e90c59',
    '#c72626',
    '#88ad38',
    '#46a23c',
    '#3da077',
    '#3a6196',
    '#3b3993',
    '#8d3b86',
  ],
  plotOptions: {
    bar: {
      columnWidth: '45%',
      distributed: true,
    },
  },
}
