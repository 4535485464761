import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { insertToast } from '../toasts/services'
import { personalTrainersAtom } from './data'
import {
  CreatePersonalTrainerDTO,
  PersonalTrainerLicenseChangeDTO,
  UpdatePersonalTrainerAdminDTO,
} from './schemas'
import {
  IlistPersonalTrainerDTO,
  PersonalTrainer,
  PersonalTrainerLicense,
  PersonalTrainerQuery,
} from './types'

export async function listPersonalTrainer(params?: PersonalTrainerQuery) {
  await api
    .get<IlistPersonalTrainerDTO>('/admin/personal-trainers', {
      params,
    })
    .then(({ data }) => {
      personalTrainersAtom.set(data)
    })
    .catch((error: AxiosError<APIError>) => {
      console.log(error.response?.data.message)
    })
}

export async function createPersonalTrainer(payload: CreatePersonalTrainerDTO) {
  return api
    .post<PersonalTrainer>('/admin/personal-trainers', payload)
    .then(({ data }) => {
      const old = personalTrainersAtom.get()
      personalTrainersAtom.set({
        ...old,
        personalTrainers: [data, ...old.personalTrainers],
      })
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response?.data.message,
          title: 'Erro ao tentar criar personal trainer',
        })
      }
    })
}

export async function adminUpdatePersonalTrainer(
  personalTrainerID: string,
  payload: UpdatePersonalTrainerAdminDTO,
) {
  return api
    .patch<PersonalTrainer>(
      `/admin/personal-trainers/${personalTrainerID}`,
      payload,
    )
    .then(({ data }) => {
      const newList = personalTrainersAtom.get()
      const index = newList.personalTrainers.findIndex(
        (pt) => pt.id === data.id,
      )

      if (index >= 0) {
        newList.personalTrainers.splice(index, 1, data)
        personalTrainersAtom.set({ ...newList })
      }

      insertToast({
        duration: 3,
        message: 'Dados do personal trainer atualizados.',
        title: 'Sucesso!',
      })

      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response?.data.message,
          title: 'Erro ao tentar atualizar personal trainer',
          type: 'error',
        })
      }
      return false
    })
}

export async function listPersonalTrainerLicenseChanges(
  personalTrainerId: string,
  controller: AbortController,
) {
  const data = await api
    .get<PersonalTrainerLicense[]>(
      `/admin/personal-trainers/${personalTrainerId}/licenses`,
      { signal: controller.signal },
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response?.data.message,
          title: 'Erro ao buscar licenças',
        })
      }
    })
  return data
}

export async function registerPersonalTrainerLicenseChange(
  personalTrainerId: string,
  payload: PersonalTrainerLicenseChangeDTO,
  controller: AbortController,
): Promise<PersonalTrainerLicense | undefined> {
  return await api
    .post(`/admin/personal-trainers/${personalTrainerId}/licenses`, payload, {
      signal: controller.signal,
    })
    .then(({ data }) => {
      const { meta, personalTrainers } = personalTrainersAtom.get()

      const newList = personalTrainers.map((trainer) =>
        trainer.id === personalTrainerId
          ? {
              ...trainer,
              personalTrainerLicense: [data],
            }
          : trainer,
      )

      personalTrainersAtom.set({
        meta,
        personalTrainers: newList,
      })

      return data
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response?.data.message,
          title: 'Erro ao tentar criar licença',
        })
      }
    })
}
