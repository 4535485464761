import { ApexOptions } from 'apexcharts'
import ApexChart from 'react-apexcharts'

import { useIMCFormula } from '~/modules/antropometry/useIMCData'
import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

export const ChartIMC: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const { calcIMC } = useIMCFormula(evaluation)

  const categories = [
    '0,05',
    '0,1',
    '0,15 ',
    '0,2',
    '0,25',
    '0,3',
    '0,35',
    '0,4',
    '0,45',
    '0,5',
    '0,55',
    '0,6',
    '0,65',
    '0,7',
    '0,75',
    '0,8',
    '0,85',
    '0,9',
    '0,95',
    '1',
  ]

  const series = [
    {
      name: 'Obtido',
      // data: [28.1],
      data: [calcIMC()],
      type: 'bar',
    },
    {
      name: 'Mínimo',
      data: [
        18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18,
        18, 18,
      ],
      // data: [getCoronarianRiskFactorResult().totalScore],
      type: 'line',
      color: '#008FFB',
    },
    {
      name: 'Máximo',
      data: [
        40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40,
        40, 40,
      ],
      // data: [[40, 40]],
      type: 'line',
      color: '#00E396',
    },
  ]

  const chartOptions: ApexOptions = {
    chart: {
      id: 'imc-chart',
    },
    legend: {
      show: true,
      position: 'right',
    },
    stroke: {
      width: [0, 2, 5],
      curve: 'smooth',
    },

    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },

    colors: ['#e90c59'],

    plotOptions: {
      bar: {
        // columnWidth: '85%',
        columnWidth: 350,
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
  }

  return (
    <div className='flex w-full max-w-[190mm]'>
      <ApexChart
        type='bar'
        height={180}
        width={720}
        series={series}
        options={chartOptions}
      />
    </div>
  )
}
