import { useEffect, useRef } from 'react'

type QuestionProps = {
  index: string
  title: string
  value: string | number
}

export const Question: React.FC<QuestionProps> = ({ index, title, value }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    containerRef.current?.scrollIntoView()
  }, [])

  return (
    <div ref={containerRef} className='flex flex-1 gap-1'>
      <div className='shrink-0 w-9 h-9 bg-gpa-gray-500 flex justify-center items-center'>
        <p className='text-sm'>{index}</p>
      </div>
      <div className='flex flex-1 flex-col'>
        <div className='flex items-center min-h-[36px] bg-gpa-gray-500 py-1 px-3'>
          <p className='text-sm'>{title}</p>
        </div>
        <div className='flex items-center min-h-[36px] bg-gpa-gray-50 py-1 px-3'>
          <p className='text-sm'>{value}</p>
        </div>
      </div>
    </div>
  )
}
