import { useEffect, useMemo, useRef } from 'react'

import { computeAgeService } from '~/services/computeAgeService'
import { Option } from '~/types/types'

import { useAreobicCapabilities } from '../aerobicCapabilities/useAerobicCapabilities'
import { patchEvaluationAtom } from '../evalutation/services'
import { Evaluation } from '../evalutation/types'

export function useAerobicPrescription(evaluation?: Evaluation) {
  const { reserved } = useAreobicCapabilities(evaluation)

  const intensityZoneMin = useMemo(() => {
    if (evaluation?.targetZone) {
      switch (evaluation.objectiveIndex) {
        case 0:
          return 28
        case 1:
          return 43
        case 2:
          return 57
        case 3:
          return 71
        case 4:
          return 84
        default:
          return 0
      }
    } else {
      switch (evaluation?.objectiveIndex) {
        case 0:
          return 50
        case 1:
          return 61
        case 2:
          return 71
        case 3:
          return 81
        case 4:
          return 91
        default:
          return 0
      }
    }
  }, [evaluation])

  const intensityZoneMax = useMemo(() => {
    if (evaluation?.targetZone) {
      switch (evaluation.objectiveIndex) {
        case 0:
          return 42
        case 1:
          return 56
        case 2:
          return 70
        case 3:
          return 83
        case 4:
          return 100
        default:
          return 100
      }
    } else {
      switch (evaluation?.objectiveIndex) {
        case 0:
          return 60
        case 1:
          return 70
        case 2:
          return 80
        case 3:
          return 90
        case 4:
          return 100
        default:
          return 100
      }
    }
  }, [evaluation])

  const intensityOptions = useMemo<Option<number>[]>(() => {
    const options: Option<number>[] = []
    for (let i = intensityZoneMin; i <= intensityZoneMax; i += 1) {
      options.push({
        label: '' + i,
        value: i,
      })
    }
    return options
  }, [intensityZoneMin, intensityZoneMax])

  const highlightedCellsIndex = useMemo(() => {
    if (evaluation?.targetZone) {
      if (evaluation.intensity >= 20 && evaluation.intensity <= 39) {
        return [4, 5]
      }
      if (evaluation.intensity >= 40 && evaluation.intensity <= 59) {
        return [6, 7]
      }
      if (evaluation.intensity >= 60 && evaluation.intensity <= 84) {
        return [8, 9, 10]
      }
      if (evaluation.intensity >= 85 && evaluation.intensity <= 99) {
        return [11, 12, 13]
      }
      return [14]
    }
    if (
      (evaluation?.intensity || 0) >= 35 &&
      (evaluation?.intensity || 0) <= 54
    ) {
      return [4, 5]
    }
    if (
      (evaluation?.intensity || 0) >= 55 &&
      (evaluation?.intensity || 0) <= 69
    ) {
      return [6, 7]
    }
    if (
      (evaluation?.intensity || 0) >= 70 &&
      (evaluation?.intensity || 0) <= 89
    ) {
      return [8, 9, 10]
    }
    if (
      (evaluation?.intensity || 0) >= 90 &&
      (evaluation?.intensity || 0) <= 99
    ) {
      return [11, 12, 13]
    }
    return [14]
  }, [evaluation?.targetZone, evaluation?.intensity])

  const maxMinorHeartFrequency = useMemo(() => {
    const DP = computeAgeService(evaluation?.student.birthday) < 26 ? 10 : 12
    return (evaluation?.maxHeartFrequency ?? 0) - DP
  }, [evaluation])

  const maxMajorHeartFrequency = useMemo(() => {
    const DP = computeAgeService(evaluation?.student.birthday) < 26 ? 10 : 12
    return (evaluation?.maxHeartFrequency ?? 0) + DP
  }, [evaluation])

  const minorHeartfrequencyReserve = useMemo(() => {
    if (!maxMinorHeartFrequency || !evaluation?.restingHeartFrequency) {
      return 0
    }
    return maxMinorHeartFrequency - evaluation?.restingHeartFrequency
  }, [evaluation, maxMinorHeartFrequency])

  const majorHeartFrequencyReserve = useMemo(() => {
    if (!maxMajorHeartFrequency || !evaluation?.restingHeartFrequency) {
      return 0
    }
    return maxMajorHeartFrequency - evaluation?.restingHeartFrequency
  }, [maxMajorHeartFrequency, evaluation])

  const minorHeartfrequencyReserveInWater = useMemo(() => {
    if (!maxMinorHeartFrequency || !evaluation?.restingHeartFrequencyInWater) {
      return 0
    }
    return maxMinorHeartFrequency - evaluation?.restingHeartFrequencyInWater
  }, [evaluation, maxMinorHeartFrequency])

  const majorHeartFrequencyReserveInWater = useMemo(() => {
    if (!maxMajorHeartFrequency || !evaluation?.restingHeartFrequencyInWater) {
      return 0
    }
    return maxMajorHeartFrequency - evaluation?.restingHeartFrequencyInWater
  }, [maxMajorHeartFrequency, evaluation])

  const minorTrainingZone = useMemo(() => {
    if (evaluation?.targetZone) {
      return +(
        minorHeartfrequencyReserve * (evaluation.intensity / 100) +
        (evaluation?.restingHeartFrequency || 0)
      ).toFixed()
    }
    return +(
      maxMinorHeartFrequency *
      ((evaluation?.intensity || 0) / 100)
    ).toFixed()
  }, [
    evaluation?.targetZone,
    evaluation?.intensity,
    evaluation?.restingHeartFrequency,
    minorHeartfrequencyReserve,
    maxMinorHeartFrequency,
  ])

  const majorTrainingZone = useMemo(() => {
    if (evaluation?.targetZone) {
      return +(
        majorHeartFrequencyReserve * (evaluation.intensity / 100) +
        (evaluation?.restingHeartFrequency || 0)
      ).toFixed()
    }
    return +(
      maxMajorHeartFrequency *
      ((evaluation?.intensity || 0) / 100)
    ).toFixed()
  }, [
    evaluation?.targetZone,
    evaluation?.intensity,
    evaluation?.restingHeartFrequency,
    majorHeartFrequencyReserve,
    maxMajorHeartFrequency,
  ])

  const minorTrainingZoneInWater = useMemo(() => {
    if (evaluation?.targetZone) {
      return +(
        minorHeartfrequencyReserveInWater * (evaluation.intensity / 100) +
        (evaluation?.restingHeartFrequencyInWater || 0)
      ).toFixed()
    }
    return +(
      maxMinorHeartFrequency *
      ((evaluation?.intensity || 0) / 100)
    ).toFixed()
  }, [
    evaluation?.targetZone,
    evaluation?.intensity,
    evaluation?.restingHeartFrequencyInWater,
    minorHeartfrequencyReserveInWater,
    maxMinorHeartFrequency,
  ])

  const majorTrainingZoneInWater = useMemo(() => {
    if (evaluation?.targetZone) {
      return +(
        majorHeartFrequencyReserveInWater * (evaluation.intensity / 100) +
        (evaluation?.restingHeartFrequencyInWater || 0)
      ).toFixed()
    }
    return +(
      maxMajorHeartFrequency *
      ((evaluation?.intensity || 0) / 100)
    ).toFixed()
  }, [
    evaluation?.targetZone,
    evaluation?.intensity,
    evaluation?.restingHeartFrequencyInWater,
    majorHeartFrequencyReserveInWater,
    maxMajorHeartFrequency,
  ])

  const trainingVO2 = useMemo(() => {
    return +((reserved * (evaluation?.intensity || 0)) / 100 + 3.5).toFixed(1)
  }, [reserved, evaluation?.intensity])

  const trainingMETS = useMemo(() => {
    return +(trainingVO2 / 3.5).toFixed(1)
  }, [trainingVO2])

  const energyPerMinute = useMemo(() => {
    return +(
      (trainingMETS * 3.5 * (evaluation?.currentWeight || 0)) /
      200
    ).toFixed(1)
  }, [evaluation, trainingMETS])

  const initialSessionDuration = useMemo(() => {
    return +((evaluation?.proposedEnergy || 0) / energyPerMinute).toFixed()
  }, [energyPerMinute, evaluation?.proposedEnergy])

  const minimumKgcalForTrainging = useMemo(() => {
    return +((300 * (evaluation?.currentWeight || 0)) / 70).toFixed()
  }, [evaluation])

  const oldIndex = useRef<number | undefined>(evaluation?.objectiveIndex)
  useEffect(() => {
    if (oldIndex.current === evaluation?.objectiveIndex) return
    oldIndex.current = evaluation?.objectiveIndex
    patchEvaluationAtom({ intensity: intensityZoneMin })
  }, [evaluation?.objectiveIndex, evaluation?.targetZone, intensityZoneMin])

  return {
    highlightedCellsIndex,
    intensityZoneMin,
    intensityOptions,
    intensityZoneMax,
    minorTrainingZone,
    majorTrainingZone,
    minorTrainingZoneInWater,
    majorTrainingZoneInWater,
    trainingVO2,
    trainingMETS,
    initialSessionDuration,
    minimumKgcalForTrainging,
    energyPerMinute,
  }
}
