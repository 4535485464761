import { useCallback, useState } from 'react'

import { Button } from '~/components/Button'
import { toggleInvitationLinkActiveStatus } from '~/modules/invitationLinks/services'
import { insertToast } from '~/modules/toasts/services'

type Props = {
  id: string
  active: boolean
}

export const ToggleLinkButton: React.FC<Props> = ({ id, active }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(active)

  const handleToggleLink = useCallback(() => {
    setLoading(true)
    toggleInvitationLinkActiveStatus({ id, active: !isActive }).then((data) => {
      if (data) {
        setIsActive(data.active)
      }
      setLoading(false)
    })
  }, [isActive, id])

  const handleCopyLink = useCallback((link: string) => {
    navigator.clipboard.writeText(link)
    insertToast({
      title: 'Link copiado!',
      duration: 3,
    })
  }, [])

  return (
    <div className='flex gap-2 justify-center'>
      {isActive ? (
        <Button
          onClick={() =>
            handleCopyLink(
              `${window.location.origin}/personal/cadastro?convite=${id}`,
            )
          }
        >
          Copiar Link
        </Button>
      ) : null}
      <Button onClick={handleToggleLink} disabled={loading}>
        {isActive ? 'Desabilitar' : 'Habilitar'}
      </Button>
    </div>
  )
}
