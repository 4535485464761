import { forwardRef, InputHTMLAttributes } from 'react'
import { IoIosSearch } from 'react-icons/io'

type Props = InputHTMLAttributes<HTMLInputElement>

const Component: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref,
) => {
  return (
    <label className='flex items-center gap-1.5 border-2 p-2 h-10 rounded-lg bg-white'>
      <IoIosSearch size={24} color='#3773AF' />
      <div>
        <input
          ref={ref}
          type='text'
          {...props}
          className='outline-none bg-transparent'
        />
      </div>
    </label>
  )
}

export const SearchInput = forwardRef(Component)
