import { PropsWithChildren } from 'react'
// import { motion } from 'framer-motion'

export const Loading: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='flex justify-center items-center'>
      {children || 'Carregando'}
      <div className='h-3 w-3 border-2 border-x-slate-200 border-y-slate-200 border-t-slate-400 rounded-full ml-1 animate-spin' />
      {/* <motion.span
        className='h-1 w-1 rounded-full bg-white mt-4 ml-1'
        animate={{ y: [0, -5, 0] }}
        transition={{ duration: 2, repeat: Infinity }}
      />
      <motion.span
        className='h-1 w-1 rounded-full bg-white mt-4 ml-1'
        animate={{ y: [0, -5, 0] }}
        transition={{ duration: 2, repeat: Infinity, delay: 0.5 }}
      />
      <motion.span
        className='h-1 w-1 rounded-full bg-white mt-4 ml-1'
        animate={{ y: [0, -5, 0] }}
        transition={{ duration: 2, repeat: Infinity, delay: 1 }}
      /> */}
    </div>
  )
}
