import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import {
  closeEvaluation,
  deleteEvaluation,
} from '~/modules/evalutation/services'
import { personalHomeRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { menuStateAtom } from '../state'

export const EvaluationButtons: React.FC = () => {
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const navigate = useNavigate()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [loadingDeleteEvaluation, setLoadingDeleteEvaluation] =
    useState<boolean>(false)

  const handleDeleteEvaluation = useCallback(async () => {
    if (!evaluation_id || !student_id) return
    setLoadingDeleteEvaluation(true)
    const success = await deleteEvaluation(student_id, evaluation_id)
    if (success) {
      setShowDeleteModal(false)
      menuStateAtom.set(false)
      navigate(personalHomeRoute, { replace: true })
    }
  }, [navigate, evaluation_id, student_id])

  const handleCloseEvaluation = useCallback(() => {
    closeEvaluation()
    menuStateAtom.set(false)
    navigate(personalHomeRoute, { replace: true })
  }, [navigate])

  const toggleDeleteEvaluationModal = useCallback(() => {
    setShowDeleteModal((state) => !state)
  }, [])

  return (
    <>
      <div className='flex md:flex-row flex-col flex-start gap-2'>
        <Button
          onClick={toggleDeleteEvaluationModal}
          className='!bg-white gap-1 font-normal border-transparent hover:!bg-slate-50 enabled:active:bg-red-50 !text-gpa-red !justify-start md:justify-center'
        >
          Excluir
        </Button>
        <Button
          onClick={handleCloseEvaluation}
          className='!bg-white border-transparent !text-black hover:!bg-slate-50 font-normal enabled:active:bg-slate-100 !justify-start md:justify-center'
        >
          Fechar avaliação
        </Button>
      </div>
      <Modal visible={showDeleteModal}>
        <div className='flex flex-col gap-8'>
          <h1 className='text-lg'>Deseja realmente apagar este registro?</h1>
          <p>Todos os dados desta avaliação serão perdidos</p>
          <div className='flex justify-end gap-3'>
            <Button
              loading={loadingDeleteEvaluation}
              onClick={handleDeleteEvaluation}
              className='!bg-gpa-red border-gpa-red !font-normal enabled:active:bg-red-500 hover:bg-red-400'
            >
              Apagar
            </Button>
            <Button
              mode='outlined'
              onClick={toggleDeleteEvaluationModal}
              className='border-gpa-red !font-normal text-gpa-red enabled:active:bg-red-50'
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
