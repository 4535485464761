import { RefObject, createRef } from 'react'

import { Evaluation } from '~/modules/evalutation/types'

export const dailyPhysicalActivityEnum = [
  'Esforço ocupacional e recreacional intenso',
  'Esforço ocupacional e recreacional moderado',
  'Trabalho sedentário e esforço recreacional intenso',
  'Trabalho sedentário e esforço recreacional moderado',
  'Trabalho sedentário e esforço recreacional leve',
  'Ausência completa de qualquer exercício',
] as const

export const weightEnum = [
  '+ 2,3kg abaixo do peso padrão',
  '-2,3kg a + 2,3kg do peso padrão',
  'excesso de peso padrão de 2,7kg até 9kg',
  'excesso de peso padrão de 9,1kg até 15,8kg',
  'excesso de peso padrão de 16,2kg até 22,6kg',
  'excesso de peso padrão de 23,0kg até 29,5kg',
] as const

export const sexAndAgeEnum = [
  'Mulher abaixo de 40 anos',
  'Mulher de 40 a 50 anos',
  'Mulher com mais de 50 anos',
  'Homem',
  'Homem endomesomórfico',
  'Homem careca e endomesomórfico',
] as const

export const smokeEnum = [
  'Não fuma',
  'Charuto e/ou cachimbo',
  '10 cigarros ou menos por dia',
  '20 cigarros por dia',
  '30 cigarros por dia',
  '40 cigarros ou mais por dia',
] as const

export const hereditarianismEnum = [
  'Nenhuma história conhecida de cardiopatia',
  'Um parente com doença cardiovascular, acima de 60 anos',
  '2 parentes com + de 60 anos com doença cardiovascular',
  '1 parente com - de 60 anos com doença cardiovascular',
  '2 parentes com doença cardiovascular, abaixo de 60 anos',
  '3 parentes com doença cardiovascular, abaixo de 60 anos',
] as const

export const cholesterolEnum = [
  'Colesterol de 180mg ou dieta sem gordura animal',
  'Colesterol de 181mg a 205mg ou dieta com 10% gordura animal',
  'Colesterol de 206mg a 230mg ou dieta com 20% gordura animal',
  'Colesterol de 231mg a 255mg ou dieta com 30% gordura animal',
  'Colesterol de 256mg a 280mg ou dieta com 40% gordura animal',
  'Colesterol de 280mg a 330mg ou dieta com 50% gordura animal',
] as const

export const questionsRefs = new Map<
  keyof Evaluation,
  RefObject<HTMLDivElement>
>([
  ['diastolicArterialPressure', createRef<HTMLDivElement>()],
  ['systolicArterialPressure', createRef<HTMLDivElement>()],
  ['cholesterol', createRef<HTMLDivElement>()],
  ['dailyPhysicalActivity', createRef<HTMLDivElement>()],
  ['hereditarianism', createRef<HTMLDivElement>()],
  ['sexAndAge', createRef<HTMLDivElement>()],
  ['smoke', createRef<HTMLDivElement>()],
  ['weight', createRef<HTMLDivElement>()],
])
