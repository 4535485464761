import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { documentNumberMask } from '~/components/TextInput/utils/mask'
import { authenticationAtom } from '~/modules/authentication/data'
import {
  UpdatePersonalTrainerDTO,
  updatePersonalTrainerSchema,
} from '~/modules/personalTrainer/schema'
import { updatePersonalTrainer } from '~/modules/personalTrainer/services'
import { useAtom } from '~/observers/jotai'

type Props = {
  onSubmit: () => void
}

export const UpdatePersonalTrainerForm: React.FC<Props> = ({ onSubmit }) => {
  const [session] = useAtom(authenticationAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePersonalTrainerDTO>({
    resolver: zodResolver(updatePersonalTrainerSchema),
    defaultValues: {
      email: session?.user.email,
      name: session?.user.name,
      documentNumber: documentNumberMask(
        session?.user.documentNumber as string,
      ),
    },
  })

  const handleSubmitForm = useCallback(
    (data: UpdatePersonalTrainerDTO) => {
      setLoading(true)
      updatePersonalTrainer(data).then((success) => {
        if (success) {
          onSubmit()
        }
        setLoading(false)
      })
    },
    [onSubmit],
  )

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <div className='flex flex-1 flex-wrap gap-3'>
        <TextInput
          label='Nome'
          {...register('name')}
          error={errors.name?.message}
        />
        <TextInput
          label='E-mail'
          {...register('email')}
          error={errors.email?.message}
        />
      </div>

      <div className='flex flex-1 flex-wrap gap-3'>
        <Controller
          control={control}
          name='documentNumber'
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={(e) =>
                field.onChange(documentNumberMask(e.target.value))
              }
              label='CPF'
              error={errors.documentNumber?.message}
            />
          )}
        />
      </div>
      <div className='flex flex-1 flex-wrap gap-3'>
        <TextInput
          label='Nova senha (opcional)'
          type='password'
          {...register('password')}
          error={errors.password?.message}
        />
        <TextInput
          label='Confirmar senha (opcional)'
          type='password'
          {...register('confirmPassword')}
          error={errors.confirmPassword?.message}
        />
      </div>

      <Button disabled={loading} loading={loading} className='w-full'>
        Atualizar
      </Button>

      <Button
        type='button'
        mode='outlined'
        disabled={loading}
        loading={loading}
        className='w-full mt-4'
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>
    </form>
  )
}
