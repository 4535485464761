import ApexChart from 'react-apexcharts'

import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { Evaluation } from '~/modules/evalutation/types'

type Props = {
  data: Evaluation | undefined
}

export const ChartBodyCompositionAnalysis: React.FC<Props> = ({ data }) => {
  const categories = [
    '% Gordura',
    '% MCM ',
    'Massa Corporal Magra ',
    'Peso Gordo (kg) ',
    'Peso (Kg)',
  ]

  const {
    percentualDeGorduraMedido,
    MCMPercent,
    MCM,
    pesoGordoExcedenteDoProposto,
    pesoGordo,
    pesoGordoSugerido,
    CalcPesoSugerido,
    MCMSugerido,
    sugerePercentGordura,
    MCMPercentSugerido,
  } = useCorporalEvaluation(data)

  const series = [
    {
      name: 'Medido',
      data: [
        +percentualDeGorduraMedido.toFixed(2),
        MCMPercent,
        MCM,
        pesoGordoExcedenteDoProposto,
        pesoGordo,
      ],
    },
    {
      name: 'Sugerido',
      data: [
        +sugerePercentGordura.toFixed(2),
        MCMPercentSugerido,
        MCMSugerido,
        pesoGordoSugerido,
        CalcPesoSugerido,
      ],
    },
  ]

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      id: 'basic-bar',
    },
    legend: {
      show: true,
      position: 'right',
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },

    colors: ['#F44336', '#76FF03'],
    plotOptions: {
      bar: {
        columnWidth: '65%',
        distributed: false,
      },
    },
  }

  return (
    <div className='flex items-center max-h-96 h-full w-full max-w-[700px]'>
      <ApexChart
        type='bar'
        height={300}
        width={720}
        series={series}
        options={chartOptions}
      />
    </div>
  )
}
