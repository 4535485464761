import zod from 'zod'

import { validateCPF } from '~/utils/validateCPF'

export const schema = zod
  .object({
    documentNumber: zod
      .string()
      .length(14, 'Número incompleto')
      .trim()
      .transform((value: string) => value.replace(/\D/g, ''))
      .refine((value) => validateCPF(value), { message: 'CPF inválido' }),
    password: zod.string().min(8, 'Deve ter no mínimo 8 caracteres').trim(),
  })
  .strict()

export type LoginFormType = zod.infer<typeof schema>

export const adminAuthenticationSchema = zod.object({
  email: zod.string().email().trim(),
  password: zod.string().min(8, 'Senha deve ter ao menos 8 caracteres').trim(),
})

export type AuthenticateAdminDTO = zod.infer<typeof adminAuthenticationSchema>
