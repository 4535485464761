import { useCallback, useMemo } from 'react'

import { useAreobicCapabilities } from '~/modules/aerobicCapabilities/useAerobicCapabilities'
import { patchEvaluationAtom } from '~/modules/evalutation/services'
import { Evaluation } from '~/modules/evalutation/types'

import {
  protocoloCAstro3,
  protocoloCastro0,
  protocoloCastro1,
  protocoloCastro2,
  protocoloCastro4,
  protocoloCastro5,
} from './tables'
import { SlopeProtocolData } from './types'

export function useVSAQ(evaluation?: Evaluation) {
  const { mets } = useAreobicCapabilities(evaluation)

  const updateSlopeProtocolData = useCallback(
    (payload: Partial<SlopeProtocolData>) => {
      patchEvaluationAtom({
        slope: {
          ...(evaluation?.slope ? evaluation.slope : {}),
          ...(payload as SlopeProtocolData),
        },
      })
    },
    [evaluation],
  )

  const protocol = useMemo(() => {
    if (mets >= 13) {
      return {
        name: 'Protocolo de Castro 5 (DERCAD/RJ-SOCERJ)',
        data: protocoloCastro5,
      }
    } else if (mets >= 11) {
      return {
        name: 'Protocolo de Castro 4 (DERCAD/RJ-SOCERJ)',
        data: protocoloCastro4,
      }
    } else if (mets >= 9) {
      return {
        name: 'Protocolo de Castro 3 (DERCAD/RJ-SOCERJ)',
        data: protocoloCAstro3,
      }
    } else if (mets >= 7) {
      return {
        name: 'Protocolo de Castro 2 (DERCAD/RJ-SOCERJ)',
        data: protocoloCastro2,
      }
    } else if (mets >= 5) {
      return {
        name: 'Protocolo de Castro 1 (DERCAD/RJ-SOCERJ)',
        data: protocoloCastro1,
      }
    } else
      return {
        name: 'Protocolo de Castro 0 (DERCAD/RJ-SOCERJ)',
        data: protocoloCastro0,
      }
  }, [mets])

  return { updateSlopeProtocolData, protocol }
}
