import axios, { AxiosError } from 'axios'

import { signOut } from '~/modules/authentication/services'

export const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL_API,
})

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ message: string }>) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      signOut()
    }

    return Promise.reject(error)
  },
)
