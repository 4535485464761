import { useCallback, useState } from 'react'

import { Button } from '~/components/Button'
import {
  patchPersonalTrainerExercises,
  restoreStrengthExerciseToGroup,
} from '~/modules/personalTrainerExercises/services'

type Props = {
  group: string
}

export const RecoverGroupButton: React.FC<Props> = ({ group }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleRestoreExercises = useCallback(() => {
    setLoading(true)
    const strengthExercises = restoreStrengthExerciseToGroup(group)
    if (strengthExercises) {
      patchPersonalTrainerExercises({ strengthExercises }).finally(() => {
        setLoading(false)
      })
    }
  }, [group])

  return (
    <Button
      disabled={loading}
      loading={loading}
      onClick={handleRestoreExercises}
    >
      Restaurar exercícios padrão
    </Button>
  )
}
