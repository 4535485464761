import { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { FormStepIndicator } from '~/components/FormStepIndicator'
import { StudentTag } from '~/components/StudentTag'
import { validateAerobicCapabilities } from '~/modules/aerobicCapabilities/services'
import { anamnesisSchema } from '~/modules/anamnesis/schema'
import { validateCorporalEvaluation } from '~/modules/corporalEvaluation/services'
import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'
import { hemodynamicMeasurementsSchema } from '~/modules/hemodynamicMeasurements/schema'

import { antropometrySchema } from '../components/formsContainer/components/antropometryForm/schema'
import { SectionForm } from '../types'

const forms: SectionForm[] = [
  {
    path: 'anamnese?subform=geral',
    label: 'Geral',
    validation: () => true,
  },
  {
    path: 'antropometria',
    label: 'Antropometria',
    validation: () =>
      anamnesisSchema.safeParse(evaluationReadOnlyAtom.get()).success,
  },
  {
    path: 'avaliacao_corporal',
    label: 'Avaliação Corporal',
    validation: () =>
      antropometrySchema.safeParse(evaluationReadOnlyAtom.get()).success,
  },
  {
    path: 'medidas_hemodinamicas',
    label: 'Medidas Hemodinâmicas',
    validation: () => validateCorporalEvaluation(evaluationReadOnlyAtom.get()),
  },
  {
    path: 'capacidade_aerobica',
    label: 'Capacidade Aeróbica',
    validation: () =>
      hemodynamicMeasurementsSchema.safeParse(evaluationReadOnlyAtom.get())
        .success,
  },
  {
    path: 'prescricao_aerobica',
    label: 'Prescrição Aeróbica',
    validation: () => validateAerobicCapabilities(evaluationReadOnlyAtom.get()),
  },
  {
    path: 'agendamento',
    label: 'Agendamento',
    validation: () => validateAerobicCapabilities(evaluationReadOnlyAtom.get()),
  },
  {
    path: 'exercicios_aerobicos',
    label: 'Exercícios Aeróbicos',
    validation: () => validateAerobicCapabilities(evaluationReadOnlyAtom.get()),
  },
  {
    path: 'prescricao_de_exercicios',
    label: 'Prescrição de Exercícios (Série)',
    validation: () => validateAerobicCapabilities(evaluationReadOnlyAtom.get()),
  },
  {
    path: 'personalizacao_da_serie',
    label: 'Personalização da série',
    validation: () => validateAerobicCapabilities(evaluationReadOnlyAtom.get()),
  },
]

export const Header: React.FC = () => {
  const { pathname } = useLocation()

  const swiperRef = useRef<any>(null)

  useEffect(() => {
    let index = 0
    if (pathname.endsWith('anamnese')) {
      index = 0
    } else if (pathname.endsWith('antropometria')) {
      index = 1
    } else if (pathname.endsWith('avaliacao_corporal')) {
      index = 2
    } else if (pathname.endsWith('medidas_hemodinamicas')) {
      index = 3
    } else if (pathname.endsWith('capacidade_aerobica')) {
      index = 4
    } else if (pathname.endsWith('prescricao_aerobica')) {
      index = 5
    } else if (pathname.endsWith('agendamento')) {
      index = 6
    } else if (pathname.endsWith('exercicios_aerobicos')) {
      index = 7
    } else if (pathname.endsWith('prescricao_de_exercicios')) {
      index = 8
    } else if (pathname.endsWith('personalizacao_da_serie')) {
      index = 9
    }

    swiperRef.current?.swiper.slideTo(index)
  }, [pathname])

  return (
    <header className='flex flex-col gap-6 p-2.5 bg-white rounded-2xl'>
      <div className='flex justify-between items-center'>
        <StudentTag />
      </div>

      <nav className='select-none'>
        <Swiper
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView='auto'
          scrollbar={{ hide: true }}
          modules={[Scrollbar]}
        >
          {forms.map((item, index) =>
            item.validation() ? (
              <SwiperSlide key={`form-${index}`} style={{ width: 'auto' }}>
                <Link to={item.path} replace>
                  <FormStepIndicator
                    step={index + 1}
                    last={index + 1 === forms.length}
                    title={item.label}
                    path={item.path}
                    seen
                  />
                </Link>
              </SwiperSlide>
            ) : (
              <SwiperSlide key={`form-${index}`} style={{ width: 'auto' }}>
                <div key={`form-${index}`}>
                  <FormStepIndicator
                    step={index + 1}
                    last={index + 1 === forms.length}
                    title={item.label}
                    path={item.path}
                  />
                </div>
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </nav>
    </header>
  )
}
