import zod from 'zod'

import { genderEnum, maritalStatusEnum } from '~/modules/students/variables'
import {
  convertToUTC,
  normalizeDate,
  validateDate,
} from '~/utils/normalizeDate'

export const registerStudentSchema = zod.object({
  name: zod.string().nonempty('Campo obrigatório').trim(),
  phoneNumber: zod
    .array(
      zod
        .string()
        .min(15, 'Deve ter no mínimo 11 números')
        .trim()
        .transform((value) => value.replace(/\D/g, '')),
    )
    .nonempty('Campo obrigatório'),
  birthday: zod
    .string()
    .nonempty('Campo obrigatório')
    .trim()
    .superRefine((val, ctx) => {
      const normalizedDate = normalizeDate(val)
      if (!validateDate(normalizedDate)) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: 'Formato de data inválido',
        })
      }
    }),
  documentNumber: zod
    .string()
    .nonempty('Campo obrigatório')
    .length(14, 'Número incompleto')
    .trim()
    .transform((value) => value.replace(/\D/g, '')),
  socialDocumentNumber: zod
    .string()
    .nonempty('Campo obrigatório')
    .length(13, 'Número incompleto')
    .trim()
    .transform((value) => value.replace(/\D/g, '')),
  email: zod.string().email('Deve ser um e-mail válido').trim(),
  maritalStatus: zod.enum(maritalStatusEnum, {
    required_error: 'Campo obrigatório',
  }),
  sex: zod.enum(genderEnum, {
    required_error: 'Campo obrigatório',
  }),
})

export type RegisterStudentDTO = zod.infer<typeof registerStudentSchema>
