import { WeekDays } from '~/types/types'

export const weekDaysOrder = <WeekDays[]>[
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
]

export function getWeekdayIndex(day: WeekDays): number {
  switch (day) {
    case 'Segunda':
      return 1
    case 'Terça':
      return 2
    case 'Quarta':
      return 3
    case 'Quinta':
      return 4
    case 'Sexta':
      return 5
    case 'Sábado':
      return 6
    case 'Domingo':
    default:
      return 0
  }
}
