import { Option } from '~/types/types'

import { LicenseOperation } from './types'

export const licenseOperationsEnum = [
  'dec_number_of_customers',
  'inc_number_of_customers',
  'start',
] as const

export const licenseOperationsOptions: Option<LicenseOperation>[] = [
  { label: 'adicionar', value: 'inc_number_of_customers' },
  { label: 'remover', value: 'dec_number_of_customers' },
]
