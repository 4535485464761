export type Session = {
  name: string
  duration: string
  kcal: number
}

export type ExerciseSessions = {
  sessionTitle: string
  exerciseTitle: string
  durationTitle: string
  kcalTitle: string
  sessions: Session[]
}

export const chunkArray = (arr: any[], size: number) => {
  const chunkedArr = []
  for (let i = 0; i < arr.length; i += size) {
    chunkedArr.push(arr.slice(i, i + size))
  }
  return chunkedArr
}

const arr = []

for (let index = 0; index < 96; index++) {
  arr.push({
    name: 'Andar',
    duration: '49 min',
    kcal: 200,
  })
}

export const sessionsMock: ExerciseSessions[] = [
  {
    sessionTitle: 'Sessão',
    exerciseTitle: 'Exercício',
    durationTitle: 'Dur(min)',
    kcalTitle: '(Kcal)',
    sessions: arr,
  },
]
