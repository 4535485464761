import { useCallback, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { RadioButton } from '~/components/RadioButton'
import { TextInput } from '~/components/TextInput'
import { useAreobicCapabilities } from '~/modules/aerobicCapabilities/useAerobicCapabilities'
import { evaluationAtom } from '~/modules/evalutation/data'
import { insertToast } from '~/modules/toasts/services'
import { useAtom } from '~/observers/jotai'

import { TableInput } from './TableInput'
import { useVSAQ } from './useVSAQ'

const questions = [
  '1. Comer, vestir-se, trabalhar sentado',
  '2. Fazer compras, cozinhar, descer oito degraus',
  '3. Andar devagar em uma superfície plana, por um ou dois quarteirões, serviço domestico leve',
  '4. Trabalhar leve no quintal ou jardim, como varrer folhas, pintura ou carpintaria leve',
  '5. Caminhar com agilidade a 6 km/h, dançar socialmente ou lavar o carro',
  '6. Jogar golfe (nove buracos), carpintaria pesada, cortar grama',
  '7. Jogar tênis, carregar 25Kg, subir ladeira caminhando',
  '8. Carregar sacolas de supermercado escada acima, Mover mobília pesada, corrida leve em superfície plana, subir escada rapidamente',
  '9. Andar de bicicleta em ritmo moderado, cerrar madeira, pular corda (devagar)',
  '10. Nadar, subir ladeira de bicicleta, correr a 9,5 km/h',
  '11. Jogar uma partida de basquete, subir 2 lances de escada carregando algo pesado, como uma criança no colo, andar de bicicleta em ritmo acelerado continuamente',
  '12. Correr a uma velocidade constante de 12Km/h em uma superfície plana',
  '13. Qualquer atividades física competitiva, incluindo correr intermitente, remar ou pedalar',
]

export const Slope: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const { mets, velocityInKmPerHour } = useAreobicCapabilities(evaluation)
  const { protocol, updateSlopeProtocolData } = useVSAQ(evaluation)

  const [showModa, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleSubmitForm = useCallback(() => {
    if (mets === 0) {
      insertToast({
        title: 'Atenção',
        message:
          'Escolha a resposta do questionário que mais se aproxima do máximo de esforço do aluno.',
        duration: 4,
      })
      return
    }

    if (evaluation?.slope?.speed === 0) {
      insertToast({
        title: 'Atenção',
        message: 'Indique a velocidade final do teste',
        duration: 4,
      })
      return
    }

    setShowModal(false)
  }, [evaluation, mets])

  return (
    <>
      <Button onClick={handleToggleModal}>
        Calcular VO2 (Executar Protocolo)
      </Button>
      <Modal visible={showModa}>
        <header className='flex items-center justify-between mb-3'>
          <h1 className='text-lg font-bold'>
            Protocolo de Rampa Manual Individualizado
          </h1>
          <button
            type='button'
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={handleToggleModal}
          >
            <FiX size={24} />
          </button>
        </header>
        <section className='bg-blue-50 p-3 border border-gpa-blue-50 rounded-md'>
          <h3 className='font-bold mb-3'>Instruções</h3>
          <article>
            Antes de começar o seu teste em esteira hoje, precisamos avaliar
            quais são seus limites, durante as atividades diárias. A seguir está
            uma lista de atividades que aumentam em dificuldade a cada item.
            Pense cuidadosamente, em seguida, informe a primeira atividade que,
            se realizada por um período de tempo, normalmente causaria fadiga,
            falta de ar, desconforto no peito, ou faria com que você desejasse
            parar. Se você normalmente não executar uma determinada atividade,
            tente imaginar como seria se você fizesse
          </article>
        </section>

        <section className='flex flex-col gap-3 my-3'>
          <h3 className='font-bold'>1 - Escolha a resposta mais adequada</h3>
          {questions.map((q, index) => (
            <div key={`question-${index}`} className='flex items-center'>
              <RadioButton
                name='question'
                value={index}
                defaultChecked={index + 1 === evaluation?.slope.vsaq}
                label={q}
                onChange={() =>
                  updateSlopeProtocolData({
                    vsaq: index + 1,
                    speed: 0,
                    time: '',
                    steepness: 0,
                  })
                }
              />
            </div>
          ))}
        </section>
        <section>
          <h3 className='font-bold'>2 - Indique o final do teste</h3>
          {evaluation?.slope.vsaq === 0 ? null : (
            <>
              <p>{protocol.name}</p>
              <TableInput
                data={protocol.data}
                time={evaluation?.slope.time}
                onChange={updateSlopeProtocolData}
              />
            </>
          )}
        </section>
        <section className='flex items-center justify-between border-t border-gpa-gray-100 pt-2 mt-3'>
          <div className='max-w-[100px]'>
            <TextInput label='METs' value={mets.toFixed(1)} readOnly />
          </div>
          <div className='flex items-end justify-end gap-4'>
            <div className='max-w-[140px]'>
              <TextInput
                label='Velocidade (Km/h)'
                value={velocityInKmPerHour.toFixed(1)}
                readOnly
              />
            </div>
            <div className='max-w-[100px]'>
              <TextInput
                label='Inclinação'
                value={evaluation?.slope.steepness}
                readOnly
              />
            </div>
            <Button onClick={handleSubmitForm}>OK</Button>
          </div>
        </section>
      </Modal>
    </>
  )
}
