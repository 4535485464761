import { aerobicMacrocycleAtom } from '~/modules/standardizedSeries/data'
import { AerobicRow } from '~/modules/standardizedSeries/types'
import { useAtom } from '~/observers/jotai'

import { chunkArray } from './aerobicPrescriptionMock'
import { Header } from './Header'

type Props = {
  min: number
  max: number
}

export const AerobicExercisesPrescription: React.FC<Props> = ({ min, max }) => {
  const [session] = useAtom(aerobicMacrocycleAtom)
  const chunks = chunkArray(session.slice(min - 1, max), 43)

  return (
    <div className='flex flex-col px-8  py-7 w-full page-break'>
      <Header title='Prescrição Aeróbia' />

      {/* Section Header Tabela */}
      <div className='grid grid-cols-2 border border-black bg-zinc-300 mt-4 mb-2'>
        <div className='whitespace-nowrap text-xs '>
          <span className='font-bold pl-2 '>Zona Alvo no solo</span>
          <span className='pl-6'>Zona Alvo no solo 136 - 142</span>
        </div>

        <div className='whitespace-nowrap text-xs '>
          <span className='font-bold text-sm pl-6 '>Zona Alvo na água</span>
          <span className='pl-6'>Zona Alvo na água 0 - 0</span>
        </div>
      </div>

      {/* Section Tabela */}
      <div className='grid grid-cols-3 text-xs'>
        {chunks.map((chunk, i) => (
          <div key={'column-' + i}>
            <div className='grid grid-cols-4 font-bold border-b border-black'>
              <div className='flex justify-start pl-2 '>Sessão</div>
              <div className='flex justify-start'>Exercício</div>

              <div className='flex justify-center'>Dur(min)</div>
              <div className='flex justify-center'>(Kcal)</div>
            </div>
            {chunk.map((c: AerobicRow) => (
              <div
                key={`session-${c.session}`}
                className='grid grid-cols-4 border border-black border-t-0'
              >
                <div className='flex justify-start pl-2 '>{c.session}</div>
                <div className='flex justify-start'>{c.exercise}</div>

                <div className='flex justify-center'>
                  {c.duration.toFixed()}
                </div>
                <div className='flex justify-center'>{c.calories}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
