import { RefObject, createRef } from 'react'

import { ParQFormType } from './schemas'

export const questionsRefs = new Map<
  keyof ParQFormType,
  RefObject<HTMLDivElement>
>([
  ['bloodPressureMedication', createRef<HTMLDivElement>()],
  ['boneAche', createRef<HTMLDivElement>()],
  ['chestPain', createRef<HTMLDivElement>()],
  ['chestPainLastMonth', createRef<HTMLDivElement>()],
  ['dizziness', createRef<HTMLDivElement>()],
  ['reasonNotToExercise', createRef<HTMLDivElement>()],
  ['supervisedPhysicalActivity', createRef<HTMLDivElement>()],
])
