import { useCallback, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { deleteStudent } from '~/modules/students/services'

import { studentCardContext } from '../..'

type Props = {
  onSubmit: () => void
}

export const DeleteStudentButton: React.FC<Props> = ({ onSubmit }) => {
  const { student } = useContext(studentCardContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleDeleteStudent = useCallback(() => {
    setLoading(true)
    deleteStudent(student.id).then((success) => {
      if (success) {
        onSubmit()
      }
      setLoading(false)
    })
  }, [student.id, onSubmit])

  return (
    <>
      <Button color='error' onClick={handleToggleModal}>
        Remover
      </Button>
      {createPortal(
        <Modal visible={showModal}>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center justify-between mb-4'>
              <h1 className='text-xl'>Atenção!</h1>
              <button
                type='button'
                className='p-2 rounded-full hover:bg-slate-50'
                onClick={handleToggleModal}
              >
                <FiX size={26} />
              </button>
            </div>

            <p>
              Tem certeza que deseja apagar os dados desse aluno?{' '}
              <b>As avaliações também serão apagadas!</b>
            </p>

            <div className='flex gap-4 flex-wrap'>
              <Button
                className='flex-1'
                color='error'
                disabled={loading}
                onClick={handleDeleteStudent}
              >
                Sim, tenho certeza
              </Button>
              <Button
                className='flex-1'
                color='error'
                mode='outlined'
                onClick={handleToggleModal}
              >
                Não, mantenha o registro
              </Button>
            </div>
          </div>
        </Modal>,
        document.getElementById('modals')!,
      )}
    </>
  )
}
