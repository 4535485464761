import { useCallback, useMemo, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { SelectInput } from '~/components/SelectInput'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import {
  patchPersonalTrainerExercises,
  removeStrengthExerciseFromSeriesGroup,
} from '~/modules/personalTrainerExercises/services'
import { useAtom } from '~/observers/jotai'
import { Option } from '~/types/types'

import { NewExerciseForm } from './NewExerciseForm'
import { RecoverGroupButton } from './RecoverGroupButton'

export const StrengthExercises: React.FC = () => {
  const [personalTrainerExercises] = useAtom(personalTrainerExercisesAtom)
  const [selectedGroup, setSelectedGroup] = useState<string>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const exercisesOptions = useMemo<Option<string>[]>(
    () =>
      !personalTrainerExercises?.strengthExercises
        ? []
        : personalTrainerExercises?.strengthExercises.map<Option<string>>(
            (e) => ({
              label: e.groupName,
              value: e.groupName,
            }),
          ),
    [personalTrainerExercises],
  )

  const exercises = useMemo(() => {
    const section = personalTrainerExercises?.strengthExercises.find(
      (e) => e.groupName === selectedGroup,
    )

    return section?.exercises ?? []
  }, [personalTrainerExercises?.strengthExercises, selectedGroup])

  const handleToggleEditModal = useCallback(() => {
    setShowEditModal((state) => !state)
  }, [])

  const handleRemoveExercise = useCallback(
    async (exercise: string, group: string) => {
      const strengthExercises = removeStrengthExerciseFromSeriesGroup(
        exercise,
        group,
      )

      if (strengthExercises) {
        await patchPersonalTrainerExercises({ strengthExercises })
      }
    },
    [],
  )

  return (
    <div className='flex flex-col gap-3'>
      <h2 className='font-bold'>
        Aqui você pode verificar e editar a lista de exercícios
      </h2>
      <div className='flex gap-3 flex-wrap items-center'>
        <SelectInput
          label='Selecione um grupo de exercícios'
          options={exercisesOptions}
          value={selectedGroup ?? ''}
          onChange={setSelectedGroup}
        />
      </div>
      <div className='grid grid-cols-[repeat(auto-fit,_minmax(_180px,auto))] gap-3 min-h-[80px] bg-slate-100 p-3 rounded-lg max-h-[300px] overflow-y-auto items-center'>
        {exercises.map((e) => (
          <p
            key={`exercise-${e.replace(' ', '-')}`}
            className='bg-blue-100 p-2 rounded-lg text-center'
          >
            {e}
          </p>
        ))}
      </div>

      {selectedGroup ? (
        <Button className='flex-1' onClick={handleToggleEditModal}>
          Editar Grupo
        </Button>
      ) : null}

      <Modal visible={showEditModal}>
        {selectedGroup ? (
          <div className='flex flex-col gap-4'>
            <div className='flex justify-between'>
              <h2 className='text-xl font-medium'>
                Editar Grupo: {selectedGroup}
              </h2>
              <button
                className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
                onClick={handleToggleEditModal}
              >
                <FiX size={24} />
              </button>
            </div>

            <NewExerciseForm exerciseGroup={selectedGroup} />

            <div className='flex flex-col gap-4'>
              <p>Clique no exercício abaixo para apagá-lo</p>
              <div className='flex flex-wrap gap-4'>
                {exercises.map((e) => (
                  <ExerciseTag
                    key={`exercise-tag-${e.replace(' ', '_')}`}
                    exercise={e}
                    onDelete={() => handleRemoveExercise(e, selectedGroup)}
                  />
                ))}
              </div>
              <RecoverGroupButton group={selectedGroup} />
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-4'>
            <div className='flex justify-between'>
              <h2 className='text-xl font-medium'>Aviso</h2>
              <button
                className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
                onClick={handleToggleEditModal}
              >
                <FiX size={24} />
              </button>
            </div>

            <p>Para editar, selecione primeiro um grupo de exercícios</p>

            <Button className='self-end' onClick={handleToggleEditModal}>
              Entendido
            </Button>
          </div>
        )}
      </Modal>
    </div>
  )
}
