import { useNavigate } from 'react-router-dom'

import { SubnavItem } from '~/components/Subnav'
import { useQuery } from '~/hooks/useQuery'
import {
  evaluationAtom,
  evaluationReadOnlyAtom,
} from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

import { FormList } from '../../types'
import { coronarianRiskFactorSchema } from '../coronarianRiskFactorForm/schema'
import { generalFormSchema } from '../generalForm/schema'
import { parqFormSchema } from '../parqForm/schemas'

export const formList: FormList[] = [
  { label: 'Geral', key: 'geral', validation: () => true },
  {
    label: 'Par-Q',
    key: 'parQ',
    validation: (data: any) => generalFormSchema.safeParse(data).success,
  },
  {
    label: 'Fator de Risco Coronariano',
    key: 'fatorDeRiscoCoronariano',
    validation: (data: any) => parqFormSchema.safeParse(data).success,
  },
  {
    label: 'Stress',
    key: 'stress',
    validation: (data: any) =>
      coronarianRiskFactorSchema.safeParse(data).success,
  },
]

export const Navigator: React.FC = () => {
  const query = useQuery()
  const menu = query.get('subform')
  const navigate = useNavigate()
  const [evaluation] = useAtom(evaluationReadOnlyAtom)

  return (
    <nav className='flex overflow-x-auto gap-2 p-2'>
      {formList.map((item, index) => (
        <SubnavItem
          key={item.key}
          title={item.label}
          active={menu === item.key || (!menu && index === 0)}
          disabled={!item.validation(evaluation)}
          onClick={() => {
            navigate('?subform=' + item.key, {
              replace: true,
            })
          }}
        />
      ))}
    </nav>
  )
}
