import { motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useQuery } from '~/hooks/useQuery'
import { anamnesisSchema, generalSchema } from '~/modules/anamnesis/schema'
import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'

import { FormsPreview } from './components/FormsPreview'
import { GeneralReportPreview } from './components/GeneralReportPreview'
import { PreviewTabs } from './types'

export const InfoBoards: React.FC = () => {
  const query = useQuery()

  const { pathname } = useLocation()
  const shouldHide = useMemo(() => pathname.endsWith('macrociclos'), [pathname])
  const [currentTab, setCurrentTab] = useState<PreviewTabs>('anamnesis')

  useEffect(() => {
    if (query.get('subform') && query.get('subform') !== 'geral') {
      setCurrentTab('evaluations')
    }
  }, [query])

  return shouldHide ? null : (
    <div className='hidden md:flex flex-col flex-1 gap-4 h-full max-w-[440px] min-w-[400px] w-full py-7'>
      <div className='flex flex-col flex-1 bg-white rounded-2xl p-4 overflow-hidden'>
        <nav className='flex bg-slate-100 transition-all duration-200 self-start rounded-md p-1 mb-1'>
          <button
            className='py-1 px-2 relative'
            onClick={() => setCurrentTab('anamnesis')}
          >
            <span className='relative z-10'>Anamnese</span>
            {currentTab === 'anamnesis' ? (
              <motion.div
                layoutId='previewTabs'
                transition={{ type: 'spring', bounce: 0.1, duration: 0.4 }}
                className='absolute inset-0 rounded-md bg-white'
              />
            ) : null}
          </button>
          {generalSchema.safeParse(evaluationReadOnlyAtom.get()).success ? (
            <button
              className='py-1 px-2 relative'
              onClick={() => setCurrentTab('evaluations')}
            >
              <span className='relative z-10'>Avaliação</span>
              {currentTab === 'evaluations' ? (
                <motion.div
                  layoutId='previewTabs'
                  transition={{ type: 'spring', bounce: 0.1, duration: 0.4 }}
                  className='absolute inset-0 rounded-md bg-white'
                />
              ) : null}
            </button>
          ) : null}
        </nav>
        <div className='flex flex-col flex-1 gap-2.5 px-3 overflow-y-auto py-2.5 border border-gpa-gray-500 rounded-lg'>
          {currentTab === 'anamnesis' ? (
            <GeneralReportPreview />
          ) : (
            <FormsPreview />
          )}
        </div>
      </div>
    </div>
  )
}
