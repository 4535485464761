import { useCoronarianRiskFactorFormula } from '~/modules/anamnesis/useCoronarianRiskFactorFormula'
import { useStressFormula } from '~/modules/anamnesis/useStressFormula'
import { useIMCFormula } from '~/modules/antropometry/useIMCData'
import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

import { ChartIMC } from './ChartIMC'
import { ChartIRCQ } from './ChartIRCQ'
import { Header } from './Header'

export const CoronarianRisk: React.FC = () => {
  const [evaluation] = useAtom(evaluationReadOnlyAtom)
  const { coronarianRiskFactorResult } =
    useCoronarianRiskFactorFormula(evaluation)

  const { calcIMC, calcIMCClassificacao, calcICM_CC_Classificacao, calcIRCQ } =
    useIMCFormula(evaluation)

  const analiseDeStress = useStressFormula(evaluation)

  return (
    <div className='flex flex-col px-8  py-7 w-full page-break'>
      <Header title='IMC / IRCQ / Fator de Risco Coronariano / IMC + CC / STRESS' />

      {/* Section Índice de Massa Corporal (IMC) */}
      <div className='grid grid-cols-2 border border-black bg-zinc-300 my-4'>
        <div>
          <span className='font-bold  pl-4'>
            Índice de Massa Corporal (IMC)
          </span>
          <span className='pl-16'>{calcIMC()}</span>
        </div>

        <div>
          <span className='font-bold  pl-16'>Classificação</span>
          <span className='pl-16'>{calcIMCClassificacao().label}</span>
        </div>
      </div>

      {/* Section Gráfico Índice de Massa Corporal (IMC) */}
      <ChartIMC />

      {/* Section Índice de Relação Cintura/Quadril  */}
      <div className='grid grid-cols-2 border border-black bg-zinc-300 my-3'>
        <div>
          <span className='font-bold  pl-4'>
            Índice de Relação Cintura/Quadril
          </span>
          <span className='pl-16'>{calcIRCQ().value?.toFixed(2)}</span>
        </div>

        <div>
          <span className='font-bold  pl-16'>Classificação</span>
          <span className='pl-16'>Risco Muito alto</span>
        </div>
      </div>

      {/* Section Gráfico Índice de Relação Cintura/Quadril */}
      <ChartIRCQ />

      {/* Section I.M.C (O.M.S) */}
      <div className='flex border border-black bg-zinc-300 my-4'>
        <span className='font-bold  pl-4'>I.M.C (O.M.S)</span>
      </div>

      <div className='flex gap-12 '>
        <span className='font-bold pl-8'>Classificação</span>
        <span className=''>{calcICM_CC_Classificacao().label}</span>
      </div>

      {/* Section Fator de Risco Coronariano */}
      <div className='flex border border-black bg-zinc-300 my-4'>
        <span className='font-bold  pl-4'>Fator de Risco Coronariano</span>
      </div>

      <div className='flex flex-col'>
        <div className='flex gap-12 '>
          <span className='font-bold pl-8'>Pontos</span>
          <span className='pl-11'>{coronarianRiskFactorResult.totalScore}</span>
        </div>

        <div className='flex gap-12 '>
          <span className='font-bold pl-8'>Classificação</span>
          <span className=''>{coronarianRiskFactorResult.message}</span>
        </div>
      </div>

      {/* Section Teste de Stress */}
      <div className='flex border border-black bg-zinc-300 my-4'>
        <span className='font-bold  pl-4'>Teste de Stress</span>
      </div>

      <div className='flex gap-12 '>
        <span className='font-bold pl-8'>Resultado</span>
        <span className=''>{analiseDeStress.message}</span>
      </div>
    </div>
  )
}
