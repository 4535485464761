import { Forms } from './components/Forms'
import { Navigator } from './components/Navigator'

export const AnamnesisForm: React.FC = () => {
  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <Navigator />
      <Forms />
    </div>
  )
}
