import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { documentNumberMask } from '~/components/TextInput/utils/mask'
import {
  CreatePersonalTrainerDTO,
  createPersonalTrainerSchema,
} from '~/modules/admin/schemas'
import { createPersonalTrainer } from '~/modules/admin/services'

type Props = {
  onSubmit?: () => void
}

export const PersonalTrainerForm: React.FC<Props> = ({ onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<CreatePersonalTrainerDTO>({
    resolver: zodResolver(createPersonalTrainerSchema),
  })

  const handleSubmitForm = useCallback(
    async (payload: CreatePersonalTrainerDTO) => {
      await createPersonalTrainer(payload)
      onSubmit && onSubmit()
    },
    [onSubmit],
  )

  return (
    <form
      className='flex flex-col gap-2'
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <TextInput
        {...register('name')}
        error={errors.name?.message}
        label='Nome'
      />

      <Controller
        control={control}
        name='documentNumber'
        render={({ field }) => (
          <TextInput
            label='Número de CPF'
            value={field.value}
            onChange={(e) => field.onChange(documentNumberMask(e.target.value))}
            error={errors.documentNumber?.message}
          />
        )}
      />

      <div className='flex flex-col sm:flex-row gap-x-4 gap-y-2 '>
        <TextInput
          label='E-mail'
          {...register('email')}
          role='presentation'
          autoComplete='new-password'
          error={errors.email?.message}
        />
        <TextInput
          label='Limite inicial de alunos'
          {...register('maximumNumberOfCustomers', { valueAsNumber: true })}
          error={errors.maximumNumberOfCustomers?.message}
        />
      </div>

      <TextInput
        label='Senha'
        type='password'
        {...register('password')}
        error={errors.password?.message}
      />

      <Button loading={isSubmitting}>Cadastrar</Button>
    </form>
  )
}
