import { useCallback, useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from 'react-icons/io'

import { Button } from '~/components/Button'
import { SelectInput } from '~/components/SelectInput'
import { TextInput } from '~/components/TextInput'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  getIntensityRange,
  patchEvaluationAtom,
} from '~/modules/evalutation/services'
import { PeriodizedSeries } from '~/modules/evalutation/types'
import { exerciseTypesOptions } from '~/modules/standardizedSeries/variables'
import { moveItemsInArray } from '~/utils/array'
import { debounce } from '~/utils/debounce'

import { ExercisesModal } from './ExercisesModal'

type Props = {
  data: PeriodizedSeries
  index: number
}

const debouncer = debounce()

export const Series: React.FC<Props> = ({ data, index }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [state, setState] = useState(() => ({
    description: data.description,
    intensity: data.intensity,
  }))

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleMoveItemInList = useCallback(
    (index: number, direction: 'up' | 'down') => {
      moveItemsInArray(index, data.exercises, direction)
      patchEvaluationAtom({
        periodizedSeries: evaluationAtom.get()?.periodizedSeries,
      })
    },
    [data],
  )

  const handleRemoveItemFromList = useCallback(
    (index: number) => {
      data.exercises.splice(index, 1)
      patchEvaluationAtom({
        periodizedSeries: evaluationAtom.get()?.periodizedSeries,
      })
    },
    [data],
  )

  useEffect(() => {
    data.description = state.description
    data.intensity = state.intensity

    debouncer(() => {
      patchEvaluationAtom({
        periodizedSeries: evaluationAtom.get()?.periodizedSeries,
      })
    })
  }, [state, data])

  return (
    <>
      <div className='flex flex-col border-2 p-4 rounded-lg w-full'>
        <h1>Série {data.letter}</h1>
        <SelectInput
          options={exerciseTypesOptions}
          label='Tipo de Trabalho'
          value={data.type}
          onChange={(v) => {
            data.type = v
            data.intensity = getIntensityRange(v)[0]
            patchEvaluationAtom({
              periodizedSeries: evaluationAtom.get()?.periodizedSeries,
            })
          }}
        />

        <TextInput
          mode='horizontal'
          label='Descrição'
          value={state.description}
          onChange={(e) =>
            setState((state) => ({ ...state, description: e.target.value }))
          }
        />

        <div className='flex gap-3'>
          <div className=''>
            <TextInput
              mode='horizontal'
              label='Intensidade'
              value={state.intensity}
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  intensity: +e.target.value.replace(/\D/, ''),
                }))
              }
            />
          </div>
          <p className='mt-1.5 flex-1 whitespace-nowrap'>
            {getIntensityRange(data.type).join(' ~ ')}
          </p>
        </div>

        <div className='flex flex-col gap-3'>
          <div className='bg-slate-100 p-2 rounded-md gap-3 h-[200px] overflow-y-auto'>
            <ul className='flex flex-col gap-3 bg-slate-100 rounded-md p-2'>
              {data.exercises?.map((exercise, i) => (
                <li
                  key={`exercise-${index}-${i}`}
                  className='flex bg-gpa-blue-50 p-1.5 rounded-md gap-2'
                >
                  <span className='flex-1'>{exercise.name}</span>
                  <div className='flex gap-1'>
                    {i !== 0 ? (
                      <button
                        className='p-1 rounded-full bg-gpa-blue-500 active:bg-blue-500 h-6 w-6'
                        onClick={() => handleMoveItemInList(i, 'up')}
                      >
                        <IoIosArrowUp className='text-white text-md' />
                      </button>
                    ) : null}
                    {i + 1 !== data.exercises.length ? (
                      <button
                        className='p-1 rounded-full bg-gpa-blue-500 h-6 w-6 active:bg-blue-500'
                        onClick={() => handleMoveItemInList(i, 'down')}
                      >
                        <IoIosArrowDown className='text-white text-md' />
                      </button>
                    ) : null}

                    <button
                      className='flex justify-center items-center rounded-full bg-gpa-red h-6 w-6 active:bg-red-600'
                      onClick={() => handleRemoveItemFromList(i)}
                    >
                      <IoIosClose className='text-white text-lg' />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <Button onClick={handleToggleModal}>
            Editar Exercícios da Série
          </Button>
        </div>
      </div>
      <ExercisesModal
        visible={showModal}
        onClose={handleToggleModal}
        data={data}
      />
    </>
  )
}
