import { ChangeEvent, useCallback, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { BsPlus } from 'react-icons/bs'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { SearchInput } from '~/components/SearchInput'
import { PersonalTrainerForm } from '~/forms/PersonalTrainerForm'
import { debounce } from '~/utils/debounce'

import { context } from './context'

const debouncer = debounce()

export const Header: React.FC = () => {
  const { handleChangeSearch } = useContext(context)
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      debouncer(() => handleChangeSearch(e.target.value.replace(/[.-]/g, '')))
    },
    [handleChangeSearch],
  )

  return (
    <div className='flex items-center justify-between p-2 py-3 border-b-2 border-b-slate-300'>
      <div className='max-w-[270px]'>
        <SearchInput
          placeholder='Buscar por nome ou CPF'
          onChange={handleSearch}
        />
      </div>
      <div className='max-w-[270px]'>
        <Button onClick={handleToggleModal}>
          <BsPlus size={26} /> Novo Personal
        </Button>

        {createPortal(
          <Modal visible={showModal}>
            <div className='flex items-center justify-between'>
              <h1 className='text-xl'>Cadastrar Novo Personal Trainer</h1>
              <button
                type='button'
                className='p-2 rounded-full hover:bg-slate-50'
                onClick={handleToggleModal}
              >
                <FiX size={26} />
              </button>
            </div>

            <PersonalTrainerForm onSubmit={handleToggleModal} />
          </Modal>,
          document.getElementById('modals')!,
        )}
      </div>
    </div>
  )
}
