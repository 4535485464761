import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

import { Header } from './Header'

export const ParQ: React.FC = () => {
  const [data] = useAtom(evaluationReadOnlyAtom)

  return (
    <div className='relative flex flex-col px-8 py-7 w-full h-full page-break'>
      <Header title='Par-Q' borderBottom />

      <div className='flex flex-col text-xs py-3 gap-5'>
        {/* Section 1 */}
        <div className='flex items-center gap-3'>
          <span className='flex-1'>
            1-) Algum médico já disse que você possui algum problema de coração
            e que só deveria realizar atividade física supervisionada por
            profissionais de saúde?
          </span>
          <div className='flex gap-3'>
            <div className='flex gap-2 items-center'>
              <span>Sim</span>
              <input
                type='checkbox'
                defaultChecked={data?.supervisedPhysicalActivity}
                name='section1'
                id=''
                className='w-6 h-6 '
              />
            </div>
            <div className='flex gap-2 items-center'>
              <span>Não</span>
              <input
                type='checkbox'
                defaultChecked={!data?.supervisedPhysicalActivity}
                name='section1'
                id=''
                className='w-6 h-6 '
              />
            </div>
          </div>
        </div>

        {/* Section 2 */}
        <div className='flex items-center gap-3'>
          <span className='flex-1'>
            2-) Você sente dores no peito quando pratica atividade física?
          </span>
          <div className='flex gap-3'>
            <div className='flex gap-2 items-center'>
              <span>Sim</span>
              <input
                type='checkbox'
                defaultChecked={data?.supervisedPhysicalActivity}
                name='section1'
                id=''
                className='w-6 h-6 '
              />
            </div>
            <div className='flex gap-2 items-center'>
              <span>Não</span>
              <input
                type='checkbox'
                defaultChecked={!data?.supervisedPhysicalActivity}
                name='section1'
                id=''
                className='w-6 h-6 '
              />
            </div>
          </div>
        </div>

        {/* Section 3 */}
        <div className='flex items-center gap-3'>
          <span className='flex-1'>
            3-) No último mês você sentiu dores no peito quando praticava
            atividade física?
          </span>
          <div className='flex gap-3'>
            <div className='flex gap-2 items-center'>
              <span>Sim</span>
              <input
                type='checkbox'
                defaultChecked={data?.chestPainLastMonth}
                name='chestPainLastMonth'
                id=''
                className='w-6 h-6 '
              />
            </div>
            <div className='flex gap-2 items-center'>
              <span>Não</span>
              <input
                type='checkbox'
                defaultChecked={!data?.chestPainLastMonth}
                name='chestPainLastMonth'
                id=''
                className='w-6 h-6 '
              />
            </div>
          </div>
        </div>

        {/* Section 4 */}
        <div className='flex items-center gap-3'>
          <span className='flex-1'>
            4-) Você apresenta desequilíbrio devido a tontura e/ou perda da
            consciência?
          </span>
          <div className='flex gap-3'>
            <div className='flex gap-2 items-center'>
              <span>Sim</span>
              <input
                type='checkbox'
                defaultChecked={data?.dizziness}
                name='dizziness'
                id=''
                className='w-6 h-6 '
              />
            </div>
            <div className='flex gap-2 items-center'>
              <span>Não</span>
              <input
                type='checkbox'
                defaultChecked={!data?.dizziness}
                name='dizziness'
                id=''
                className='w-6 h-6 '
              />
            </div>
          </div>
        </div>

        {/* Section 5 */}
        <div className='flex items-center gap-3'>
          <span className='flex-1'>
            5-) Você possui algum problema ósseo ou articular que poderia ser
            piorado pela atividade física?
          </span>
          <div className='flex gap-3'>
            <div className='flex gap-2 items-center'>
              <span>Sim</span>
              <input
                type='checkbox'
                defaultChecked={data?.boneAche}
                name='boneAche'
                id=''
                className='w-6 h-6 '
              />
            </div>
            <div className='flex gap-2 items-center'>
              <span>Não</span>
              <input
                type='checkbox'
                defaultChecked={!data?.boneAche}
                name='boneAche'
                id=''
                className='w-6 h-6 '
              />
            </div>
          </div>
        </div>

        {/* Section 6 */}
        <div className='flex items-center gap-3'>
          <span className='flex-1'>
            6-) Você toma atualmente algum medicamento para pressão arterial
            e/ou problema de coração?
          </span>
          <div className='flex gap-3'>
            <div className='flex gap-2 items-center'>
              <span>Sim</span>
              <input
                type='checkbox'
                defaultChecked={data?.bloodPressureMedication}
                name='bloodPressure'
                className='w-6 h-6 '
              />
            </div>
            <div className='flex gap-2 items-center'>
              <span>Não</span>
              <input
                type='checkbox'
                defaultChecked={!data?.bloodPressureMedication}
                name='bloodPressure'
                className='w-6 h-6 '
              />
            </div>
          </div>
        </div>

        {/* Section 7 */}
        <div className='flex items-center gap-3'>
          <span className='flex-1'>
            7-) Sabe de alguma outra razão pela qual você não deve realizar
            atividade física?
          </span>
          <div className='flex gap-3'>
            <div className='flex gap-2 items-center'>
              <span>Sim</span>
              <input
                type='checkbox'
                defaultChecked={data?.reasonNotToExercise}
                name='section1'
                id=''
                className='w-6 h-6 '
              />
            </div>
            <div className='flex gap-2 items-center'>
              <span>Não</span>
              <input
                type='checkbox'
                defaultChecked={!data?.reasonNotToExercise}
                name='section1'
                id=''
                className='w-6 h-6 '
              />
            </div>
          </div>
        </div>
      </div>

      {/* Sugestão */}
      <div className='flex mt-96'>
        <span className='font-bold'>
          Se apenas uma das questões for respondida com um &ldquo;sim&rdquo;,
          seria recomendado uma avaliação de um medico antes do início do
          programa.
        </span>
        <div className='flex justify-center text-base border-t border-black mt-12 w-[420px]'>
          <span className='font-bold text-nowrap mt-2 mx-3'>
            {evaluationReadOnlyAtom.get()?.student.name}
          </span>
        </div>
      </div>
    </div>
  )
}
