import { StressFormulaResult } from '~/modules/anamnesis/types'

import { Evaluation } from '../evalutation/types'

const physicalStressKeys: (keyof Evaluation)[] = [
  'lackingAppetite',
  'gastrointestinalDysfunction',
  'highRestingHeartRate',
  'muscularDamage',
]

const mentalStressKeys: (keyof Evaluation)[] = [
  'highBloodPressure',
  'lackingFocus',
  'insomnia',
  'lowerLibido',
  'irritability',
]

export function useStressFormula(evaluation?: Evaluation): StressFormulaResult {
  const result: StressFormulaResult = {
    message: 'Não existe evidência de stress',
    color: 'green',
  }

  if (!evaluation) return result

  let mentalStressScore = 0,
    physicalStressScore = 0

  for (const key of physicalStressKeys) {
    if (evaluation[key]) {
      physicalStressScore += 1
    }
  }

  for (const key of mentalStressKeys) {
    if (evaluation[key]) {
      mentalStressScore += 1
    }
  }

  if (mentalStressScore >= 2 && physicalStressScore >= 2) {
    result.message = 'Stress físico e mental alto'
  } else if (mentalStressScore >= 2) {
    result.message = 'Existe evidência de um possível stress mental'
  } else if (physicalStressScore >= 2) {
    result.message = 'Existe evidência de um possível stress físico'
  }

  if (mentalStressScore + physicalStressScore <= 3) result.color = 'green'
  else if (mentalStressScore + physicalStressScore <= 4) result.color = 'yellow'
  else if (mentalStressScore + physicalStressScore <= 5) result.color = 'orange'
  else if (mentalStressScore + physicalStressScore <= 7) result.color = 'red'
  else if (mentalStressScore + physicalStressScore <= 9) result.color = 'purple'

  return result
}
