import axios, { AxiosError } from 'axios'

import { api } from '~/utils/api'
import { storageAuthKey } from '~/utils/storageKeys'

import { PersonalTrainer } from '../admin/types'
import { authenticationAtom } from '../authentication/data'
import { insertToast } from '../toasts/services'
import { RegisterPersonalTrainerDTO, UpdatePersonalTrainerDTO } from './schema'

export async function updatePersonalTrainer(payload: UpdatePersonalTrainerDTO) {
  return api
    .patch<PersonalTrainer>('/personal-trainers', payload)
    .then(({ data }) => {
      const old = authenticationAtom.get()

      if (old) {
        const newValue = { ...old, user: data as any }
        localStorage.setItem(storageAuthKey, JSON.stringify(newValue))
        authenticationAtom.set(newValue)
        insertToast({
          duration: 3,
          message: 'Perfil atualizado',
          title: 'Sucesso!',
        })
      }

      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response?.data.message,
          title: 'Erro ao atualizar perfil',
        })
      }

      return false
    })
}

export async function registerPersonalTrainer(
  payload: RegisterPersonalTrainerDTO,
) {
  return axios
    .post<PersonalTrainer>('/personal-trainers/register', payload, {
      baseURL: import.meta.env.VITE_BASE_URL_API,
    })
    .then(({ data }) => {
      insertToast({
        title: 'Sucesso!',
        message: 'Seus dados foram cadastrados. Você pode acessar o GPA agora!',
        duration: 8,
        type: 'success',
      })

      return data
    })
    .catch((e: AxiosError<APIError>) => {
      if (e.response?.data.message) {
        insertToast({
          title: 'Erro ao Registrar',
          message: e.response?.data.message,
          duration: 3,
          type: 'error',
        })
      }
    })
}
