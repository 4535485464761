import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import zod from 'zod'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import { patchPersonalTrainerExercises } from '~/modules/personalTrainerExercises/services'

const schema = zod.object({
  newActivity: zod
    .string({ required_error: 'Obrigatório' })
    .nonempty({ message: 'Obrigatório' }),
})

type NewActivityDTO = zod.infer<typeof schema>

export const NewActivityForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewActivityDTO>({
    resolver: zodResolver(schema),
  })

  const onFormSubmit = useCallback(
    (payload: NewActivityDTO) => {
      setLoading(true)

      const activities = personalTrainerExercisesAtom.get()?.activities ?? []
      activities.push(payload.newActivity)

      patchPersonalTrainerExercises({
        activities,
      })
        .then((data) => {
          data && reset()
        })
        .finally(() => {
          setLoading(false)
        })
    },

    [reset],
  )

  return (
    <form className='flex gap-3' onSubmit={handleSubmit(onFormSubmit)}>
      <TextInput
        {...register('newActivity')}
        error={errors.newActivity?.message}
      />

      <Button
        disabled={loading}
        loading={loading}
        loadingMessage='Cadastrando...'
      >
        Adicionar atividade
      </Button>
    </form>
  )
}
