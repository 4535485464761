import { useCallback, useMemo } from 'react'

import { useAreobicCapabilities } from '../aerobicCapabilities/useAerobicCapabilities'
import { suggestRepetitionNumber } from '../evalutation/services'
import {
  Evaluation,
  PeriodizedSeries,
  PeriodizedSeriesExercise,
} from '../evalutation/types'

export function usePeriodizedSeries(evaluation: Evaluation | undefined) {
  const { score } = useAreobicCapabilities(evaluation)

  const numberOfSeries = useMemo(() => {
    if (score === 0 || score === 1) return 3
    if (score === 2 || score === 3) return 2
    return 1
  }, [score])

  const addExerciseToSeries = useCallback(
    (exercise: string, series: PeriodizedSeries) => {
      if (!series) return null

      if (series.exercises.some((e) => e.name === exercise)) {
        return null
      }

      series.exercises.push({
        adjustments: '',
        initialCharge: 0,
        interval: '0',
        maximumCharge: 0,
        name: exercise,
        numSeries: numberOfSeries,
        repetitions: suggestRepetitionNumber(exercise, series.intensity),
        speed: '0',
      })

      return evaluation?.periodizedSeries
    },
    [numberOfSeries, evaluation?.periodizedSeries],
  )

  const addStandardizedExercisesToSeries = useCallback(
    (
      series: PeriodizedSeries | undefined,
      standardizedExercises: string[] | undefined,
    ) => {
      if (!series || !standardizedExercises) return

      const exercises: PeriodizedSeriesExercise[] = []

      standardizedExercises?.forEach((e) => {
        exercises.push({
          adjustments: '',
          initialCharge: 0,
          interval: '0',
          maximumCharge: 0,
          name: e,
          numSeries: numberOfSeries,
          repetitions: suggestRepetitionNumber(e, series.intensity),
          speed: '0',
        })
      })

      series.exercises = exercises
    },
    [numberOfSeries],
  )

  return {
    addExerciseToSeries,
    addStandardizedExercisesToSeries,
  }
}
