import { Option } from '~/types/types'

export const targetZoneOptions: Option<number>[] = [
  {
    label: 'Frequência de Reserva',
    value: 1,
  },
  {
    label: 'Frequência Máxima',
    value: 0,
  },
]

export const objectiveOptions: Option<number>[] = [
  {
    label: 'Atividade Regenerativa',
    value: 0,
  },
  {
    label: 'Perda de Gordura',
    value: 1,
  },
  {
    label: 'Condicionamento Aeróbico',
    value: 2,
  },
  {
    label: 'Limiar Anaeróbico',
    value: 3,
  },
  {
    label: 'Esforço Máximo',
    value: 4,
  },
]

export const tableData = [
  ['', 6, 1],
  ['Muito, muito leve', 7, 1],
  ['', 8, 2],
  ['Muito leve', 9, 2],
  ['', 10, 3],
  ['Moderadamente leve', 11, 3],
  ['', 12, 4],
  ['Um pouco pesado', 13, 5],
  ['', 14, 6],
  ['Pesado', 15, 6],
  ['', 16, 7],
  ['Muito pesado', 17, 8],
  ['', 18, 9],
  ['Muito, muito pesado', 19, 10],
  ['', 20, 10],
]
