import { useMemo } from 'react'

import { corporalEvaluationImageAtom } from '~/modules/corporalEvaluation/data'
import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

import subscapularFemale from '/images/DF1.jpg'
import triceptalFemale from '/images/DF2.jpg'
import chestFemale from '/images/DF4.jpg'
import axillaFemale from '/images/DF5.jpg'
import suprailiacFemale from '/images/DF6.jpg'
import abdominalFemale from '/images/DF7.jpg'
import medialThightFemale from '/images/DF8.jpg'
import subscapularMale from '/images/DM1.jpg'
import triceptalMale from '/images/DM2.jpg'
import chestMale from '/images/DM4.jpg'
import axillaMale from '/images/DM5.jpg'
import suprailiacMale from '/images/DM6.jpg'
import abdominalMale from '/images/DM7.jpg'
import medialThightMale from '/images/DM8.jpg'
import Female from '/images/Feminino.jpg'
import Male from '/images/Masculino.jpg'
import rightFistFemale from '/images/PF17.jpg'
import abdomenFemale from '/images/PF5.jpg'
import rightFistMale from '/images/PM17.jpg'
import abdomenMale from '/images/PM5.jpg'

export const ImageGuide: React.FC = () => {
  const [imageIndex] = useAtom(corporalEvaluationImageAtom)
  const [evaluation] = useAtom(evaluationAtom)

  const image = useMemo(() => {
    if (evaluation?.student.sex === 'feminino') {
      switch (imageIndex) {
        case 1:
          return chestFemale
        case 2:
          return abdominalFemale
        case 3:
          return medialThightFemale
        case 4:
          return subscapularFemale
        case 5:
          return triceptalFemale
        case 6:
          return axillaFemale
        case 7:
          return suprailiacFemale
        case 8:
          return abdomenFemale
        case 9:
          return rightFistFemale
        case 0:
        default:
          return Female
      }
    }

    switch (imageIndex) {
      case 1:
        return chestMale
      case 2:
        return abdominalMale
      case 3:
        return medialThightMale
      case 4:
        return subscapularMale
      case 5:
        return triceptalMale
      case 6:
        return axillaMale
      case 7:
        return suprailiacMale
      case 8:
        return abdomenMale
      case 9:
        return rightFistMale
      case 0:
      default:
        return Male
    }
  }, [imageIndex, evaluation?.student.sex])

  return <img src={image} alt='' className='w-full' />
}
