import { AxiosError } from 'axios'

import { WeekDays } from '~/types/types'
import { api } from '~/utils/api'
import { swapItemsInArray } from '~/utils/array'
import { getWeekdayIndex } from '~/utils/weekdays'

import { evaluationAtom } from '../evalutation/data'
import { patchEvaluationAtom } from '../evalutation/services'
import { insertToast } from '../toasts/services'
import { personalTrainerExercisesAtom } from './data'
import { PersonalTrainerExercises, PersonalTrainerExercisesDTO } from './types'
import { defaultActivities } from './variables'

export async function getPersonalTrainerExercises(controller: AbortController) {
  return api
    .get<PersonalTrainerExercises>('/personal-trainers/exercises', {
      signal: controller.signal,
    })
    .then(({ data }) => {
      personalTrainerExercisesAtom.set(data)
      return data
    })
    .catch((e: AxiosError<APIError>) => {
      if (e.response?.data.message) {
        insertToast({
          duration: 3,
          message: e.response.data.message,
          title: 'Falha ao buscar exercícios do personal',
          type: 'error',
        })
      }
      return null
    })
}

export async function patchPersonalTrainerExercises(
  payload: PersonalTrainerExercisesDTO,
) {
  return api
    .patch<PersonalTrainerExercises>('/personal-trainers/exercises/', payload)
    .then(({ data }) => {
      personalTrainerExercisesAtom.set(data)
      return data
    })
    .catch((e: AxiosError<APIError>) => {
      if (e.response?.data.message) {
        insertToast({
          duration: 3,
          message: e.response.data.message,
          title: 'Falha ao tentar registrar',
          type: 'error',
        })
      }
      return null
    })
}

export function recoverDefaultActivities() {
  const oldData = personalTrainerExercisesAtom.get()
  if (oldData?.activities.length) {
    const newActivities = defaultActivities.filter(
      (item) => !oldData?.activities.includes(item),
    )
    return [...oldData.activities, ...newActivities]
  }
  return null
}

export function toggleActivityInWeek(day: WeekDays | number, activity: string) {
  const index = typeof day === 'number' ? day : getWeekdayIndex(day)
  const activityWeek = evaluationAtom.get()?.activityWeek
  if (!activityWeek) return

  const newActivities = [...activityWeek]

  if (newActivities[index]) {
    const activityIndex = newActivities[index]?.findIndex((a) => a === activity)
    if (activityIndex !== undefined && activityIndex >= 0) {
      newActivities[index]?.splice(activityIndex, 1)
      patchEvaluationAtom({ activityWeek: newActivities })
    } else {
      newActivities[index]?.push(activity)
      patchEvaluationAtom({ activityWeek: newActivities })
    }
  } else {
    newActivities[index] = [activity]
    patchEvaluationAtom({ activityWeek: newActivities })
  }
}

export function removeActivityFromWeekDay(index: number, dayIndex: number) {
  const activityWeek = evaluationAtom.get()?.activityWeek
  if (!activityWeek || !activityWeek[dayIndex]) return
  const newArr = [...activityWeek]
  newArr[dayIndex]?.splice(index, 1)
  patchEvaluationAtom({ activityWeek: newArr })
}

export function moveActivityInWeekDay(
  direction: 'up' | 'down',
  index: number,
  dayIndex: number,
) {
  const activityWeek = evaluationAtom.get()?.activityWeek
  if (!activityWeek) return

  if (
    (direction === 'up' && index === 0) ||
    (direction === 'down' && activityWeek[dayIndex]?.length === index + 1)
  ) {
    return
  }

  const newArr = [...activityWeek]
  direction === 'up'
    ? swapItemsInArray(newArr[dayIndex], index, index - 1)
    : swapItemsInArray(newArr[dayIndex], index, index + 1)
  patchEvaluationAtom({ activityWeek: newArr })
}

export function addStrengthExerciseToGroup(
  exercise: string,
  exerciseGroup: string,
) {
  const strengthExercises =
    personalTrainerExercisesAtom.get()?.strengthExercises
  if (!strengthExercises) return false

  const index = strengthExercises.findIndex(
    (e) => e.groupName === exerciseGroup,
  )
  if (index < 0) return null

  if (strengthExercises[index].exercises.some((e) => e === exercise)) {
    return null
  }

  strengthExercises[index].exercises.push(exercise)

  return strengthExercises
}

export function removeStrengthExerciseFromSeriesGroup(
  exercise: string,
  group: string,
) {
  const strengthExercises =
    personalTrainerExercisesAtom.get()?.strengthExercises
  if (!strengthExercises) return null

  const groupIndex = strengthExercises.findIndex((e) => e.groupName === group)

  if (groupIndex < 0) return null

  strengthExercises[groupIndex].exercises = strengthExercises[
    groupIndex
  ].exercises.filter((e) => e !== exercise)

  return strengthExercises
}

export function restoreStrengthExerciseToGroup(group: string) {
  const strengthExercises =
    personalTrainerExercisesAtom.get()?.strengthExercises
  if (!strengthExercises) return null

  const groupIndex = strengthExercises.findIndex((e) => e.groupName === group)
  if (groupIndex < 0) return null

  const exercisesList = strengthExercises[groupIndex].exercises

  let missingExercises: string[] = []

  switch (group) {
    case 'Abdomen':
      missingExercises = [
        'Crunch com rotação',
        'Flexão lateral tronco',
        'Abd. infra-umbilical',
        'Crunch banco/polia',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Panturrilha':
      missingExercises = [
        'Panturrilha em pé',
        'Panturrilha sentado',
        'Panturrilha no Leg-press',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Coxas e gluteos':
      missingExercises = [
        'Meio Agachamento',
        'Agachamento',
        'Agachamento no Hack',
        'Leg-press horizontal',
        'Leg-press vertical',
        'Extensão de pernas',
        'Flexão de pernas',
        'Afundo',
        'Cadeira adutora',
        'Cadeira abdutora',
        'Levantamento Terra',
        'Mesa para Glúteos',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Peito':
      missingExercises = [
        'Supino reto',
        'Supino inclinado',
        'Supino decli/canad.',
        'Crucifixo reto',
        'Crucifixo inclinado',
        'Crucifixo declinado',
        'Pec deck (voador)',
        'Crossover',
        'Pullover',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Costas':
      missingExercises = [
        'Barra fixa',
        'Pulley alto post.',
        'Pulley alto anterior',
        'Remada sentado',
        'Remada unilateral',
        'Remada curvada',
        'Hiperextensão lombar',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Ombro':
      missingExercises = [
        'Desenvolv. p/ frente',
        'Desenvolv. p/ trás',
        'Desenvolv. halteres',
        'Elevação lateral',
        'Elevação frontal',
        'Remada alta',
        'Encolhimento ombros',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Biceps':
      missingExercises = [
        'Rosca direta(barra)',
        'Rosca simult.(halt.)',
        'Rosca concentrada',
        'Rosca Scott',
        'Rosca inversa',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Triceps':
      missingExercises = [
        'Tríceps pulley alto',
        'Tríceps na testa',
        'Tríceps coice(Kickb.)',
        'Supino fechado',
        'Tríceps francês',
        'Tríceps unilateral',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Antebraço':
      missingExercises = [
        'Rosca punho',
        'Rosca punho inversa',
        'Adução/Abdução punho',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Outros':
    default:
      break
  }

  strengthExercises[groupIndex].exercises = [
    ...exercisesList,
    ...missingExercises,
  ]

  return strengthExercises
}

export function createStandardizedSeriesGroup(groupName: string) {
  const standardizedSeries =
    personalTrainerExercisesAtom.get()?.standardizedSeries
  if (!standardizedSeries) return null

  const hasGroup = standardizedSeries.some((g) => g.groupName === groupName)
  if (hasGroup) return null

  standardizedSeries.push({
    groupName,
    exercises: [],
  })

  return standardizedSeries
}

export function addExerciseToStandardizedSeriesGroup(
  exercise: string,
  exerciseGroup: string,
) {
  const standardizedSeries =
    personalTrainerExercisesAtom.get()?.standardizedSeries
  if (!standardizedSeries) return false

  const index = standardizedSeries.findIndex(
    (e) => e.groupName === exerciseGroup,
  )
  if (index < 0) return null

  if (standardizedSeries[index].exercises.some((e) => e === exercise)) {
    return null
  }

  standardizedSeries[index].exercises.push(exercise)

  return standardizedSeries
}

export function removeExerciseFromStandardizedSeriesGroup(
  exercise: string,
  group: string,
) {
  const standardizedSeries =
    personalTrainerExercisesAtom.get()?.standardizedSeries
  if (!standardizedSeries) return null

  const groupIndex = standardizedSeries.findIndex((e) => e.groupName === group)

  if (groupIndex < 0) return null

  standardizedSeries[groupIndex].exercises = standardizedSeries[
    groupIndex
  ].exercises.filter((e) => e !== exercise)

  return standardizedSeries
}

export function removeStandardizedSeries(group: string) {
  const standardizedSeries =
    personalTrainerExercisesAtom.get()?.standardizedSeries
  if (!standardizedSeries) return null

  return standardizedSeries.filter((se) => se.groupName !== group)
}
