import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { dateMask } from '~/components/TextInput/utils/mask'
import { authenticationAtom } from '~/modules/authentication/data'
import {
  CreateInvitationLinkDTO,
  createInvitationLinkSchema,
} from '~/modules/invitationLinks/schemas'
import { createInvitationLink } from '~/modules/invitationLinks/services'
import { InvitationLink } from '~/modules/invitationLinks/types'
import { useAtom } from '~/observers/jotai'

type Props = {
  onSubmit: (payload: InvitationLink) => void
}

export const InvitationLinkForm: React.FC<Props> = ({ onSubmit }) => {
  const [authData] = useAtom(authenticationAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<CreateInvitationLinkDTO>({
    defaultValues: {
      createdBy: authData?.user.id,
    },
    resolver: zodResolver(createInvitationLinkSchema),
  })

  const handleFormSubmit = useCallback(
    async (payload: CreateInvitationLinkDTO) => {
      setLoading(true)
      createInvitationLink(payload).then((data) => {
        if (data) {
          onSubmit(data)
        }
        setLoading(false)
      })
    },
    [onSubmit],
  )

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className='flex flex-col gap-2'
    >
      <TextInput
        label='Nome'
        {...register('name')}
        error={errors.name?.message}
      />
      <TextInput
        label='Quantidade Máxima de Cadastros'
        {...register('registrationsLimit', { valueAsNumber: true })}
        error={errors.registrationsLimit?.message}
      />
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-4'>
        <Controller
          control={control}
          name='dueDate'
          defaultValue=''
          render={({ field }) => (
            <TextInput
              label='Validade'
              value={field.value}
              onChange={(e) => field.onChange(dateMask(e.target.value))}
              error={errors.dueDate?.message}
            />
          )}
        />
        <TextInput
          label='Quantidade de Licenças'
          {...register('licensesAmount', { valueAsNumber: true })}
          error={errors.licensesAmount?.message}
        />
      </div>

      <Button disabled={loading}>Cadastrar</Button>
    </form>
  )
}
