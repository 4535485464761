import { useMemo } from 'react'

import { computeAgeService } from '~/services/computeAgeService'

import { Evaluation } from '../evalutation/types'

export function useCoronarianRiskFactorFormula(evaluation?: Evaluation) {
  const coronarianRiskFactorResult = useMemo(() => {
    let totalScore = 0
    const age = computeAgeService(evaluation?.student.birthday)

    let arterialPressureScore = 'Ótima'

    // classifies after calculating arterial pressure
    const pressureClassification = [
      'ÓTIMA',
      'ÓTIMA',
      'NORMAL',
      'NORMAL LIMÍTROFE',
      'HIPERTENSÃO LEVE',
      'HIPERTENSÃO MODERADA',
      'HIPERTENSÃO GRAVE',
    ]

    const pressureCalculation = (
      systolic: number,
      diastolic: number,
    ): number[] => {
      if (diastolic === 0 || systolic === 0) {
        return [0, 0]
      }

      const returnPressuresCalculation: number[] = []

      if (systolic <= 119) {
        returnPressuresCalculation[0] = 1
      } else if (systolic <= 129) {
        returnPressuresCalculation[0] = 2
      } else if (systolic <= 139) {
        returnPressuresCalculation[0] = 3
      } else if (systolic <= 149) {
        returnPressuresCalculation[0] = 4
      } else if (systolic <= 159) {
        returnPressuresCalculation[0] = 5
      } else {
        returnPressuresCalculation[0] = 6
      }

      if (diastolic <= 79) {
        returnPressuresCalculation[1] = 1
      } else if (diastolic <= 84) {
        returnPressuresCalculation[1] = 2
      } else if (diastolic <= 89) {
        returnPressuresCalculation[1] = 3
      } else if (diastolic <= 99) {
        returnPressuresCalculation[1] = 4
      } else if (diastolic <= 109) {
        returnPressuresCalculation[1] = 5
      } else {
        returnPressuresCalculation[1] = 6
      }

      return returnPressuresCalculation
    }

    switch (evaluation?.dailyPhysicalActivity) {
      case 'Ausência completa de qualquer exercício':
        totalScore += 8
        break
      case 'Esforço ocupacional e recreacional intenso':
        totalScore += 1
        break
      case 'Esforço ocupacional e recreacional moderado':
        totalScore += 2
        break
      case 'Trabalho sedentário e esforço recreacional intenso':
        totalScore += 3
        break
      case 'Trabalho sedentário e esforço recreacional leve':
        totalScore += 6
        break
      case 'Trabalho sedentário e esforço recreacional moderado':
        totalScore += 5
        break
      default:
        break
    }

    switch (evaluation?.weight) {
      case '-2,3kg a + 2,3kg do peso padrão':
        totalScore += 1
        break
      case 'excesso de peso padrão de 16,2kg até 22,6kg':
        totalScore += 5
        break
      case 'excesso de peso padrão de 2,7kg até 9kg':
        totalScore += 2
        break
      case 'excesso de peso padrão de 23,0kg até 29,5kg':
        totalScore += 7
        break
      case 'excesso de peso padrão de 9,1kg até 15,8kg':
        totalScore += 3
        break
      case '+ 2,3kg abaixo do peso padrão':
      default:
        break
    }

    switch (evaluation?.sexAndAge) {
      case 'Homem':
        totalScore += 5
        break
      case 'Homem careca e endomesomórfico':
        totalScore += 7
        break
      case 'Homem endomesomórfico':
        totalScore += 6
        break
      case 'Mulher abaixo de 40 anos':
        totalScore += 1
        break
      case 'Mulher com mais de 50 anos':
        totalScore += 3
        break
      case 'Mulher de 40 a 50 anos':
        totalScore += 2
        break
      default:
        break
    }

    if (age <= 20) {
      totalScore += 1
    } else if (age <= 30) {
      totalScore += 2
    } else if (age <= 40) {
      totalScore += 3
    } else if (age <= 50) {
      totalScore += 4
    } else if (age <= 60) {
      totalScore += 6
    } else {
      totalScore += 8
    }

    switch (evaluation?.smoke) {
      case '10 cigarros ou menos por dia':
        totalScore += 2
        break
      case '20 cigarros por dia':
        totalScore += 4
        break
      case '30 cigarros por dia':
        totalScore += 6
        break
      case '40 cigarros ou mais por dia':
        totalScore += 10
        break
      case 'Charuto e/ou cachimbo':
        totalScore += 1
        break
      case 'Não fuma':
      default:
        break
    }

    // pressure calculation
    const idxPressureCalculation = pressureCalculation(
      evaluation?.systolicArterialPressure ?? 0,
      evaluation?.diastolicArterialPressure ?? 0,
    )

    // describes the level of pressure (ótima, normal,...) checking which is the biggest
    arterialPressureScore =
      idxPressureCalculation[0] > idxPressureCalculation[1]
        ? pressureClassification[idxPressureCalculation[0]]
        : pressureClassification[idxPressureCalculation[1]]

    // scores the value of the highest pressure, systolic or diastolic
    totalScore +=
      idxPressureCalculation[0] > idxPressureCalculation[1]
        ? idxPressureCalculation[0]
        : idxPressureCalculation[1]

    switch (evaluation?.hereditarianism) {
      case 'Nenhuma história conhecida de cardiopatia':
        totalScore += 1
        break
      case 'Um parente com doença cardiovascular, acima de 60 anos':
        totalScore += 2
        break
      case '2 parentes com + de 60 anos com doença cardiovascular':
        totalScore += 3
        break
      case '1 parente com - de 60 anos com doença cardiovascular':
        totalScore += 4
        break
      case '2 parentes com doença cardiovascular, abaixo de 60 anos':
        totalScore += 6
        break
      case '3 parentes com doença cardiovascular, abaixo de 60 anos':
        totalScore += 8
        break
      default:
        break
    }

    switch (evaluation?.cholesterol) {
      case 'Colesterol de 180mg ou dieta sem gordura animal':
        totalScore += 1
        break
      case 'Colesterol de 181mg a 205mg ou dieta com 10% gordura animal':
        totalScore += 2
        break
      case 'Colesterol de 206mg a 230mg ou dieta com 20% gordura animal':
        totalScore += 3
        break
      case 'Colesterol de 231mg a 255mg ou dieta com 30% gordura animal':
        totalScore += 4
        break
      case 'Colesterol de 256mg a 280mg ou dieta com 40% gordura animal':
        totalScore += 5
        break
      case 'Colesterol de 280mg a 330mg ou dieta com 50% gordura animal':
        totalScore += 7
        break
      default:
        break
    }

    // coronary risk details
    if (totalScore >= 6 && totalScore <= 11) {
      return {
        totalScore,
        arterialPressureScore,
        color: 'green',
        message:
          'Risco bem abaixo da média para desenvolver doenças coronarianas',
      }
    } else if (totalScore >= 12 && totalScore <= 17)
      return {
        totalScore,
        arterialPressureScore,
        color: 'lime',
        message: 'Risco abaixo da média para desenvolver doenças coronarianas',
      }
    else if (totalScore >= 18 && totalScore <= 24)
      return {
        totalScore,
        arterialPressureScore,
        color: 'yellow',
        message: 'Risco na média geral para desenvolver doenças coronarianas',
      }
    else if (totalScore >= 25 && totalScore <= 31)
      return {
        totalScore,
        arterialPressureScore,
        color: 'amber',
        message: 'Risco moderado para desenvolver doenças coronarianas',
      }
    else if (totalScore >= 32 && totalScore <= 40)
      return {
        totalScore,
        arterialPressureScore,
        color: 'red',
        message:
          'Risco em nível perigoso para desenvolver doenças coronarianas',
      }
    else if (totalScore >= 41)
      return {
        totalScore,
        arterialPressureScore,
        color: 'purple',
        message:
          'Risco avançado para desenvolver doenças coronarianas - procurar urgentemente um cardiologista',
      }
    else
      return {
        totalScore,
        arterialPressureScore,
        color: 'none',
        message: 'Sem dados suficientes. Continue respondendo o questionário',
      }
  }, [evaluation])

  return { coronarianRiskFactorResult }
}
