import { useCallback, useEffect, useState } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { setCorporalEvaluationImageIndex } from '~/modules/corporalEvaluation/services'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluationAtom } from '~/modules/evalutation/services'

export const CoteWilmore: React.FC = () => {
  const [value, setValue] = useState<{
    abdomen: number | undefined
    rightFist: number | undefined
  }>({
    abdomen: evaluationAtom.get()?.abdomen,
    rightFist:
      evaluationAtom.get()?.rightFist || evaluationAtom.get()?.rightFist,
  })

  useEffect(() => {
    patchEvaluationAtom({
      abdomen: value.abdomen,
      rightFist: value.rightFist,
    })
  }, [value])

  const handleAbdomenFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(8)
  }, [])

  const handleRightFistFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(9)
  }, [])

  return (
    <div className='flex gap-3'>
      <MaskedInput
        label='Abdômen (cm)'
        mask='float'
        placeholder='0,0'
        value={value?.abdomen}
        onFocus={handleAbdomenFocus}
        onChange={(v) =>
          setValue((state) => ({
            ...state,
            abdomen: +v,
          }))
        }
      />
      <MaskedInput
        key={'rightFist-input'}
        label='Punho Direito (cm)'
        mask='float'
        value={value?.rightFist}
        placeholder='0,0'
        onFocus={handleRightFistFocus}
        onChange={(v) =>
          setValue((state) => ({
            ...state,
            rightFist: +v,
          }))
        }
      />
    </div>
  )
}
