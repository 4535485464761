import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'

import { Modal } from '~/components/Modal'
import { evaluationAtom } from '~/modules/evalutation/data'
import { openEvaluation } from '~/modules/evalutation/services'
import { useAtom } from '~/observers/jotai'
import { EvaluationRouteParams } from '~/routes/types'

import { AerobicCapabilitiesForm } from './components/formsContainer/components/aerobicCapabitiliesForm'
import { AerobicExercises } from './components/formsContainer/components/aerobicExercises'
import { AerobicPrescription } from './components/formsContainer/components/aerobicPrescription'
import { AnamnesisForm } from './components/formsContainer/components/anamnesisForm'
import { AntropometryForm } from './components/formsContainer/components/antropometryForm'
import { CorporalEvaluationForm } from './components/formsContainer/components/corporalEvaluationForm'
import { ExercisesPrescription } from './components/formsContainer/components/exercisesPrescription'
import { HemodynamicMeasuresForm } from './components/formsContainer/components/hemodynamicMeasuresForm'
import { Macrocycles } from './components/formsContainer/components/macrocycles'
import { Scheduler } from './components/formsContainer/components/scheduler'
import { SeriesEdit } from './components/formsContainer/components/SeriesEdit'
import { FormsLayout } from './layout'

export const StudentEvaluation: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()

  useEffect(() => {
    if (!student_id || !evaluation_id) return

    const abortController = new AbortController()

    openEvaluation(student_id, evaluation_id, abortController)

    return () => {
      abortController.abort()
    }
  }, [student_id, evaluation_id])

  return !evaluation?.id ? (
    <Modal visible>
      <div className='flex flex-col flex-1 gap-4'>
        <p className='text-center'>Carregando dados da avaliação...</p>
        <div className='h-8 w-8 border-4 border-x-slate-200 border-y-slate-200 border-t-slate-400 rounded-full mx-auto animate-spin' />
      </div>
    </Modal>
  ) : (
    <Routes>
      <Route
        path='/'
        element={
          <FormsLayout>
            <Outlet />
          </FormsLayout>
        }
      >
        <Route path='anamnese' element={<AnamnesisForm />} />
        <Route path='antropometria' element={<AntropometryForm />} />
        <Route path='avaliacao_corporal' element={<CorporalEvaluationForm />} />
        <Route
          path='medidas_hemodinamicas'
          element={<HemodynamicMeasuresForm />}
        />
        <Route
          path='capacidade_aerobica'
          element={<AerobicCapabilitiesForm />}
        />
        <Route path='prescricao_aerobica' element={<AerobicPrescription />} />
        <Route path='agendamento' element={<Scheduler />} />
        <Route path='exercicios_aerobicos' element={<AerobicExercises />} />
        <Route
          path='prescricao_de_exercicios'
          element={<ExercisesPrescription />}
        />
        <Route path='personalizacao_da_serie' element={<SeriesEdit />} />

        <Route path='macrociclos' element={<Macrocycles />} />

        <Route path='/' element={<Navigate to={'anamnese'} replace />} />
      </Route>
    </Routes>
  )
}
