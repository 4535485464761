import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

import { ChartAnthropometricMeasurements } from './ChartAnthropometricMeasurements'
import { Header } from './Header'

export const AntropometricMeasures: React.FC = () => {
  const [data] = useAtom(evaluationReadOnlyAtom)
  return (
    <div className='flex flex-col px-8  py-7 w-full page-break'>
      <Header title='Medidas Antropométricas' />

      {/* Section 1 */}
      <div className='flex justify-center border border-black bg-zinc-300 my-4'>
        <span className='font-bold text-sm'>Perímetros</span>
      </div>

      <div className='grid grid-cols-printPerimetros'>
        <div>
          <div className='flex justify-between mb-1'>
            <span className='font-bold'>Perímetro</span>
            <span className='font-bold'>Valor</span>
          </div>
          <div>
            <div className='flex justify-between'>
              <span>1 - Ombro (cm)</span>
              <span>{data?.shoulder}</span>
            </div>
            <div className='flex justify-between'>
              <span>2 - Peitoral Inspirado (cm) </span>
              <span>{data?.chestInflated}</span>
            </div>
            <div className='flex justify-between'>
              <span>3 - Peitoral Normal (cm) </span>
              <span>{data?.chestRegular}</span>
            </div>
            <div className='flex justify-between'>
              <span>4 - Quadril (cm) </span>
              <span>{data?.hip}</span>
            </div>
            <div className='flex justify-between'>
              <span>5 - Abdômen (cm)</span>
              <span>{data?.abdomen}</span>
            </div>
            <div className='flex justify-between'>
              <span>6 - Cintura (cm) </span>
              <span>{data?.waist}</span>
            </div>
            <div className='flex justify-between'>
              <span>7 - Coxa Proximal Dir. (cm) </span>
              <span>{data?.rightThigh}</span>
            </div>
            <div className='flex justify-between'>
              <span>8 - Coxa Proximal Esq. (cm) </span>
              <span>{data?.leftThigh}</span>
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <div className='flex justify-between mb-1'>
            <span className='font-bold'>Perimetro</span>
            <span className='font-bold'>Valor</span>
          </div>
          <div>
            <div className='flex justify-between'>
              <span>9 - Perna Direita (cm) </span>
              <span>{data?.rightLeg}</span>
            </div>
            <div className='flex justify-between'>
              <span>10 - Perna Esquerda (cm) </span>
              <span>{data?.leftLeg}</span>
            </div>
            <div className='flex justify-between'>
              <span>11 - Braço Dir. contraído (cm) </span>
              <span>{data?.rightArmContracted}</span>
            </div>
            <div className='flex justify-between'>
              <span>12 - Braço esq. contraído (cm) </span>
              <span>{data?.leftArmContracted}</span>
            </div>
            <div className='flex justify-between'>
              <span>13 - Braço Dir. relaxado (cm)</span>
              <span>{data?.rightArmRelaxed}</span>
            </div>
            <div className='flex justify-between'>
              <span>14 - Braço esq. relaxado (cm) </span>
              <span>{data?.leftArmRelaxed}</span>
            </div>
            <div className='flex justify-between'>
              <span>15 - Antebraço Direito (cm) </span>
              <span>{data?.rightForearm}</span>
            </div>
            <div className='flex justify-between'>
              <span>16 - Antebraço Esquerdo (cm) </span>
              <span>{data?.leftForearm}</span>
            </div>
          </div>
        </div>
        <div></div>
      </div>

      {/* Section Gráfico */}
      <div className='flex justify-center border border-black bg-zinc-300 my-4'>
        <span className='font-bold text-sm'>Gráfico</span>
      </div>
      <ChartAnthropometricMeasurements data={data} />
    </div>
  )
}
