import { useEffect, useState } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { MargariaProtocol } from '~/modules/aerobicCapabilities/schema'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluationAtom } from '~/modules/evalutation/services'

export const Margaria: React.FC = () => {
  const data = evaluationAtom.get()
  const [value, setValue] = useState<MargariaProtocol>({
    distance: data?.margaria?.distance ?? 0,
    time: data?.margaria?.time ?? 0,
  })

  useEffect(() => {
    patchEvaluationAtom({ margaria: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <MaskedInput
        mask='float'
        inputMode='numeric'
        label='Tempo (min)'
        placeholder='0'
        value={value?.time}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            time: e,
          }))
        }
      />
      <MaskedInput
        mask='float'
        inputMode='numeric'
        label='Distância (m)'
        placeholder='0'
        value={value?.distance}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            distance: e,
          }))
        }
      />
    </div>
  )
}
