import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import zod from 'zod'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import {
  createStandardizedSeriesGroup,
  patchPersonalTrainerExercises,
} from '~/modules/personalTrainerExercises/services'
import { insertToast } from '~/modules/toasts/services'

const schema = zod.object({
  groupName: zod
    .string()
    .trim()
    .min(3, 'Deve ter ao menos 3 caracteres')
    .max(30, 'Deve ter no máximo 30 caracteres'),
})

type FormType = zod.infer<typeof schema>

type Props = {
  onSubmit: () => void
}

export const CreateGroupForm: React.FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<FormType>({ resolver: zodResolver(schema) })

  const onFormSubmit = useCallback(
    async (payload: FormType) => {
      const standardizedSeries = createStandardizedSeriesGroup(
        payload.groupName,
      )

      if (standardizedSeries) {
        await patchPersonalTrainerExercises({ standardizedSeries }).then(
          (data) => {
            data && resetField('groupName')
            onSubmit && onSubmit()
          },
        )
      } else {
        insertToast({
          duration: 3,
          message: 'Este nome já está sendo usado para um grupo',
          title: 'Atenção!',
          type: 'warn',
        })
      }
    },
    [resetField, onSubmit],
  )

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='flex flex-wrap gap-3'>
        <TextInput
          {...register('groupName')}
          placeholder='Nome da nova série padronizada'
          error={errors.groupName?.message}
        />
        <Button disabled={isSubmitting} loading={isSubmitting}>
          Criar Série Padronizada
        </Button>
      </div>
    </form>
  )
}
