export function validateCPF(cpf: string) {
  if (cpf.replaceAll(/\D/g, '').length !== 11) return false

  if (/^([0-9])\1{10}$/.test(cpf)) return false

  const getNextVerificationDigit = (stringSlice: string) => {
    let sum = 0
    for (let i = 0; i < stringSlice.length; i++) {
      const digit = +stringSlice[i]
      const constant = stringSlice.length + 1 - i
      sum += digit * constant
    }
    const remainder = sum % 11
    return (remainder < 2 ? 0 : 11 - remainder) + ''
  }

  const firstValidDigit = getNextVerificationDigit(cpf.slice(0, 9))
  const secondValidDigit = getNextVerificationDigit(
    cpf.slice(0, 9) + firstValidDigit,
  )

  return cpf === cpf.slice(0, 9) + firstValidDigit + secondValidDigit
}
