import { useCallback } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { setCorporalEvaluationImageIndex } from '~/modules/corporalEvaluation/services'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluationAtom } from '~/modules/evalutation/services'
import { useAtom } from '~/observers/jotai'

export const Direto: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const data = evaluationAtom.get()

  const handleFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(0)
  }, [])

  console.log(evaluation?.direct)

  return (
    <div>
      <MaskedInput
        value={data?.direct || 0}
        mode='horizontal'
        label='Percentual de Gordura'
        placeholder='0,0'
        mask='float'
        inputMode='numeric'
        onFocus={handleFocus}
        onChange={(v) => {
          patchEvaluationAtom({
            direct: v,
          })
        }}
      />
    </div>
  )
}
