export function swapItemsInArray(
  array: Array<any> | null,
  indexA: number,
  indexB: number,
) {
  if (array === null) return

  const copy = array[indexB]
  array[indexB] = array[indexA]
  array[indexA] = copy
}

export function moveItemsInArray(
  targetIndex: number,
  array: any[],
  direction: 'up' | 'down',
) {
  if (!array || !array.length) return
  if (
    (targetIndex === 0 && direction === 'up') ||
    (targetIndex === array.length - 1 && direction === 'down')
  )
    return
  direction === 'up'
    ? swapItemsInArray(array, targetIndex, targetIndex - 1)
    : swapItemsInArray(array, targetIndex, targetIndex + 1)
}
