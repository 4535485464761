import { useCallback, useEffect, useState } from 'react'

import { PageNavigation } from '~/components/PageNavigation'
import { listInvitationLinks } from '~/modules/invitationLinks/services'
import {
  InvitationLink,
  ListInvitationLinksDTO,
} from '~/modules/invitationLinks/types'

import { invitationLinksContext } from './context'
import { Header } from './Header'
import { ToggleLinkButton } from './ToggleLinkButton'

export function InvitationLinks() {
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [invitationLinks, setInvitationLinks] =
    useState<ListInvitationLinksDTO>()

  useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)
    listInvitationLinks({ page }, abortController)
      .then((data) => {
        data && setInvitationLinks(data)
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [page])

  const handleAddNewLink = useCallback((link: InvitationLink) => {
    setInvitationLinks((state) => {
      if (state) {
        return {
          meta: { ...state.meta },
          invitationLinks: [link, ...state.invitationLinks],
        }
      }
    })
  }, [])

  return (
    <invitationLinksContext.Provider
      value={{ data: invitationLinks, addNewInvitationLink: handleAddNewLink }}
    >
      <main className='flex flex-1 flex-col bg-slate-100'>
        <Header />
        <div className='flex flex-1 p-5'>
          <div className='flex flex-col flex-1 items-end justify-between gap-6 bg-white overflow-hidden p-3 rounded-xl'>
            {invitationLinks?.invitationLinks.length ? (
              <>
                <div className='w-full overflow-x-auto'>
                  <table className='bg-white w-full min-w-[800px] table-auto border-separate border-spacing-y-3'>
                    <thead>
                      <tr>
                        <th className='text-center'>Nome</th>
                        <th className='text-center'>
                          Qtd. Máxima de Cadastros
                        </th>
                        <th className='text-center'>Validade</th>
                        <th className='text-center'>Criado por</th>
                        <th className='text-center'>Quantidade de Licenças</th>
                        <th className='text-center'>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invitationLinks?.invitationLinks
                        .map((il) => (
                          <tr
                            key={il.id}
                            className='odd:bg-slate-200 bg-white my-2'
                          >
                            <td className='px-2 text-center'>{il.name}</td>
                            <td className='px-2 text-center'>
                              {il.registrationsLimit}
                            </td>
                            <td className='px-2 text-center'>
                              {new Date(il.dueDate).toLocaleDateString()}
                            </td>
                            <td className='px-2 text-center'>
                              {il.admin.name}
                            </td>
                            <td className='px-2 text-center'>
                              {il.licensesAmount}
                            </td>
                            <td className='px-2 text-center'>
                              <ToggleLinkButton id={il.id} active={il.active} />
                            </td>
                          </tr>
                        ))
                        .slice(0, 10)}
                    </tbody>
                  </table>
                </div>
                <PageNavigation
                  currentPage={page}
                  numPages={invitationLinks.meta.totalPages}
                  onPageChange={setPage}
                />
              </>
            ) : (
              <p className='mx-auto'>
                {loading ? 'Listando...' : 'Nenhum Registro Ainda'}
              </p>
            )}
          </div>
        </div>
      </main>
    </invitationLinksContext.Provider>
  )
}
