import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

import { AbdominalInput } from './components/AbdominalInput'
import { ChestInput } from './components/ChestInput'
import { MedialThighInput } from './components/MedialThighInput'
import { SuprailiacInput } from './components/SuprailiacInput'
import { TriceptalInput } from './components/TriceptalInput'

export const PollockJacksonTrifold: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)

  return (
    <div className='grid flex-1'>
      <div className='grid grid-rows-3 xl:grid-rows-4 gap-5 self-start'>
        <div className='hidden xl:grid grid-cols-5 gap-3 items-end'>
          <div></div>
          <div className='font-bold'>Medida 1</div>
          <div className='font-bold'>Medida 2</div>
          <div className='font-bold'>Medida 3</div>
          <div className='font-bold'>Médida</div>
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          {evaluation?.student.sex === 'masculino' ? (
            <ChestInput />
          ) : (
            <TriceptalInput />
          )}
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          {evaluation?.student.sex === 'masculino' ? (
            <AbdominalInput />
          ) : (
            <SuprailiacInput />
          )}
        </div>
        <div className='grid grid-cols-1 xl:grid-cols-5 gap-3 items-center self-start'>
          <MedialThighInput />
        </div>
      </div>
    </div>
  )
}
