import { ApexOptions } from 'apexcharts'
import ApexChart from 'react-apexcharts'

import { useIMCFormula } from '~/modules/antropometry/useIMCData'
import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

export const ChartIRCQ: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const { calcIRCQ } = useIMCFormula(evaluation)

  const categories = ['Baixo Risco', 'Alto Risco']

  const series: ApexOptions['series'] = [
    {
      name: 'Obtido',
      // data: [1.14],
      data: [calcIRCQ().value || 0],
      type: 'bar',
    },
    {
      name: 'Alto Risco',
      // data: [0.85],
      data: [0.85, 0.85],
      type: 'line',
      color: '#008FFB',
    },
    {
      name: 'Baixo Risco',
      // data: [0.7],
      data: [0.7, 0.7],
      type: 'line',
      color: '#00E396',
    },
  ]

  const chartOptions: ApexOptions = {
    chart: {
      id: 'ircq-chart',
    },
    legend: {
      show: true,
      position: 'right',
    },
    stroke: {
      width: [0, 2, 5],
      curve: 'smooth',
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },

    //   yaxis: [{
    //     title: {
    //       text: 'baixo risco',
    //     },
    //   },
    // {
    //   opposite: true,
    //   title: {
    //     text: 'alto risco'
    //   }
    // }],

    colors: ['#e90c59'],

    plotOptions: {
      bar: {
        columnWidth: '85%',
        distributed: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
  }

  return (
    <div className='w-full max-w-[700px]'>
      <ApexChart
        type='bar'
        height={180}
        width={720}
        series={series}
        options={chartOptions}
      />
    </div>
  )
}
