import {
  ChangeEvent,
  SelectHTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { Option } from '~/types/types'

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Option<any>[]
  error?: string
  label?: string
  onChange: (v: any) => void
}

export const SelectInput: React.FC<Props> = ({
  options,
  label,
  error,
  onChange,
  ...props
}) => {
  const [hasValue, setHasValue] = useState<boolean>(() =>
    props.value || props.value === 0 ? true : false,
  )

  const defaultValue = useMemo(() => {
    const index = options.findIndex((o) => o.value === props.value)
    return index >= 0 ? index : ''
  }, [options, props.value])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const index = +e.target.value
      setHasValue(true)
      onChange && onChange(options[index].value)
    },
    [onChange, options],
  )

  return (
    <div className='flex flex-col gap-3 w-full'>
      {label ? <span>{label}</span> : null}
      <label
        data-error={!!error}
        className='flex w-full h-10 px-2 rounded-md border-2 focus-within:border-gpa-blue-500 data-[error=true]:border-gpa-red'
      >
        <select
          data-filled={hasValue}
          defaultValue={defaultValue}
          className='flex-1 bg-transparent outline-none w-full text-slate-500 text-opacity-55 data-[filled=true]:text-black focus:text-black'
          onChange={handleChange}
        >
          <option value='' disabled hidden>
            {props.placeholder}
          </option>
          {options?.map((o, i) => (
            <option key={`${props.name}-${i}`} value={i}>
              {o.label}
            </option>
          ))}
        </select>
      </label>
      <span className='text-xs text-gpa-red'>{error}</span>
    </div>
  )
}
