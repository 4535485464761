import { forwardRef } from 'react'

import { IRadioButtonProps } from './IRadioButtonProps'

const Component: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IRadioButtonProps
> = ({ label, hasError, ...props }, ref) => {
  return (
    <label className='flex gap-[6px] cursor-pointer'>
      <div
        data-error={hasError}
        className='relative shrink-0 data-[error=true]:border-red-500 flex w-[25px] h-[25px] border focus-within:border-2 border-gpa-blue-500 justify-center items-center rounded-full'
      >
        <input {...props} ref={ref} type='radio' className='peer opacity-0' />
        <div className='absolute h-[15px] w-[15px] rounded-full bg-gpa-blue-500 peer-checked:block hidden peer-focus:shadow-md shadow-gpa-blue-500' />
      </div>
      <span>{label}</span>
    </label>
  )
}

export const RadioButton = forwardRef(Component)
