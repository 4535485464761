import { PropsWithChildren, forwardRef, useCallback } from 'react'
import { BsCheck, BsExclamation } from 'react-icons/bs'

type Props = {
  title: string
  isValid: boolean
  errors?: boolean
  onClick?: () => void
}

const Component: React.ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<Props>
> = ({ title, isValid, errors, onClick, children }, ref) => {
  const handleClick = useCallback(() => {
    onClick && onClick()
  }, [onClick])

  return (
    <div
      ref={ref}
      data-error={errors}
      className='flex flex-1 flex-col border-2 data-[error=true]:border-red-200 border-gpa-gray-500 p-2 gap-5 rounded-md w-min-[350px]'
      onClick={handleClick}
    >
      <div className='flex justify-between items-start gap-2'>
        <h3 className='font-bold'>{title}</h3>
        <div
          data-error={errors}
          data-valid={isValid}
          className='shrink-0 h-[25px] w-[25px] border-2 data-[valid=true]:bg-gpa-blue-500 data-[error=true]:!bg-gpa-red group border-gpa-blue-500 data-[error=true]:border-gpa-red rounded-full'
        >
          {errors ? (
            <BsExclamation className='text-white h-full w-full' />
          ) : (
            <BsCheck className='text-white h-full w-full' />
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export const QuestionFrame = forwardRef(Component)
