import { useCallback } from 'react'

import { computeAgeService } from '~/services/computeAgeService'

import { patchEvaluationAtom } from '../evalutation/services'
import { Evaluation } from '../evalutation/types'

export function useHemodynamicMeasurements(evaluation?: Evaluation) {
  const calcFreqCardiacaReserva = useCallback(() => {
    if (!evaluation?.maxHeartFrequency || !evaluation.restingHeartFrequency)
      return 0
    return evaluation.maxHeartFrequency - evaluation.restingHeartFrequency
  }, [evaluation])

  const calcFreqCardiacaReservaNaAgua = useCallback(() => {
    if (
      !evaluation?.maxHeartFrequency ||
      !evaluation.restingHeartFrequencyInWater
    )
      return 0
    return (
      evaluation.maxHeartFrequency - evaluation.restingHeartFrequencyInWater
    )
  }, [evaluation])

  const calcClassificacaoDaPressaoAterial = useCallback((): Classification => {
    if (!evaluation || Object.keys(evaluation).length === 0)
      return {
        color: 'none',
        label: '',
      }

    let result = ''
    let color: Color = 'lime'

    if (
      evaluation.systolicArterialPressure < 120 &&
      evaluation.diastolicArterialPressure < 80
    ) {
      result = 'Ótima'
    } else if (
      evaluation.systolicArterialPressure < 129 &&
      evaluation.diastolicArterialPressure < 84
    ) {
      result = 'Normal'
      color = 'green'
    } else if (
      evaluation.systolicArterialPressure < 139 &&
      evaluation.diastolicArterialPressure < 89
    ) {
      result = 'Normal Limítrofe'
      color = 'orange'
    } else if (
      evaluation.systolicArterialPressure < 159 &&
      evaluation.diastolicArterialPressure < 99
    ) {
      result = 'Hipertensão leve'
      color = 'amber'
    } else if (
      evaluation.systolicArterialPressure < 179 &&
      evaluation.diastolicArterialPressure < 109
    ) {
      result = 'Hipertensão moderada'
      color = 'red'
    } else if (
      evaluation.systolicArterialPressure >= 180 &&
      evaluation.diastolicArterialPressure >= 110
    ) {
      result = ' Hipertensão Grave'
      color = 'purple'
    }

    if (
      evaluation.systolicArterialPressure >= 140 &&
      evaluation.diastolicArterialPressure >= 90
    ) {
      result += 'Hipertensão Sistólica Isolada'
      color = 'purple'
    }

    return {
      label: result,
      color,
    }
  }, [evaluation])

  const freqCardiacaMaximaSugerida = useCallback(() => {
    if (!evaluation?.student) return 0
    const age = computeAgeService(evaluation?.student.birthday)
    patchEvaluationAtom({ maxHeartFrequency: 220 - age })
  }, [evaluation])

  return {
    freqCardiacaMaximaSugerida,
    calcClassificacaoDaPressaoAterial,
    calcFreqCardiacaReserva,
    calcFreqCardiacaReservaNaAgua,
  }
}
