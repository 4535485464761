import { useEffect, useState } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { RockportProtocol } from '~/modules/aerobicCapabilities/schema'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluationAtom } from '~/modules/evalutation/services'

export const Rockport: React.FC = () => {
  const data = evaluationAtom.get()
  const [value, setValue] = useState<RockportProtocol>({
    heartRate: data?.rockport?.heartRate ?? 0,
    time: data?.rockport?.time ?? 0,
  })

  useEffect(() => {
    patchEvaluationAtom({ rockport: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <MaskedInput
        mask='float'
        inputMode='numeric'
        label='Tempo (min)'
        placeholder='0'
        value={value.time}
        onChange={(e) => {
          setValue((state) => ({
            ...state,
            time: e,
          }))
        }}
      />
      <MaskedInput
        mask='float'
        inputMode='numeric'
        label='Frequência Cardíaca Final (bpm)'
        placeholder='0'
        value={value.heartRate}
        onChange={(e) => {
          setValue((state) => ({
            ...state,
            heartRate: e,
          }))
        }}
      />
    </div>
  )
}
