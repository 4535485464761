import { useMemo } from 'react'
import ApexCharts from 'react-apexcharts'

import { aerobicMacrocycleAtom } from '~/modules/standardizedSeries/data'
import { AerobicRow } from '~/modules/standardizedSeries/types'
import { useAtom } from '~/observers/jotai'

export const Aerobic: React.FC = () => {
  const [data] = useAtom(aerobicMacrocycleAtom)

  const chartData = useMemo<Array<AerobicRow[]>>(() => {
    const a = data.filter((i) => i.mesocycle === 'Introdutório')
    const b = data.filter((i) => i.mesocycle === 'Básico')
    const c = data.filter((i) => i.mesocycle === 'Estabilizador')
    const d = data.filter((i) => i.mesocycle === 'Controle')
    return [a, b, c, d]
  }, [data])

  return (
    <div className='flex flex-col gap-10'>
      <div className='flex max-h-[300px] overflow-scroll'>
        <table className='relative table-auto w-full'>
          <thead className='sticky top-0 bg-slate-300'>
            <tr>
              <th>Sessão</th>
              <th>Mesociclo</th>
              <th>Microciclo</th>
              <th>Exercício</th>
              <th>Duração (min)</th>
              <th>Calorias (Kcal)</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={`aerobic-row-${row.microcycle}-${index}`}>
                <td className='bg-slate-100 border-r-4 px-2 border-white border-b-2'>
                  {row.session}
                </td>
                <td className='bg-slate-100 border-r-4 px-2 border-white border-b-2'>
                  {row.mesocycle}
                </td>
                <td className='bg-slate-100 border-r-4 px-2 border-white border-b-2'>
                  {row.microcycle}
                </td>
                <td className='bg-slate-100 border-r-4 px-2 border-white border-b-2'>
                  {row.exercise}
                </td>
                <td className='bg-slate-100 border-r-4 px-2 border-white border-b-2'>
                  {row.duration.toFixed()}
                </td>
                <td className='bg-slate-100 border-r-4 px-2 border-white border-b-2'>
                  {row.calories}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='grid xl:grid-cols-2 grid-cols-1 w-full'>
        <div className='w-full h-[600px]'>
          <ApexCharts
            type='bar'
            height='100%'
            width='100%'
            series={[
              {
                name: 'Introdutório',
                data: chartData[0].map((props) => +props.duration.toFixed()),
              },
            ]}
            options={{
              chart: {
                id: 'basic-bar',
              },
              xaxis: {
                categories: chartData[0]
                  .map((props) => +props.duration.toFixed())
                  .map((_, i) => `${i + 1}º dia`),
              },
              title: {
                text: 'Introdutório',
                align: 'center',
                style: { fontSize: '26px' },
              },
            }}
          />
        </div>
        <div className='w-full h-[600px]'>
          <ApexCharts
            type='bar'
            height='100%'
            width='100%'
            series={[
              {
                name: 'Básico',
                data: chartData[1].map((props) => +props.duration.toFixed()),
                color: '#e63333',
              },
            ]}
            options={{
              chart: {
                id: 'basic-bar',
                zoom: {
                  enabled: true,
                  type: 'x',
                  autoScaleYaxis: false,
                },
              },
              xaxis: {
                categories: chartData[1]
                  .map((props) => +props.duration.toFixed())
                  .map((_, i) => `${i + 1}º dia`),
              },
              title: {
                text: 'Básico',
                align: 'center',
                style: { fontSize: '26px' },
              },
            }}
          />
        </div>
        <div className='w-full h-[600px]'>
          <ApexCharts
            type='bar'
            height='100%'
            width='100%'
            series={[
              {
                name: 'Estabilizador',
                data: chartData[2].map((props) => +props.duration.toFixed()),
                color: '#e68a33',
              },
            ]}
            options={{
              chart: {
                id: 'basic-bar',
              },
              xaxis: {
                categories: chartData[2]
                  .map((props) => +props.duration.toFixed())
                  .map((_, i) => `${i + 1}º dia`),
              },
              title: {
                text: 'Estabilizador',
                align: 'center',
                style: { fontSize: '26px' },
              },
            }}
          />
        </div>
        <div className='w-full h-[600px]'>
          <ApexCharts
            type='bar'
            height='100%'
            width='100%'
            series={[
              {
                name: 'Controle',
                data: chartData[3].map((props) => +props.duration.toFixed()),
                color: '#1a8328',
              },
            ]}
            options={{
              chart: {
                id: 'basic-bar',
              },
              xaxis: {
                categories: chartData[3]
                  .map((props) => +props.duration.toFixed())
                  .map((_, i) => `${i + 1}º dia`),
              },
              title: {
                text: 'Controle',
                align: 'center',
                style: { fontSize: '26px' },
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}
