import { useCallback } from 'react'

import bgImage from '~/assets/img/loginBackground.png'
import { PageContainer } from '~/layout/personalTrainer/PageContainer'
import { authenticatePersonalTrainer } from '~/modules/authentication/services'
import { app_version } from '~/utils/version'

import { LoginFormType } from '../../../modules/authentication/schema'
import { LoginForm } from './components/form'

export const PersonalTrainerLogin: React.FC = () => {
  const handleSubmitForm = useCallback(async (payload: LoginFormType) => {
    await authenticatePersonalTrainer(payload)
  }, [])

  return (
    <PageContainer>
      <div
        className='flex flex-1 bg-no-repeat bg-left-top bg-cover'
        style={{
          backgroundImage: `url(${bgImage})`,
          minHeight: window.innerHeight,
        }}
      >
        <div className='flex flex-col items-center w-full max-w-2xl bg-white ml-auto px-6'>
          <div className='flex flex-col flex-1 w-full items-start justify-center gap-3'>
            <h1 className='text-4xl font-semibold'>Bem Vindo,</h1>
            <p className='text-xl'>Para acessar o GPA, faça o login abaixo.</p>
            <LoginForm onSubmit={handleSubmitForm} />
          </div>
          <p className='self-end pb-1 text-black'>{app_version}</p>
        </div>
      </div>
    </PageContainer>
  )
}
