import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { SelectInput } from '~/components/SelectInput'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  generatePeriodizedSeries,
  patchEvaluation,
  patchEvaluationAtom,
} from '~/modules/evalutation/services'
import { toggleActivityInWeek } from '~/modules/personalTrainerExercises/services'
import { strengthExercisesAmountOptions } from '~/modules/personalTrainerExercises/variables'
import { insertToast } from '~/modules/toasts/services'
import { useAtom } from '~/observers/jotai'
import {
  aerobicExercisesRoute,
  aerobicPrescriptionRoute,
} from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'
import { getWeekdayIndex, weekDaysOrder } from '~/utils/weekdays'

import { formsLayoutContext } from '../../../../layout'
import { ActivitiesManager } from './components/ActivitiesManager'
import { DayActivitiesList } from './components/DayActivitiesList'

export const Scheduler: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [evaluation] = useAtom(evaluationAtom)

  const [loading, setLoading] = useState<boolean>(false)
  const availableDaysInWeek = useMemo(
    () =>
      evaluation?.availableWeekDaysForPhysical?.map((day) =>
        getWeekdayIndex(day),
      ),
    [evaluation?.availableWeekDaysForPhysical],
  )

  const handleAlterPeriodizedSeries = useCallback((numSeries: number) => {
    patchEvaluationAtom({
      numSeries,
      ...(numSeries === 0
        ? {
            activityWeek: evaluationAtom
              .get()
              ?.activityWeek.map(
                (l) => l?.filter((e) => e !== 'Trabalho de Musculação') ?? null,
              ),
          }
        : {}),
    })
  }, [])

  const handleSave = useCallback(async () => {
    if (!evaluation_id || !student_id) return
    setLoading(true)

    const activityWeek = evaluationAtom.get()?.activityWeek

    if (
      !activityWeek ||
      !activityWeek.some((aw) => aw !== null && aw?.length)
    ) {
      insertToast({
        duration: 7,
        message: 'Por favor, termine de preencher o formulário',
        title: 'Formulário incompleto',
        type: 'error',
      })
      setLoading(false)
      return
    }

    const periodizedSeries = generatePeriodizedSeries(
      evaluationAtom.get()?.numSeries ?? 0,
    )

    await patchEvaluation(student_id, evaluation_id, {
      activityWeek,
      numSeries: evaluationAtom.get()?.numSeries ?? 0,
      periodizedSeries,
    }).then((success) => {
      setLoading(false)
      if (success) {
        navigate(
          aerobicExercisesRoute
            .replace(':student_id', student_id)
            .replace(':evaluation_id', evaluation_id),
          { replace: true },
        )
      }
    })
  }, [evaluation_id, student_id, navigate])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      aerobicPrescriptionRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  useEffect(() => {
    if (evaluation?.numSeries === 0) {
      patchEvaluationAtom({})
    }
  }, [evaluation?.numSeries])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <div className='flex flex-col flex-1 border rounded-md border-slate-200 p-3'>
        <p className='font-semibold text-xl'>Exercícios Aeróbicos</p>

        <p className='mb-4'>
          Entre os dias disponíveis, selecione os de sua preferência
        </p>
        <div className='grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4'>
          {weekDaysOrder
            .filter((d) =>
              evaluation?.availableWeekDaysForPhysical?.includes(d),
            )
            ?.map((c) => (
              <Checkbox
                key={`weekday-strength-${c}`}
                label={c}
                checked={evaluation?.activityWeek[getWeekdayIndex(c)]?.includes(
                  'Trabalho Aeróbico',
                )}
                onChange={() => toggleActivityInWeek(c, 'Trabalho Aeróbico')}
              />
            ))}
        </div>
      </div>

      <div className='flex flex-col flex-1 gap-4 border rounded-md border-slate-200 p-3'>
        <div className='flex flex-col gap-x-10 gap-y-1'>
          <p className='font-semibold text-xl'>Exercícios de Força</p>
          <p className='mb-4'>
            Selecione o número de séries de exercícios (Treinos A, B, C, D e E)
            de musculação (Mantenha 0 caso não queira usar treino de força)
          </p>
          <SelectInput
            value={evaluation?.numSeries}
            options={strengthExercisesAmountOptions}
            onChange={handleAlterPeriodizedSeries}
          />
        </div>
        {evaluation?.numSeries ? (
          <div className='grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4'>
            {weekDaysOrder
              .filter((d) =>
                evaluation?.availableWeekDaysForPhysical?.includes(d),
              )
              ?.map((c) => (
                <Checkbox
                  key={`weekday-aerobic-${c}`}
                  label={c}
                  checked={evaluation?.activityWeek[
                    getWeekdayIndex(c)
                  ]?.includes('Trabalho de Musculação')}
                  onChange={() =>
                    toggleActivityInWeek(c, 'Trabalho de Musculação')
                  }
                />
              ))}
          </div>
        ) : null}
      </div>

      <ActivitiesManager />

      <div className='grid grid-cols-1 lg:grid-cols-2 flex-wrap gap-4 justify-center'>
        {evaluation?.activityWeek.map((week, index) =>
          availableDaysInWeek?.includes(index) ? (
            <DayActivitiesList
              key={`activity-week-${index}`}
              data={week}
              dayIndex={index}
            />
          ) : null,
        )}
      </div>

      <div className='flex justify-between w-full'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
