import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import {
  adminAuthenticationSchema,
  AuthenticateAdminDTO,
} from '~/modules/authentication/schema'
import { authenticateAdmin } from '~/modules/authentication/services'

export const Form: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<AuthenticateAdminDTO>({
    resolver: zodResolver(adminAuthenticationSchema),
  })

  const SubmitForm = useCallback(async (payload: AuthenticateAdminDTO) => {
    await authenticateAdmin(payload)
  }, [])

  return (
    <form
      onSubmit={handleSubmit(SubmitForm)}
      className='flex flex-col gap-4 w-full'
    >
      <h3 className='text-xl font-semibold mb-4'>Login de Administrador</h3>
      <TextInput
        inputMode='email'
        placeholder='Seu e-mail'
        {...register('email')}
        error={errors.email?.message}
      />
      <TextInput
        type='password'
        placeholder='Sua senha'
        {...register('password')}
        error={errors.password?.message}
      />
      <Button loading={isSubmitting}>Entrar</Button>
    </form>
  )
}
