import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { documentNumberMask } from '~/components/TextInput/utils/mask'
import {
  RegisterPersonalTrainerDTO,
  registerPersonalTrainerSchema,
} from '~/modules/personalTrainer/schema'
import { registerPersonalTrainer } from '~/modules/personalTrainer/services'

type Props = {
  onSubmit: () => void
  link: string
}
export const PersonalTrainerRegistrationForm: React.FC<Props> = ({
  link,
  onSubmit,
}) => {
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<RegisterPersonalTrainerDTO>({
    defaultValues: {
      registrationLink: link,
    },
    resolver: zodResolver(registerPersonalTrainerSchema),
  })

  const handleSubmitForm = useCallback(
    (payload: RegisterPersonalTrainerDTO) => {
      console.log(payload)
      registerPersonalTrainer(payload).then((data) => {
        if (data) {
          onSubmit()
        }
      })
    },
    [onSubmit],
  )

  return (
    <form
      className='flex flex-col gap-2'
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <h2 className='text-2xl'>Faça seu cadastro &bull; Personal Trainer</h2>
      <TextInput
        {...register('name')}
        error={errors.name?.message}
        label='Seu Nome'
      />

      <div className='flex flex-col sm:flex-row gap-x-4 gap-y-2 '>
        <TextInput
          label='E-mail'
          {...register('email')}
          autoComplete='off'
          error={errors.email?.message}
        />
        <Controller
          control={control}
          name='documentNumber'
          defaultValue=''
          render={({ field }) => (
            <TextInput
              label='Número de CPF'
              value={field.value}
              onChange={(e) =>
                field.onChange(documentNumberMask(e.target.value))
              }
              error={errors.documentNumber?.message}
            />
          )}
        />
      </div>

      <TextInput
        label='Senha'
        type='password'
        {...register('password')}
        error={errors.password?.message}
      />

      <TextInput
        label='Confirme Sua Senha'
        type='password'
        autoComplete='off'
        {...register('confirmPassword')}
        error={errors.confirmPassword?.message}
      />

      <Button loading={isSubmitting}>Cadastrar</Button>
    </form>
  )
}
