import { useMemo } from 'react'

import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'

export const TableScheduling: React.FC = () => {
  const [data] = useAtom(evaluationReadOnlyAtom)

  const rows = useMemo(() => {
    const entries = []
    for (let i = 0; i < 23; i++) {
      entries.push(i)
    }
    return entries
  }, [])

  const week = useMemo(() => {
    return data?.activityWeek ? [...data.activityWeek] : []
  }, [data?.activityWeek])

  return (
    <table className='table-fixed mt-1 border-collapse w-full'>
      <thead>
        <tr>
          <th className='font-extralight border border-black'>Segunda</th>
          <th className='font-extralight border border-black'>Terça</th>
          <th className='font-extralight border border-black'>Quarta</th>
          <th className='font-extralight border border-black'>Quinta</th>
          <th className='font-extralight border border-black'>Sexta</th>
          <th className='font-extralight border border-black'>Sábado</th>
          <th className='font-extralight border border-black'>Domingo</th>
        </tr>
      </thead>

      <tbody>
        <tr className='text-[8.8px]'>
          {[1, 2, 3, 4, 5, 6, 0].map((d, i) => (
            <td
              key={`aw-day-${i}`}
              className='border border-black h-12 align-top p-0.5'
            >
              {week[d]?.map((a, j) => (
                <p key={`Wactivity-${i}-${j}`}>{a}</p>
              ))}
            </td>
          ))}
        </tr>

        {rows.map((r, rowIndex) =>
          r === 0 ? (
            <tr key={`schedule-row-${rowIndex}`}>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <span className='text-center text-[8.8px]'>Sessão</span>
                  <span className='border-l border-black text-center'>
                    Data
                  </span>
                </div>
              </td>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <span className='text-center'>Sessão</span>
                  <span className='border-l border-black text-center'>
                    Data
                  </span>
                </div>
              </td>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <span className='text-center'>Sessão</span>
                  <span className='border-l border-black text-center'>
                    Data
                  </span>
                </div>
              </td>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <span className='text-center'>Sessão</span>
                  <span className='border-l border-black text-center'>
                    Data
                  </span>
                </div>
              </td>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <span className='text-center'>Sessão</span>
                  <span className='border-l border-black text-center'>
                    Data
                  </span>
                </div>
              </td>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <span className='text-center'>Sessão</span>
                  <span className='border-l border-black text-center'>
                    Data
                  </span>
                </div>
              </td>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <span className='text-center'>Sessão</span>
                  <span className='border-l border-black text-center'>
                    Data
                  </span>
                </div>
              </td>
            </tr>
          ) : (
            <tr key={`schedule-row-${rowIndex}`}>
              <td className='border border-black'>
                <div className='grid grid-cols-2'>
                  <div></div>
                  <div className='border-l border-black'>&nbsp;</div>
                </div>
              </td>
              <td
                className='border border-black align-top'
                style={{ height: 'inherit' }}
              >
                <div className='grid grid-cols-2'>
                  <div></div>
                  <div className='border-l border-black'>&nbsp;</div>
                </div>
              </td>
              <td
                className='border border-black align-top'
                style={{ height: 'inherit' }}
              >
                <div className='grid grid-cols-2'>
                  <div></div>
                  <div className='border-l border-black'>&nbsp;</div>
                </div>
              </td>
              <td
                className='border border-black align-top'
                style={{ height: 'inherit' }}
              >
                <div className='grid grid-cols-2'>
                  <div></div>
                  <div className='border-l border-black'>&nbsp;</div>
                </div>
              </td>
              <td
                className='border border-black align-top'
                style={{ height: 'inherit' }}
              >
                <div className='grid grid-cols-2'>
                  <div></div>
                  <div className='border-l border-black'>&nbsp;</div>
                </div>
              </td>
              <td
                className='border border-black align-top'
                style={{ height: 'inherit' }}
              >
                <div className='grid grid-cols-2'>
                  <div></div>
                  <div className='border-l border-black'>&nbsp;</div>
                </div>
              </td>
              <td
                className='border border-black align-top'
                style={{ height: 'inherit' }}
              >
                <div className='grid grid-cols-2'>
                  <div></div>
                  <div className='border-l border-black'>&nbsp;</div>
                </div>
              </td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  )
}
