import { motion } from 'framer-motion'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  aerobicMacrocycleAtom,
  strengthMacrocycleAtom,
} from '~/modules/standardizedSeries/data'
import { EvaluationRouteParams } from '~/routes/types'
import { api } from '~/utils/api'

import { Aerobic } from './components/Aerobic'
import { ReportForm } from './components/ReportForm'
import { ReportPrinter } from './components/ReportPrinter'
import { Strength } from './components/Strength'
import { Tabs } from './types'

export const Macrocycles: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tabs>('aerobic')
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [hasData, setHasData] = useState<boolean>(false)

  const handleRequestReport = useCallback(
    async (payload: any) => {
      api
        .post(
          `personal-trainers/students/${student_id}/evaluations/${evaluation_id}/macrocycles`,
          payload,
        )
        .then(({ data }) => {
          data?.macrocycle[0] && aerobicMacrocycleAtom.set(data?.macrocycle[0])
          data?.macrocycle[1] && strengthMacrocycleAtom.set(data?.macrocycle[1])
          data && setHasData(true)
        })
        .catch((e) => console.log(e))
    },
    [student_id, evaluation_id],
  )

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <ReportForm onSubmit={handleRequestReport} />
      {hasData ? (
        <>
          <div className='flex justify-between'>
            <nav className='flex bg-slate-100 transition-all duration-200 self-start rounded-md p-1 mb-1'>
              <button
                className='py-1 px-2 relative'
                onClick={() => setActiveTab('aerobic')}
              >
                <span className='relative z-10'>Aeróbico</span>
                {activeTab === 'aerobic' ? (
                  <motion.div
                    layoutId='previewTabs'
                    transition={{ type: 'spring', bounce: 0.1, duration: 0.4 }}
                    className='absolute inset-0 rounded-md bg-white shadow-sm'
                  />
                ) : null}
              </button>

              <button
                className='py-1 px-2 relative'
                onClick={() => setActiveTab('strength')}
              >
                <span className='relative z-10'>Musculação</span>
                {activeTab === 'strength' ? (
                  <motion.div
                    layoutId='previewTabs'
                    transition={{ type: 'spring', bounce: 0.1, duration: 0.4 }}
                    className='absolute inset-0 rounded-md bg-white shadow-sm'
                  />
                ) : null}
              </button>
            </nav>
            <ReportPrinter />
          </div>

          {activeTab === 'aerobic' ? <Aerobic /> : <Strength />}
        </>
      ) : (
        <h2 className='text-lg text-center'>
          Sem plano de treino no momento, solicite um.
        </h2>
      )}
    </div>
  )
}
