import { intervalToDuration } from 'date-fns'

export function computeAgeService(birthday?: string): number {
  if (!birthday) return -1
  const brazilianDateArray = birthday.split('/')

  return (
    intervalToDuration({
      start: new Date(
        `${brazilianDateArray[2]}-${brazilianDateArray[1]}-${brazilianDateArray[0]}` ||
          new Date(),
      ),
      end: new Date(),
    }).years || -1
  )
}
