import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { documentNumberMask } from '~/components/TextInput/utils/mask'
import {
  UpdatePersonalTrainerAdminDTO,
  updatePersonalTrainerAdminSchema,
} from '~/modules/admin/schemas'
import { adminUpdatePersonalTrainer } from '~/modules/admin/services'
import { PersonalTrainer } from '~/modules/admin/types'

type Props = {
  onSubmit: () => void
  defaultData: PersonalTrainer
}

export const UpdatePersonalTrainerAdminForm: React.FC<Props> = ({
  onSubmit,
  defaultData,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePersonalTrainerAdminDTO>({
    defaultValues: {
      documentNumber: documentNumberMask(defaultData.documentNumber),
      name: defaultData.name,
      email: defaultData.email,
    },
    resolver: zodResolver(updatePersonalTrainerAdminSchema),
  })

  const handleFormSubmit = useCallback(
    (payload: UpdatePersonalTrainerAdminDTO) => {
      setLoading(true)
      adminUpdatePersonalTrainer(defaultData.id, payload).then((success) => {
        success && onSubmit()
        setLoading(false)
      })
    },
    [onSubmit, defaultData],
  )

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className='flex flex-col gap-2'
    >
      <TextInput
        label='Nome'
        {...register('name')}
        error={errors.name?.message}
      />

      <div className='grid grid-cols-1 sm:grid-cols-2  gap-4'>
        <Controller
          control={control}
          name='documentNumber'
          render={({ field }) => (
            <TextInput
              label='CPF'
              value={field.value}
              onChange={(e) =>
                field.onChange(documentNumberMask(e.target.value))
              }
              error={errors.documentNumber?.message}
            />
          )}
        />
        <TextInput
          label='E-mail'
          {...register('email')}
          error={errors.email?.message}
        />
      </div>
      <TextInput
        label='Senha'
        {...register('password')}
        error={errors.password?.message}
      />
      <Button disabled={loading}>Atualizar</Button>
    </form>
  )
}
