import { useCallback, useEffect, useMemo, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { PageNavigation } from '~/components/PageNavigation/index'
import { StudentCard } from '~/components/StudentCard'
import { TextInput } from '~/components/TextInput'
import { authenticationAtom } from '~/modules/authentication/data'
import { studentsAtom } from '~/modules/students/data'
import { listStudents } from '~/modules/students/services'
import { StudentsQuery } from '~/modules/students/types'
import { useAtom } from '~/observers/jotai'
import { debounce } from '~/utils/debounce'

let controller = new AbortController()

export const PersonalTrainerHome: React.FC = () => {
  const [userData] = useAtom(authenticationAtom)
  const [loading, setloading] = useState<boolean>(true)
  const [data] = useAtom(studentsAtom)
  const [filter, setFilter] = useState<StudentsQuery>({
    page: 0,
  })

  const debouncer = useMemo(() => debounce(), [])

  const updateQuery = useCallback(
    (newQuery: string) => {
      debouncer(() => setFilter((state) => ({ ...state, name: newQuery })))
    },
    [debouncer],
  )

  const updatePage = useCallback((page: number) => {
    setFilter((state) => ({ ...state, page }))
  }, [])

  useEffect(() => {
    ;(async () => {
      controller = new AbortController()
      await listStudents(filter, controller)
      setloading(false)
    })()

    return () => {
      controller.abort()
    }
  }, [filter])

  return (
    <div className='flex flex-1 flex-col items-end px-6 py-7 gap-4 overflow-y-auto'>
      <p>Olá, {userData?.user.name}</p>
      <div className='flex flex-wrap justify-between items-center p-3 gap-2 bg-white  w-full rounded-2xl'>
        <h1 className='font-semibold text-2xl'>Alunos</h1>
        <div className='w-full md:w-auto'>
          <TextInput
            noError
            className=''
            onChange={(e) => updateQuery(e.target.value)}
            rightIconArea={[
              <FiSearch
                key='search-input'
                size={16}
                className='text-gpa-gray-500 group-focus-within:text-gpa-blue-500'
              />,
            ]}
          />
        </div>
      </div>
      {loading ? (
        <p className='text-center self-center'>Carregando...</p>
      ) : (
        <>
          {data.students.length ? (
            <div className='grid grid-cols-[repeat(auto-fit,_minmax(_245px,324px))] justify-center grid-flow-row w-full gap-4'>
              {data.students.slice(0, 10).map((student, index) => (
                <StudentCard key={`student-${index}`} student={student} />
              ))}
            </div>
          ) : (
            <p className='text-center self-center mt-12'>
              Nenhum registro encontrado.
            </p>
          )}
        </>
      )}
      <div className='flex flex-col mx-auto mt-auto w-full'>
        <div className='ml-auto'>
          <PageNavigation
            currentPage={data.meta.page || 0}
            numPages={data.meta.totalPages || 0}
            onPageChange={(page) => updatePage(page)}
          />
        </div>
        <p className='text-center flex-1'>
          Alunos {data.students.length ?? 0}/{userData?.licenseLimit ?? 0}
        </p>
      </div>
    </div>
  )
}
