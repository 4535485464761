import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { personalTrainerExercisesAtom } from '../personalTrainerExercises/data'
import { insertToast } from '../toasts/services'
import { evaluationAtom, evaluationReadOnlyAtom } from './data'
import { Evaluation, PeriodizedSeries, PeriodizedSeriesType } from './types'

export function patchEvaluationAtom(data: Partial<Evaluation>) {
  const evaluation = evaluationAtom.get()
  if (!evaluation) return
  const obj = { ...evaluation, ...data }
  evaluationAtom.set(obj)
}

export async function createEvaluation(
  studentId: string,
): Promise<Evaluation | void> {
  return api
    .post<Evaluation>(`/personal-trainers/students/${studentId}/evaluations`)
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          title: 'Falha ao autenticar',
          duration: 3,
          message: error.response.data.message,
          type: 'error',
        })
      }
    })
}

export async function deleteEvaluation(
  studentId: string,
  evaluationId: string,
): Promise<boolean> {
  return api
    .delete(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}`,
    )
    .then(() => {
      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response.data.message,
          title: 'Erro',
          type: 'error',
        })
      }
      return false
    })
}

export async function getEvaluationData(
  studentId: string,
  evaluationId: string,
) {
  return api
    .get<Evaluation>(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}`,
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      console.log(error.response?.data.message)
      return undefined
    })
}

export async function patchEvaluation(
  studentId: string,
  evaluationId: string,
  data: Partial<Evaluation>,
) {
  return api
    .patch<Evaluation>(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}`,
      data,
    )
    .then(({ data }) => {
      if (data) {
        evaluationAtom.set(data)
        evaluationReadOnlyAtom.set(data)
      }
      return data
    })
    .catch((error: AxiosError<APIError>) => {
      console.log(error.response?.data?.message)
      insertToast({
        duration: 3,
        message: error.response?.data?.message ?? '',
        title: 'Erro ao salvar',
        type: 'error',
      })
      return undefined
    })
}

export async function openEvaluation(
  studentId: string,
  evaluationId: string,
  controller: AbortController,
) {
  return api
    .get<Evaluation>(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}`,
      {
        signal: controller.signal,
      },
    )
    .then(({ data }) => {
      if (data) {
        evaluationAtom.set(data)
        evaluationReadOnlyAtom.set(data)
      }
      return data
    })
    .catch((error: AxiosError<APIError>) => {
      console.log(error.response?.data?.message)
      return undefined
    })
}

export function getIntensityRange(exerciseType: PeriodizedSeriesType) {
  switch (exerciseType) {
    case 'Endurance':
      return [20, 35]
    case 'Força Dinâmica - Hipertrofia':
      return [70, 85]
    case 'Força Explosiva':
      return [30, 60]
    case 'R.M.L. - Resistência Muscular Localizada':
      return [40, 60]
    case 'Força Pura':
      return [90, 100]
    default:
      return [0, 100]
  }
}

export function validateIntensityRange(periodizedSeries: PeriodizedSeries) {
  const intensityRange = getIntensityRange(periodizedSeries.type)
  return (
    periodizedSeries.intensity >= intensityRange[0] &&
    periodizedSeries.intensity <= intensityRange[1]
  )
}

export function generatePeriodizedSeries(numSeries: number) {
  const periodizedSeries = evaluationAtom.get()?.periodizedSeries
  if (!periodizedSeries) return

  if (periodizedSeries.length >= (numSeries || 0)) {
    for (let i = periodizedSeries.length; i > (numSeries || 0); i--) {
      periodizedSeries.pop()
    }
  } else {
    const letters = ['A', 'B', 'C', 'D', 'E']
    for (let i = 0; i < (numSeries || 0); i++) {
      if (!periodizedSeries[i]) {
        periodizedSeries[i] = {
          letter: letters[i],
          description: '',
          intensity: getIntensityRange('Endurance')[0],
          type: 'Endurance',
          exercises: [],
        }
      }
    }
  }

  return periodizedSeries
}

export function suggestRepetitionNumber(exercise: string, intensity: number) {
  const exercises = personalTrainerExercisesAtom.get()?.strengthExercises
  if (!exercises) return 0

  const exerciseGroupName = exercises.find((group) =>
    group.exercises.includes(exercise),
  )?.groupName
  const group1 = ['Panturrilha', 'Coxas e gluteos', 'Abdomen']
  const group2 = ['Peito', 'Costas', 'Ombro', 'Biceps', 'Triceps', 'Antebraço']

  if (!exerciseGroupName) return 0

  if (group1.includes(exerciseGroupName)) return +(800 / intensity).toFixed()
  else if (group2.includes(exerciseGroupName))
    return +(600 / intensity).toFixed()
  else return 1
}

export function restoreNumSeries(score: number) {
  let numSeries = 1
  if (score === 0 || score === 1) numSeries = 3
  if (score === 2 || score === 3) numSeries = 2
  return numSeries
}

export function computeValuesViaStrengthTest(
  weight: number,
  numRepetitions: number,
  intensity: number,
) {
  let computedWeight = 0
  switch (numRepetitions) {
    case 1:
      computedWeight = weight * 1
      break
    case 2:
      computedWeight = weight * 1.07
      break
    case 3:
      computedWeight = weight * 1.1
      break
    case 4:
      computedWeight = weight * 1.13
      break
    case 5:
      computedWeight = weight * 1.16
      break
    case 6:
      computedWeight = weight * 1.2
      break
    case 7:
      computedWeight = weight * 1.23
      break
    case 8:
      computedWeight = weight * 1.27
      break
    case 9:
      computedWeight = weight * 1.32
      break
    case 10:
      computedWeight = weight * 1.36
      break
    default:
      computedWeight = 0
      break
  }

  return {
    maximumCharge: +computedWeight.toFixed(),
    initialCharge: +(computedWeight * (intensity / 100)).toFixed(),
  }
}

export function divideSeries(totalValue: number, numSeries: number) {
  if (!totalValue) return '0'

  const result = []
  const remainder = totalValue % numSeries

  for (let i = 0; i < numSeries; i++) {
    result.push(Math.floor(totalValue / numSeries))
  }

  for (let i = 0; i < remainder; i++) {
    result[i] += 1
  }

  return result.join('x')
}

export function closeEvaluation() {
  evaluationAtom.set(undefined)
}
