import zod from 'zod'

export const antropometrySchema = zod.object({
  currentWeight: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(1, 'Não pode ser menor que 1')
    .nonnegative('Valor não pode ser negativo'),
  stature: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(1, 'Não pode ser menor que 1')
    .nonnegative('Valor não pode ser negativo'),
  shoulder: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  chestRegular: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  chestInflated: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  abdomen: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  hip: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  waist: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  rightThigh: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  rightLeg: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  rightArmContracted: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  rightArmRelaxed: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  rightForearm: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  rightFist: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  leftThigh: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  leftLeg: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  leftArmContracted: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  leftArmRelaxed: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  leftForearm: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
  leftFist: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(0, 'Não pode ser negativo')
    .nonnegative('Valor não pode ser negativo'),
})

export type AntropometryFormType = zod.infer<typeof antropometrySchema>
