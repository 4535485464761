import { useCallback } from 'react'
import { FiX } from 'react-icons/fi'

import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import { patchPersonalTrainerExercises } from '~/modules/personalTrainerExercises/services'
import { useAtom } from '~/observers/jotai'

import { NewActivityForm } from './NewActivityForm'
import { RecoverStandardActivitiesButton } from './RecoverStandardActivitiesButton'

type Props = {
  visible: boolean
  onClose: () => void
}

export const ActivitiesModal: React.FC<Props> = ({ visible, onClose }) => {
  const [data] = useAtom(personalTrainerExercisesAtom)

  const handleRemoveActivity = useCallback(async (activity: string) => {
    const oldActivities = personalTrainerExercisesAtom.get()?.activities
    if (oldActivities) {
      const activities = [...oldActivities].filter((a) => a !== activity)
      await patchPersonalTrainerExercises({ activities })
    }
  }, [])

  return (
    <Modal visible={visible}>
      <div>
        <div className='flex items-center justify-between mb-3'>
          <h2 className='text-xl font-medium'>Nova Atividade</h2>

          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={onClose}
          >
            <FiX size={24} />
          </button>
        </div>

        <NewActivityForm />

        <div className='flex flex-col gap-3 bg-slate-50 p-2.5 mb-4 rounded-xl'>
          <h2 className='text-xl font-semibold'>
            Remover Atividades (da sua lista)
          </h2>
          <p>
            Estas são as atividades que você irá selecionar para o dia de treino
            nos quadros de cada dia da semana. Os botões abaixo servem para
            removê-las da sua lista de atividades, então tome cuidado.{' '}
            <b>
              Você só poderá recuperar as atividades que vem por padrão no GPA.
            </b>
          </p>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-wrap gap-3'>
            {data?.activities.map((a) => (
              <ExerciseTag
                exercise={a}
                onDelete={() => handleRemoveActivity(a)}
                key={`exercise-modal-${a.replace(' ', '-')}`}
              />
            ))}
          </div>
          <div className='self-end'>
            <RecoverStandardActivitiesButton />
          </div>
        </div>
      </div>
    </Modal>
  )
}
