import { createContext, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'

import PlaceholderImg from '~/assets/userPlaceholder.webp'
import { Student } from '~/modules/students/types'
import { studentEvaluationsRoute } from '~/routes/routes'

import { EvaluationsList } from './components/EvaluationsList'
import { NewEvaluationButton } from './components/NewEvaluationButton'
import { UpdateStudent } from './components/UpdateStudent'

type Props = {
  student: Student
}

const studentCardContext = createContext<Props>({} as Props)

const StudentCard: React.FC<Props> = ({ student }) => {
  const imgRef = useRef<HTMLImageElement>(null)

  const handleProfilePicError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.src = PlaceholderImg
    }
  }, [])

  return (
    <studentCardContext.Provider value={{ student }}>
      <div className='flex flex-col items-center h-[301px] p-4 rounded-2xl bg-white w-full gap-3'>
        <div className='flex bg-white h-14 w-14 rounded-full overflow-hidden'>
          <img
            ref={imgRef}
            src={null || PlaceholderImg}
            alt='face'
            loading='lazy'
            decoding='async'
            className='w-full h-full object-contain'
            onError={handleProfilePicError}
          />
        </div>
        <div className='flex flex-1 items-center justify-end gap-2 flex-col'>
          <h3 className='font-bold'>{student.name}</h3>
          {student.evaluations.length ? null : (
            <div className='flex flex-1 items-center'>
              <p className='mt-10'>Nenhuma avaliação ainda</p>
            </div>
          )}
          {student.evaluations.length ? (
            <small className='mb-auto'>Últimas avaliações</small>
          ) : null}
          <div className='flex flex-col flex-1 justify-between'>
            {student.evaluations
              .map((evaluation) => (
                <Link
                  key={evaluation.id}
                  to={studentEvaluationsRoute
                    .replace(':student_id', student.id)
                    .replace(':evaluation_id', evaluation.id)}
                  className='text-gpa-blue-500'
                  state={{ student }}
                >
                  {new Date(evaluation.createdAt).toLocaleDateString()}
                </Link>
              ))
              .splice(0, 2)}
            {student.evaluations.length ? <EvaluationsList /> : null}
          </div>
          <div className='flex gap-2 flex-wrap items-end'>
            <UpdateStudent />
            <NewEvaluationButton />
          </div>
        </div>
      </div>
    </studentCardContext.Provider>
  )
}

export { studentCardContext, StudentCard }
