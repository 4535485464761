import { format } from 'date-fns'

import GPALogo from '~/assets/img/gpaLogo.png'
import { authenticationAtom } from '~/modules/authentication/data'
import { useAtom } from '~/observers/jotai'

export const Cover: React.FC = () => {
  const [data] = useAtom(authenticationAtom)
  return (
    <div className='flex flex-col w-full page-break'>
      <div className='flex justify-center mt-80'>
        <img
          src={GPALogo}
          alt='Logo Gallo personal systems'
          width={320}
          height={240}
        />
      </div>

      <div className='grid grid-cols-2 mt-80 mr-8'>
        <div />
        <div className='flex flex-col'>
          <div className='flex justify-center border border-black '>
            Avaliação Física
          </div>
          <div className='pt-2'>
            <div>
              <span className='pr-4 font-bold'>Nome</span>
              <span>{data?.user.name}</span>
            </div>
            <div>
              <span className='pr-4 font-bold'>Data da Avaliação</span>
              <span>{format(new Date(), 'dd/MM/yyyy')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
