import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import zod from 'zod'

import { Button } from '~/components/Button'
import { SelectInput } from '~/components/SelectInput'
import { levelEnum, levelOptions } from '~/modules/standardizedSeries/variables'

function errorMap(issue: zod.ZodIssueOptionalMessage) {
  switch (issue.code) {
    case 'invalid_enum_value':
      return { message: 'Obrigatório' }
    default:
      return { message: 'Obrigatório' }
  }
}

const schema = zod.object({
  level: zod.enum(levelEnum, {
    errorMap,
  }),
})

type FormType = zod.infer<typeof schema>

type Props = {
  onSubmit: (payload: FormType) => Promise<void>
}

export const ReportForm: React.FC<Props> = ({ onSubmit }) => {
  const mounted = useRef<boolean>(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (mounted.current) return
    mounted.current = true
    onSubmit({ level: 'iniciante' })
  }, [onSubmit])

  return (
    // <form className='flex pt-3 gap-3' onSubmit={handleSubmit(onSubmit)}>
    //   <SelectInput
    //     options={levelOptions}
    //     defaultValue={'iniciante'}
    //     value={'iniciante'}
    //     {...register('level')}
    //     error={errors.level?.message}
    //   />
    //   <Button className='self-start'>Gerar plano de treino</Button>
    // </form>
    <></>
  )
}
