import { divideSeries } from '~/modules/evalutation/services'
import { strengthMacrocycleAtom } from '~/modules/standardizedSeries/data'
import { useAtom } from '~/observers/jotai'

import { Header } from './Header'

type Props = {
  min: number
  max: number
}

export const ExercisePrescription: React.FC<Props> = ({ min, max }) => {
  const [data] = useAtom(strengthMacrocycleAtom)

  console.log(data)

  return (
    <div className='px-8 py-7 w-full page-break'>
      <Header title='Prescrição de Exercícios (Série)' />
      {data.slice(min - 1, max).map((row, rowIndex) =>
        row.map((item, index) => (
          <table
            key={`${index}-${rowIndex}-session`}
            className='mt-3 w-full avoid-break-inside'
          >
            <thead>
              <tr className='text-sm border border-black bg-zinc-300'>
                <td colSpan={3} className=' pl-4 font-bold'>
                  Série {rowIndex + 1 + item.letter}
                </td>
                <td colSpan={4} className=' pl-10 font-bold'>
                  Sessão {rowIndex + 1}
                </td>
              </tr>
              <tr className='text-sm'>
                <td className='border border-black pl-1 font-bold'>
                  Exercícios
                </td>
                <td className='border border-black w-2 px-1 font-bold'>
                  Carga
                </td>
                <td className='border border-black w-2 px-1 font-bold'>
                  Série
                </td>
                <td className='border border-black w-2 whitespace-nowrap px-2 font-bold'>
                  Execução da Rep.
                </td>
                <td className='border border-black w-2 px-1 font-bold'>
                  Interv
                </td>
                <td className='border border-black w-2 px-1 font-bold'>
                  Veloc
                </td>
                <td className='border border-black w-2 whitespace-nowrap px-6 font-bold'>
                  Ajuste/Obs
                </td>
              </tr>
            </thead>
            <tbody className='text-sm font-printPages font-thin'>
              {item.exercises.map((exercise, i) => (
                <tr key={`${item.letter}-${rowIndex}-${i}`}>
                  <td className='border border-black  '>{exercise.name}</td>
                  <td className='border border-black w-2 px-2'>
                    <div className='flex justify-center'>
                      {exercise.initialCharge}
                    </div>
                  </td>
                  <td className='border border-black w-2 px-2'>
                    <div className='flex justify-center'>
                      {exercise.numSeries}
                    </div>
                  </td>
                  <td className='border border-black'>
                    <div className='flex justify-center'>
                      {divideSeries(exercise.repetitions, exercise.numSeries)}
                    </div>
                  </td>
                  <td className='border border-black w-2 px-2'>
                    {exercise.interval}
                  </td>
                  <td className='border border-black w-2 px-2'>
                    {exercise.speed}
                  </td>
                  <td className='border border-black'>
                    {exercise.adjustments}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )),
      )}
    </div>
  )
}
