import { Navigate, Route, Routes } from 'react-router-dom'

import { AdminLogin } from '~/pages/open/AdminLogin'
import { Invite } from '~/pages/open/Invite'
import { PersonalTrainerLogin } from '~/pages/open/personalTrainerLogin'

import {
  adminLoginRoute,
  personalTrainerInvitationRoute,
  personalTrainerLoginRoute,
} from './routes'

export const OpenRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path={personalTrainerLoginRoute}
        element={<PersonalTrainerLogin />}
      />
      <Route path={personalTrainerInvitationRoute} element={<Invite />} />
      <Route path={adminLoginRoute} element={<AdminLogin />} />
      <Route
        path='/*'
        element={<Navigate to={personalTrainerLoginRoute} replace />}
      />
    </Routes>
  )
}
