import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Controller, useForm } from 'react-hook-form'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { MaskedInput } from '~/components/TextInput'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  patchEvaluation,
  patchEvaluationAtom,
} from '~/modules/evalutation/services'
import { useAtom } from '~/observers/jotai'
import { anamnesisRoute, corporalEvaluationRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import { AntropometryFormType, antropometrySchema } from './schema'
import { getChartValuesService } from './services/getChartValuesService'
import { getSupportImageService } from './services/getSupportImageService'
import { chartOptions } from './variables'

export const AntropometryForm: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const { scrollToTop } = useContext(formsLayoutContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()

  const [supportImage, setSupportImage] = useState<string>(
    getSupportImageService(evaluation?.student.sex),
  )
  const chartData = getChartValuesService(evaluation)

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AntropometryFormType>({
    defaultValues: evaluation ? evaluation : {},
    resolver: zodResolver(antropometrySchema),
  })

  const handleSubmitForm = useCallback(
    async (payload: AntropometryFormType) => {
      setLoading(true)
      if (!student_id || !evaluation_id) return
      patchEvaluation(student_id, evaluation_id, payload)
        .then((success) => {
          if (success) {
            scrollToTop()
            navigate(
              corporalEvaluationRoute
                .replace(':student_id', student_id || '')
                .replace(':evaluation_id', evaluation_id || ''),
              { replace: true },
            )
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [scrollToTop, navigate, evaluation_id, student_id],
  )

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      anamnesisRoute
        .replace('<subform>', 'stress')
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  useEffect(() => {
    const { unsubscribe } = watch((formData) => {
      patchEvaluationAtom(formData)
    })

    return () => {
      unsubscribe()
    }
  }, [watch])

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'
    >
      <QuestionFrame
        isValid={antropometrySchema.safeParse(watch()).success}
        errors={!!Object.keys(errors).length}
        title='Perimetria'
      >
        <div className='flex flex-1 gap-4'>
          <div className='relative w-[250px] hidden md:block bg-gpa-blue-50 bg-opacity-40 rounded-md h-full'>
            <div className='w-full bg-red-50 sticky top-5'>
              <picture>
                <source type='image/jpg' />
                <img
                  loading='eager'
                  decoding='async'
                  src={supportImage}
                  alt='dummy'
                  className='w-full rounded-md'
                />
              </picture>
            </div>
          </div>
          <div className='flex flex-col flex-1 overflow-hidden'>
            <div className='flex flex-wrap gap-x-5'>
              <Controller
                control={control}
                name='currentWeight'
                render={({ field }) => (
                  <MaskedInput
                    className='appearance-none'
                    label='Peso Atual (kg)'
                    value={field.value}
                    mask='float'
                    inputMode='numeric'
                    onChange={field.onChange}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(evaluation?.student.sex),
                      )
                    }
                    error={errors.currentWeight?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name='stature'
                render={({ field }) => (
                  <MaskedInput
                    value={field.value}
                    onChange={field.onChange}
                    label='Estatura (cm)'
                    mask='float'
                    inputMode='numeric'
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(evaluation?.student.sex),
                      )
                    }
                    error={errors.stature?.message}
                  />
                )}
              />
            </div>

            <div className='flex flex-col flex-1 gap-3'>
              <p className='w-full text-center font-bold'>Perimetria</p>
              <div className='grid grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] items-start gap-x-5'>
                <Controller
                  control={control}
                  name='shoulder'
                  render={({ field }) => (
                    <MaskedInput
                      mask='float'
                      inputMode='numeric'
                      label='1 - Ombro (cm)'
                      value={field.value}
                      onChange={field.onChange}
                      error={errors.shoulder?.message}
                      onFocus={() =>
                        setSupportImage(
                          getSupportImageService(
                            evaluation?.student.sex,
                            'shoulder',
                          ),
                        )
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='chestInflated'
                  render={({ field }) => (
                    <MaskedInput
                      mask='float'
                      inputMode='numeric'
                      value={field.value}
                      onChange={field.onChange}
                      label='2 - Peitoral Inspirado (cm)'
                      error={errors.chestInflated?.message}
                      onFocus={() =>
                        setSupportImage(
                          getSupportImageService(
                            evaluation?.student.sex,
                            'chestInflated',
                          ),
                        )
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name='chestRegular'
                  render={({ field }) => (
                    <MaskedInput
                      mask='float'
                      inputMode='numeric'
                      value={field.value}
                      onChange={field.onChange}
                      label='3 - Peitoral normal (cm)'
                      error={errors.chestRegular?.message}
                      onFocus={() =>
                        setSupportImage(
                          getSupportImageService(
                            evaluation?.student.sex,
                            'chestRegular',
                          ),
                        )
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name='hip'
                  render={({ field }) => (
                    <MaskedInput
                      mask='float'
                      inputMode='numeric'
                      value={field.value}
                      onChange={field.onChange}
                      label='4 - Quadril (cm)'
                      error={errors.hip?.message}
                      onFocus={() =>
                        setSupportImage(
                          getSupportImageService(
                            evaluation?.student.sex,
                            'hip',
                          ),
                        )
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='abdomen'
                  render={({ field }) => (
                    <MaskedInput
                      mask='float'
                      inputMode='numeric'
                      value={field.value}
                      onChange={field.onChange}
                      label='5 - Abdômen (cm)'
                      error={errors.abdomen?.message}
                      onFocus={() =>
                        setSupportImage(
                          getSupportImageService(
                            evaluation?.student.sex,
                            'abdomen',
                          ),
                        )
                      }
                    />
                  )}
                />

                <Controller
                  control={control}
                  name='waist'
                  render={({ field }) => (
                    <MaskedInput
                      mask='float'
                      inputMode='numeric'
                      value={field.value}
                      onChange={field.onChange}
                      label='6 - Cintura (cm)'
                      error={errors.waist?.message}
                      onFocus={() =>
                        setSupportImage(
                          getSupportImageService(
                            evaluation?.student.sex,
                            'waist',
                          ),
                        )
                      }
                    />
                  )}
                />
              </div>
            </div>

            <div className='flex flex-wrap gap-x-5'>
              <div className='flex flex-1 flex-col gap-y-3'>
                <p className='font-bold'>Lado Direito</p>

                <div className='flex flex-col flex-1 flex-wrap'>
                  <Controller
                    control={control}
                    name='rightThigh'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='7 - Coxa Proximal Dir. (cm)'
                        error={errors.rightThigh?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'rightThigh',
                            ),
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='rightLeg'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='9 - Perna Direita Dir. (cm)'
                        error={errors.rightLeg?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'rightLeg',
                            ),
                          )
                        }
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='rightArmContracted'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='11 - Braço Dir. Contraído (cm)'
                        error={errors.rightArmContracted?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'rightArmContracted',
                            ),
                          )
                        }
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='rightArmRelaxed'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='13 - Braço Dir. Relaxado (cm)'
                        error={errors.rightArmRelaxed?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'rightArmRelaxed',
                            ),
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='rightForearm'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='15 - Antebraço Dir. (cm)'
                        error={errors.rightForearm?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'rightForearm',
                            ),
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='rightFist'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='17 - Punho Dir. (cm)'
                        error={errors.rightFist?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'rightFist',
                            ),
                          )
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <div className='flex flex-1 flex-col gap-y-3'>
                <p className='font-bold'>Lado Esquerdo</p>

                <div className='flex flex-col flex-1 flex-wrap'>
                  <Controller
                    control={control}
                    name='leftThigh'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='8 - Coxa Proximal Esq. (cm)'
                        error={errors?.leftThigh?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'leftThigh',
                            ),
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='leftLeg'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='10 - Perna Esquerda Esq. (cm)'
                        error={errors?.leftLeg?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'leftLeg',
                            ),
                          )
                        }
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='leftArmContracted'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='12 - Braço Esq. Contraído (cm)'
                        error={errors?.leftArmContracted?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'leftArmContracted',
                            ),
                          )
                        }
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='leftArmRelaxed'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='14 - Braço Esq. Relaxado (cm)'
                        error={errors?.leftArmRelaxed?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'leftArmRelaxed',
                            ),
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='leftForearm'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='16 - Antebraço Esq. (cm)'
                        error={errors?.leftForearm?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'leftForearm',
                            ),
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='leftFist'
                    render={({ field }) => (
                      <MaskedInput
                        mask='float'
                        inputMode='numeric'
                        value={field.value}
                        onChange={field.onChange}
                        label='18 - Punho Esq. (cm)'
                        error={errors?.leftFist?.message}
                        onFocus={() =>
                          setSupportImage(
                            getSupportImageService(
                              evaluation?.student.sex,
                              'leftFist',
                            ),
                          )
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuestionFrame>

      <div className='h-64'>
        <Chart
          type='bar'
          options={chartOptions}
          series={[{ data: chartData }]}
          height='100%'
        />
      </div>

      <div className='flex justify-between'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button loading={loading} disabled={loading}>
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </form>
  )
}
