import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { insertToast } from '../toasts/services'
import { studentsAtom } from './data'
import { RegisterStudentDTO } from './schemas'
import { ListStudentsDTO, Student, StudentsQuery } from './types'

export async function listStudents(
  params: StudentsQuery,
  abortController: AbortController,
) {
  await api
    .get<ListStudentsDTO>('/personal-trainers/students', {
      params,
      signal: abortController.signal,
    })
    .then(({ data }) => {
      studentsAtom.set(data)
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response?.data.message,
          title: 'Erro ao buscar alunos',
        })
      }
    })
}

export async function registerStudent(
  payload: RegisterStudentDTO,
): Promise<boolean> {
  return api
    .post<Student>('/personal-trainers/students', payload)
    .then(({ data }) => {
      const old = studentsAtom.get()
      studentsAtom.set({
        meta: old.meta,
        students: [data, ...old.students],
      })

      insertToast({
        message: 'Aluno cadastrado',
        title: 'Sucesso!',
        duration: 3,
      })

      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response?.data.message,
          title: 'Erro ao registrar aluno',
          type: 'error',
        })
      }
      return false
    })
}

export async function updateStudent(
  studentId: string,
  payload: RegisterStudentDTO,
) {
  return api
    .put<Student>(`/personal-trainers/students/${studentId}`, payload)
    .then(({ data }) => {
      const old = studentsAtom.get()
      const newList = old.students.map((student) =>
        student.id === data.id ? data : student,
      )

      studentsAtom.set({
        meta: old.meta,
        students: newList,
      })

      insertToast({
        duration: 3,
        message: 'Dados do aluno atualizados',
        title: 'Sucesso!',
      })

      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          type: 'error',
          message: error.response?.data.message,
          title: 'Erro ao atualizar dados do aluno',
        })
      }
      return false
    })
}

export async function deleteStudent(studentId: string) {
  return api
    .delete(`/personal-trainers/students/${studentId}`)
    .then(() => {
      insertToast({
        duration: 3,
        title: 'Sucesso!',
        message: 'Os dados do aluno foram apagados',
      })

      const newList = { ...studentsAtom.get() }
      studentsAtom.set({
        meta: newList.meta,
        students: newList.students.filter((s) => s.id !== studentId),
      })

      return true
    })
    .catch((e: AxiosError<APIError>) => {
      if (e.response?.data.message) {
        insertToast({
          duration: 3,
          title: 'Error ao deletar aluno',
          message: e.response.data.message,
          type: 'error',
        })
      }
      return false
    })
}
