import { useEffect } from 'react'

import { authenticationAtom } from '~/modules/authentication/data'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import { getPersonalTrainerExercises } from '~/modules/personalTrainerExercises/services'
import { useAtom } from '~/observers/jotai'

export const PersonalTrainerExercisesGetter: React.FC = () => {
  const [session] = useAtom(authenticationAtom)

  useEffect(() => {
    if (session?.role === 'personalTrainer') {
      const controller = new AbortController()
      getPersonalTrainerExercises(controller)

      return () => {
        controller.abort()
        personalTrainerExercisesAtom.set(undefined)
      }
    }
  }, [session])

  return null
}
