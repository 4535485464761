import { useEffect, useState } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { MedicalProtocol } from '~/modules/aerobicCapabilities/schema'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluationAtom } from '~/modules/evalutation/services'

export const Medical: React.FC = () => {
  const data = evaluationAtom.get()
  const [value, setValue] = useState<MedicalProtocol>({
    heartRate: data?.medical?.heartRate ?? 0,
    maxVO2Consumption: data?.medical?.maxVO2Consumption ?? 0,
  })

  useEffect(() => {
    patchEvaluationAtom({ medical: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <MaskedInput
        mask='float'
        inputMode='numeric'
        label='Consumo Máximo de VO2'
        placeholder='0'
        value={value?.maxVO2Consumption}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            maxVO2Consumption: e,
          }))
        }
      />
      <MaskedInput
        mask='float'
        inputMode='numeric'
        label='Frequência Cardíaca Final (bpm)'
        placeholder='0'
        value={value?.heartRate}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            heartRate: e,
          }))
        }
      />
    </div>
  )
}
