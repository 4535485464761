import { useCallback, useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import { useAreobicCapabilities } from '~/modules/aerobicCapabilities/useAerobicCapabilities'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  computeValuesViaStrengthTest,
  patchEvaluationAtom,
  restoreNumSeries,
  suggestRepetitionNumber,
} from '~/modules/evalutation/services'
import {
  PeriodizedSeries,
  PeriodizedSeriesExercise,
} from '~/modules/evalutation/types'
import { useAtom } from '~/observers/jotai'
import { debounce } from '~/utils/debounce'

import { FormType, StrengthTestForm } from './StrengthTestForm'

type Props = {
  series: PeriodizedSeries
  exercise: PeriodizedSeriesExercise
}

const debouncer = debounce()

export const EditModal: React.FC<Props> = ({ series, exercise }) => {
  const [show, setShow] = useState<boolean>(false)
  const [state, setState] = useState<PeriodizedSeriesExercise>(() => exercise)
  const [evaluation] = useAtom(evaluationAtom)
  const { score } = useAreobicCapabilities(evaluation)

  const handleToggleModal = useCallback(() => {
    setShow((state) => !state)
  }, [])

  const handleStrengthTestForm = useCallback(
    (payload: FormType) => {
      const { initialCharge, maximumCharge } = computeValuesViaStrengthTest(
        payload.weight,
        payload.numRepetitions,
        series.intensity,
      )

      setState((state) => ({
        ...state,
        initialCharge,
        maximumCharge,
      }))
    },
    [series],
  )

  const handleRestoreNumSeries = useCallback(() => {
    const numSeries = restoreNumSeries(score)
    setState((state) => ({ ...state, numSeries }))
  }, [score])

  const handleRestoreRepetitionsNumber = useCallback(() => {
    const repetitions = suggestRepetitionNumber(exercise.name, series.intensity)
    setState((state) => ({ ...state, repetitions }))
  }, [exercise, series])

  useEffect(() => {
    exercise.adjustments = state.adjustments
    exercise.initialCharge = state.initialCharge
    exercise.interval = state.interval
    exercise.maximumCharge = state.maximumCharge
    exercise.repetitions = state.repetitions
    exercise.numSeries = state.numSeries

    debouncer(() => {
      patchEvaluationAtom({
        periodizedSeries: evaluationAtom.get()?.periodizedSeries,
      })
    })
  }, [state, exercise])

  return (
    <>
      <Button className='flex-1' onClick={handleToggleModal}>
        Editar
      </Button>

      <Modal visible={show}>
        <div className='flex items-center justify-between mb-2'>
          <h2 className='text-xl font-medium truncate'>
            Série {series.letter} - {exercise.name}
          </h2>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1.5 rounded-full'
            onClick={handleToggleModal}
          >
            <FiX size={24} />
          </button>
        </div>

        <StrengthTestForm onSubmit={handleStrengthTestForm} />

        <div className='flex flex-col mt-5'>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              containerClassName='pointer-events-none'
              label='Carga Inicial'
              value={exercise.initialCharge}
              readOnly
            />
            <TextInput
              label='Carga Máxima'
              value={state.maximumCharge}
              onChange={(e) => {
                const maximumCharge = +e.target.value.replace(/\D/, '')
                const initialCharge = +(
                  (maximumCharge ?? 0) *
                  (series.intensity / 100)
                ).toFixed(0)

                setState((state) => ({
                  ...state,
                  initialCharge,
                  maximumCharge,
                }))
              }}
            />
          </div>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              label='Séries'
              value={state.numSeries}
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  numSeries: +e.target.value.replace(/\D/, ''),
                }))
              }
            />
            <TextInput
              label='Repetições'
              value={state.repetitions}
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  repetitions: +e.target.value.replace(/\D/, ''),
                }))
              }
            />
          </div>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              label='Intervalo'
              value={state.interval}
              onChange={(e) =>
                setState((state) => ({ ...state, interval: e.target.value }))
              }
            />
            <TextInput
              label='Velocidade'
              value={state.speed}
              onChange={(e) =>
                setState((state) => ({ ...state, speed: e.target.value }))
              }
            />
          </div>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              label='Ajuste/Obs'
              value={state.adjustments}
              onChange={(e) =>
                setState((state) => ({ ...state, adjustments: e.target.value }))
              }
            />
          </div>
        </div>

        <div className='my-3'>
          <Button className='w-full' onClick={handleToggleModal}>
            Finalizar
          </Button>
        </div>

        <div className='flex gap-3 flex-wrap'>
          <Button className='flex-1' onClick={handleRestoreNumSeries}>
            Restaurar número de Séries
          </Button>

          <Button className='flex-1' onClick={handleRestoreRepetitionsNumber}>
            Restaurar número de repetições
          </Button>
        </div>
      </Modal>
    </>
  )
}
