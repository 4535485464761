import zod from 'zod'

export const aerobicPrescriptionSchema = zod.object({
  hasAerobicPrescription: zod.boolean(),
  targetZone: zod.number().int().min(0).max(1),
  objectiveIndex: zod.number().min(0),
  intensity: zod.number().min(1),
  proposedEnergy: zod.number().min(0),
})

export type AerobicPrescriptionDTO = zod.infer<typeof aerobicPrescriptionSchema>
