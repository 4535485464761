import { ForwardRefRenderFunction, PropsWithChildren, forwardRef } from 'react'

const Component: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren> = (
  { children },
  ref,
) => {
  return (
    <div
      ref={ref}
      className='print:relative absolute top-0 right-0 bottom-0 left-0 print:block flex flex-col max-w-[1950px] w-full mx-auto overflow-hidden overflow-y-auto'
    >
      {children}
    </div>
  )
}

export const PageContainer = forwardRef(Component)
