import { Props } from './types'

export const PersonalTrainer: React.FC<Props> = ({ height, color, width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={width ?? '512.000000pt'}
      height={height ?? '512.000000pt'}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color ?? 'currentColor'}
        stroke='none'
      >
        <path d='M2479 4909 c-330 -27 -616 -270 -711 -604 -32 -114 -32 -306 0 -419 104 -365 420 -606 792 -606 322 0 599 176 740 471 189 396 21 880 -375 1075 -145 71 -281 96 -446 83z m197 -210 c230 -42 422 -226 480 -459 23 -90 15 -261 -16 -349 -64 -181 -223 -329 -416 -387 -81 -25 -247 -25 -329 0 -215 65 -376 229 -430 441 -18 69 -20 211 -4 287 6 29 28 89 49 133 119 248 391 385 666 334z' />
        <path d='M1675 3054 c-170 -36 -349 -138 -472 -267 -76 -80 -272 -369 -524 -772 -232 -371 -266 -453 -257 -628 5 -101 27 -172 80 -253 17 -27 167 -185 332 -350 281 -282 305 -303 361 -324 116 -42 216 -36 321 20 l52 27 6 -66 c16 -164 18 -173 46 -205 23 -25 36 -31 70 -31 35 0 47 6 71 33 20 22 29 43 29 65 0 42 -148 1721 -155 1764 -12 66 -99 105 -152 68 -27 -19 -469 -622 -483 -659 -5 -15 -7 -39 -4 -54 4 -17 98 -119 254 -277 205 -206 250 -257 260 -292 39 -129 -83 -252 -213 -213 -35 10 -89 58 -324 293 -310 308 -340 348 -350 456 -6 65 13 144 54 226 37 72 300 491 478 760 117 176 169 245 222 295 139 131 279 188 478 197 120 6 121 6 131 -18 6 -13 104 -248 218 -521 l207 -497 -81 -217 c-49 -133 -78 -223 -75 -235 10 -30 38 -44 199 -98 163 -56 185 -59 209 -33 8 9 59 133 111 274 112 299 112 294 17 329 -34 12 -61 24 -61 26 0 4 337 818 393 947 l21 49 120 -6 c200 -9 340 -66 479 -197 53 -50 105 -119 222 -295 180 -272 441 -688 479 -763 38 -75 59 -167 53 -228 -10 -101 -40 -139 -336 -438 -330 -333 -354 -348 -461 -294 -78 40 -114 121 -90 201 10 35 55 86 260 292 156 158 250 260 254 277 3 15 2 38 -4 53 -14 36 -456 641 -483 660 -53 37 -140 -2 -152 -68 -7 -43 -155 -1722 -155 -1764 0 -22 9 -43 29 -65 24 -27 36 -33 71 -33 34 0 47 6 70 31 28 32 30 41 46 205 l6 66 44 -24 c121 -68 262 -68 377 0 54 32 603 586 645 651 52 81 75 151 80 253 9 175 -24 255 -257 628 -251 401 -448 692 -524 772 -127 134 -301 230 -479 267 -111 23 -1655 23 -1763 0z m1191 -311 c-29 -71 -109 -262 -177 -425 -68 -164 -126 -298 -129 -298 -3 0 -61 134 -129 298 -68 163 -148 354 -177 425 l-53 127 359 0 359 0 -53 -127z m-1381 -1277 c13 -154 23 -281 22 -282 -2 -2 -65 58 -140 134 l-137 137 112 154 c62 85 113 150 115 145 1 -5 14 -135 28 -288z m2300 134 l105 -145 -137 -137 c-75 -76 -138 -136 -140 -134 -1 1 8 125 22 276 14 150 25 280 25 288 0 22 5 16 125 -148z' />
      </g>
    </svg>
  )
}
