import { useContext } from 'react'
import { createPortal } from 'react-dom'
import { FiX } from 'react-icons/fi'

import { UpdatePersonalTrainerAdminForm } from '~/forms/UpdatePersonalTrainerAdminForm'

import { Modal } from '../Modal'
import { context } from './contex'

type Props = {
  visible: boolean
  onDismiss: () => void
}

export const EditPersonalTrainerModal: React.FC<Props> = ({
  visible,
  onDismiss,
}) => {
  const { personalTrainer } = useContext(context)

  return createPortal(
    <Modal visible={visible}>
      <div className='flex items-center justify-between mb-4'>
        <h1 className='text-xl'>Editar Personal Trainer</h1>
        <button
          type='button'
          className='p-2 rounded-full hover:bg-slate-50'
          onClick={onDismiss}
        >
          <FiX size={26} />
        </button>
      </div>

      <UpdatePersonalTrainerAdminForm
        onSubmit={onDismiss}
        defaultData={personalTrainer}
      />
    </Modal>,
    document.getElementById('modals')!,
  )
}
