export const colors = {
  red: 'bg-red-500',
  amber: 'bg-amber-500',
  green: 'bg-green-500',
  lime: 'bg-lime-500',
  orange: 'bg-orange-500',
  purple: 'bg-purple-500',
  yellow: 'bg-yellow-500',
  none: '',
} as const

export const colorsArray = [
  'bg-indigo-500',
  'bg-green-500',
  'bg-lime-500',
  'bg-orange-500',
  'bg-amber-500',
  'bg-orange-500',
  'bg-red-500',
]
