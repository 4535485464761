import zod from 'zod'

import {
  exerciseIntensityLevelEnum,
  availableTimeEnum,
  availableWeekDaysEnum,
  exercisesEnum,
  goalsEnum,
  periodEnum,
  presentPhysicalConditioningEnum,
  exercisesLocaleEnum,
  regularMealsEnum,
  dietEnum,
  efforTestEnum,
  alcoholicDrinksEnum,
  litersPerDayEnum,
  diabetesEnum,
  glicemyLevelEnum,
  cholesterolLevelsEnum,
  triglyceridesLevelsEnum,
  tabagismEnum,
  diseasesEnum,
  medicationEnum,
  jointMuscularDamageEnum,
  pedigreeEnum,
} from './variables'

function errorMap(issue: zod.ZodIssueOptionalMessage) {
  switch (issue.code) {
    case 'invalid_enum_value':
      return { message: 'Campo obrigatório' }
    default:
      return { message: 'Campo obrigatório' }
  }
}

export const generalFormSchema = zod
  .object({
    goals: zod
      .enum(goalsEnum)
      .array()
      .nonempty('É preciso selecionar ao menos 1 item'),
    presentPhysicalConditioning: zod.enum(presentPhysicalConditioningEnum, {
      errorMap,
    }),
    physicalConditioningTimePeriod: zod.object({
      period: zod.enum(periodEnum, { errorMap }),
      quantity: zod
        .number({
          required_error: 'Campo obrigatório',
          invalid_type_error: 'Deve ser um número',
        })
        .nonnegative('Não deve ser negativo')
        .min(0),
    }),
    availableDaysPerWeek: zod
      .number({
        errorMap,
      })
      .min(1)
      .max(7),
    availableTime: zod.enum(availableTimeEnum, {
      errorMap,
    }),
    availableWeekDaysForPhysical: zod
      .array(zod.enum(availableWeekDaysEnum), {
        invalid_type_error: 'É preciso selecionar ao menos 1 item',
      })
      .nonempty('É preciso selecionar ao menos 1 item'),
    currentExercises: zod
      .object({
        exercises: zod.array(zod.enum(exercisesEnum)),
        other: zod.string(),
      })
      .refine(({ exercises, other }) => exercises.length || other.length, {
        message: 'Ao menos um valor deve ser preenchido',
      })
      .refine(
        ({ exercises, other }) =>
          (exercises.length === 1 &&
            exercises.includes('Nenhum') &&
            !other.length) ||
          ((exercises.length || other.length) && !exercises.includes('Nenhum')),
        {
          message:
            'Quando opção "Nenhum" for selecionada, nenhum outro valor deve permanecer selecionado',
        },
      ),
    exercisesIntensity: zod.enum(exerciseIntensityLevelEnum, {
      errorMap,
    }),
    exercisesLocale: zod.enum(exercisesLocaleEnum, {
      errorMap,
    }),
    regularMeals: zod
      .array(zod.enum(regularMealsEnum), {
        invalid_type_error: 'É preciso selecionar ao menos 1 item',
      })
      .nonempty('É preciso selecionar ao menos 1 item'),
    diet: zod.enum(dietEnum, { errorMap }),
    suplements: zod.string().optional(),
    effortTest: zod.enum(efforTestEnum, { errorMap }),
    alcoholicDrinks: zod.enum(alcoholicDrinksEnum, {
      errorMap,
    }),
    litersPerDay: zod.enum(litersPerDayEnum, {
      errorMap,
    }),
    diabetes: zod.enum(diabetesEnum, { errorMap }),
    glicemyLevel: zod.enum(glicemyLevelEnum, {
      errorMap,
    }),
    cholesterolLevels: zod.enum(cholesterolLevelsEnum, {
      errorMap,
    }),
    triglyceridesLevels: zod.enum(triglyceridesLevelsEnum, {
      errorMap,
    }),
    tabagism: zod.enum(tabagismEnum, { errorMap }),
    diseases: zod
      .object({
        diseases: zod.array(zod.enum(diseasesEnum)),
        other: zod.string(),
      })
      .refine(({ diseases, other }) => diseases.length || other.length, {
        message: 'Ao menos um valor deve ser preenchido',
      })
      .refine(
        ({ diseases, other }) =>
          (diseases.length === 1 &&
            diseases.includes('Nenhuma') &&
            !other.length) ||
          ((diseases.length || other.length) && !diseases.includes('Nenhuma')),
        {
          message:
            'Quando opção "Nenhum" for selecionada, nenhum outro valor deve permanecer selecionado',
        },
      ),
    regularMedications: zod
      .object({
        medications: zod.array(zod.enum(medicationEnum)),
        other: zod.string(),
      })
      .refine(({ medications, other }) => other.length || medications.length, {
        message: 'Ao menos um valor deve ser preenchido',
      })
      .refine(
        ({ medications, other }) =>
          (medications.length === 1 &&
            medications.includes('Nenhum') &&
            !other.length) ||
          ((medications.length || other.length) &&
            !medications.includes('Nenhum')),
        {
          message:
            'Quando opção "Nenhum" for selecionada, nenhum outro valor deve permanecer selecionado',
        },
      ),
    jointMuscularDamage: zod
      .array(zod.enum(jointMuscularDamageEnum), {
        invalid_type_error: 'É preciso selecionar ao menos 1 item',
      })
      .nonempty('É preciso selecionar ao menos 1 item')
      .refine(
        (value) =>
          (value.length === 1 && value.includes('Nenhuma')) ||
          (value.length && !value.includes('Nenhuma')),
        {
          message: 'Opção "Nenhuma" não pode estar acompanhada',
        },
      ),
    pedigree: zod
      .array(zod.enum(pedigreeEnum), {
        invalid_type_error: 'É preciso selecionar ao menos 1 item',
      })
      .nonempty('É preciso selecionar ao menos 1 item')
      .refine(
        (value) =>
          (value.length === 1 && value.includes('Nenhuma')) ||
          (value.length && !value.includes('Nenhuma')),
        {
          message: 'Opção "Nenhuma" não pode estar acompanhada',
        },
      ),
    commentary: zod.string().optional(),
  })
  .refine(
    (value) => {
      if (
        value.availableDaysPerWeek < value.availableWeekDaysForPhysical.length
      ) {
        return false
      }
      return true
    },
    {
      message:
        'Você marcou mais dias do que disse ter disponíveis, desmarque na questão 6 ou aumente o limite de dias aqui.',
      path: ['availableDaysPerWeek'],
    },
  )

export const validateQuestionsSchema = zod.object({
  goals: zod
    .enum(goalsEnum)
    .array()
    .nonempty('É preciso selecionar ao menos 1 item'),
  presentPhysicalConditioning: zod.enum(presentPhysicalConditioningEnum, {
    errorMap,
  }),
  physicalConditioningTimePeriod: zod.object({
    period: zod.enum(periodEnum, { errorMap }),
    quantity: zod
      .number({
        required_error: 'Campo obrigatório',
        invalid_type_error: 'Deve ser um número',
      })
      .nonnegative('Não deve ser negativo')
      .min(0),
  }),
  availableDaysPerWeek: zod
    .number({
      errorMap,
    })
    .min(1)
    .max(7),
  availableTime: zod.enum(availableTimeEnum, {
    errorMap,
  }),
  availableWeekDaysForPhysical: zod
    .array(zod.enum(availableWeekDaysEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item'),
  currentExercises: zod
    .object({
      exercises: zod.array(zod.enum(exercisesEnum)),
      other: zod.string(),
    })
    .refine(({ exercises, other }) => exercises.length || other.length, {
      message: 'Ao menos um valor deve ser preenchido',
    })
    .refine(
      ({ exercises, other }) =>
        (exercises.length === 1 &&
          exercises.includes('Nenhum') &&
          !other.length) ||
        ((exercises.length || other.length) && !exercises.includes('Nenhum')),
      {
        message:
          'Quando opção "Nenhum" for selecionada, nenhum outro valor deve permanecer selecionado',
      },
    ),
  exercisesIntensity: zod.enum(exerciseIntensityLevelEnum, {
    errorMap,
  }),
  exercisesLocale: zod.enum(exercisesLocaleEnum, {
    errorMap,
  }),
  regularMeals: zod
    .array(zod.enum(regularMealsEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item'),
  diet: zod.enum(dietEnum, { errorMap }),
  suplements: zod.string().optional(),
  effortTest: zod.enum(efforTestEnum, { errorMap }),
  alcoholicDrinks: zod.enum(alcoholicDrinksEnum, {
    errorMap,
  }),
  litersPerDay: zod.enum(litersPerDayEnum, {
    errorMap,
  }),
  diabetes: zod.enum(diabetesEnum, { errorMap }),
  glicemyLevel: zod.enum(glicemyLevelEnum, {
    errorMap,
  }),
  cholesterolLevels: zod.enum(cholesterolLevelsEnum, {
    errorMap,
  }),
  triglyceridesLevels: zod.enum(triglyceridesLevelsEnum, {
    errorMap,
  }),
  tabagism: zod.enum(tabagismEnum, { errorMap }),
  diseases: zod
    .object({
      diseases: zod.array(zod.enum(diseasesEnum)),
      other: zod.string(),
    })
    .refine(({ diseases, other }) => diseases.length || other.length, {
      message: 'Ao menos um valor deve ser preenchido',
    })
    .refine(
      ({ diseases, other }) =>
        (diseases.length === 1 &&
          diseases.includes('Nenhuma') &&
          !other.length) ||
        ((diseases.length || other.length) && !diseases.includes('Nenhuma')),
      {
        message:
          'Quando opção "Nenhum" for selecionada, nenhum outro valor deve permanecer selecionado',
      },
    ),
  regularMedications: zod
    .object({
      medications: zod.array(zod.enum(medicationEnum)),
      other: zod.string(),
    })
    .refine(({ medications, other }) => other.length || medications.length, {
      message: 'Ao menos um valor deve ser preenchido',
    })
    .refine(
      ({ medications, other }) =>
        (medications.length === 1 &&
          medications.includes('Nenhum') &&
          !other.length) ||
        ((medications.length || other.length) &&
          !medications.includes('Nenhum')),
      {
        message:
          'Quando opção "Nenhum" for selecionada, nenhum outro valor deve permanecer selecionado',
      },
    ),
  jointMuscularDamage: zod
    .array(zod.enum(jointMuscularDamageEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item')
    .refine(
      (value) =>
        (value.length === 1 && value.includes('Nenhuma')) ||
        (value.length && !value.includes('Nenhuma')),
      {
        message: 'Opção "Nenhuma" não pode estar acompanhada',
      },
    ),
  pedigree: zod
    .array(zod.enum(pedigreeEnum), {
      invalid_type_error: 'É preciso selecionar ao menos 1 item',
    })
    .nonempty('É preciso selecionar ao menos 1 item')
    .refine(
      (value) =>
        (value.length === 1 && value.includes('Nenhuma')) ||
        (value.length && !value.includes('Nenhuma')),
      {
        message: 'Opção "Nenhuma" não pode estar acompanhada',
      },
    ),
  commentary: zod.string().optional(),
})

export type GeneralFormType = zod.infer<typeof generalFormSchema>
