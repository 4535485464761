import { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'

import { PersonalTrainer } from '~/modules/admin/types'

import { Modal } from '../Modal'
import { documentNumberMask } from '../TextInput/utils/mask'
import { context } from './contex'
import { DetailsModal } from './DetailsModal'

type Props = {
  data: PersonalTrainer
}

export const PersonalTrainerCard: React.FC<Props> = ({ data }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  return (
    <context.Provider
      value={{
        personalTrainer: data,
      }}
    >
      <div
        role='button'
        tabIndex={0}
        className='group flex gap-2 flex-col p-2 w-60 focus:bg-slate-50 focus:ring-blue-500 outline-none bg-white ring-1 ring-slate-200 shadow-md rounded-md'
        onClick={handleToggleModal}
      >
        <p className='line-clamp-2'>
          <b>{data.name}</b>
        </p>
        <p>{documentNumberMask(data.documentNumber)}</p>
        <p>limite de alunos: {data.personalTrainerLicense[0].finalAmount}</p>
        <span className='mt-auto inline-flex justify-center px-2 py-1 group-hover:bg-gpa-blue-50 text-gpa-blue-500 rounded-md'>
          Detalhes
        </span>
      </div>
      {createPortal(
        <Modal visible={showModal}>
          <DetailsModal onDismiss={handleToggleModal} />
        </Modal>,
        document.getElementById('modals')!,
      )}
    </context.Provider>
  )
}
