export function normalizeDate(date: string) {
  if (!date) return ''
  const [day, month, year] = date.split('/')
  return `${year}-${month}-${day}`
}

export function validateDate(dateString: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  if (!dateString.match(regex)) return false

  const [year, month, day] = dateString.split('-').map(Number)

  if (year === 0 || month === 0 || day === 0) return false

  const date = new Date(year, month - 1, day, 23, 59, 59, 999)

  return (
    date.getFullYear() === year &&
    date.getMonth() + 1 === month &&
    date.getDate() === day
  )
}

export function convertToUTC(dateString: string) {
  const [year, month, day] = dateString.split('-').map(Number)
  return new Date(year, month - 1, day, 23, 59, 59, 999)
}
