import { atom } from '~/observers/jotai'

import { PrintOptions } from './types'

export const printOptionsAtom = atom<PrintOptions>({
  cover: true,
  anamnesis: true,
  parQ: true,
  anthropometricMeasures: true,
  coronarianRisk: true,
  corporalComposition: true,
  hemodynamicMeasures: true,
  scheduling: true,
  aerobicExercisesPrescription: {
    self: true,
    startSession: 1,
    endSession: 112,
  },
  exercisesPrescription: {
    self: true,
    startSession: 1,
    endSession: 54,
  },
})
