import { Evaluation } from '../evalutation/types'
import {
  brunoBalkSchema,
  cooperSchema,
  dukeSchema,
  margariaSchema,
  medicalSchema,
  ownIndexSchema,
  rockportSchema,
  slopeSchema,
  ukkSchema,
} from './schema'

export function generateAerobicCapabilitiesReportBody(
  data: Evaluation | undefined,
): Array<[string, number]> {
  if (!data) return []

  const {
    brunoBalk,
    cooper,
    margaria,
    rockport,
    ukk,
    medical,
    aerobicCapabilitiesProtocol: protocol,
    ownIndex,
  } = data

  switch (protocol) {
    case 'Bruno Balk - Teste de Pista':
      return [
        ['Tempo (min)', brunoBalk.time ?? 0],
        ['Distância (m)', brunoBalk.distance ?? 0],
      ]
    case 'Cooper - Teste de Pista':
      return [['Distância (m)', cooper.distance ?? 0]]
    case 'Margaria - Teste de Pista':
      return [
        ['Tempo (min)', margaria.time ?? 0],
        ['Distância (m)', margaria.distance ?? 0],
      ]
    case 'Rockport - Teste de Caminhada':
      return [
        ['Tempo (min)', rockport.time ?? 0],
        ['Frequência Cardíaca Final (bpm)', rockport.heartRate ?? 0],
      ]
    case 'UKK - Pessoas Sedentárias':
      return [
        ['Tempo (min)', ukk.time ?? 0],
        ['Frequência Cardíaca Final (bpm)', ukk.heartRate ?? 0],
      ]
    case 'Médico - VO2 e Freq. Máxima':
      return [
        ['Consumo Máximo de VO2', medical.maxVO2Consumption ?? 0],
        ['Frequência Cardíaca Final (bpm)', medical.heartRate ?? 0],
      ]
    case 'Polar - OwnIndex':
      return [['Consumo Máximo de VO2', ownIndex.maxVO2Consumption ?? 0]]
    case 'Questionário de Duke':
    case 'Rampa Manual Individualizada':
    default:
      return []
  }
}

export function convertTimeToFloat(time: string) {
  const [, minutes, seconds] = time.split(':')
  let result = 0
  result += +minutes
  result += +seconds ? 0.5 : 0
  return result
}

export function validateAerobicCapabilities(data?: Evaluation) {
  if (!data?.aerobicCapabilitiesProtocol) return false

  switch (data.aerobicCapabilitiesProtocol) {
    case 'Bruno Balk - Teste de Pista':
      return brunoBalkSchema.safeParse(data.brunoBalk).success
    case 'Cooper - Teste de Pista':
      return cooperSchema.safeParse(data.cooper).success
    case 'Margaria - Teste de Pista':
      return margariaSchema.safeParse(data.margaria).success
    case 'Rockport - Teste de Caminhada':
      return rockportSchema.safeParse(data.rockport).success
    case 'UKK - Pessoas Sedentárias':
      return ukkSchema.safeParse(data.ukk).success
    case 'Médico - VO2 e Freq. Máxima':
      return medicalSchema.safeParse(data.medical).success
    case 'Questionário de Duke':
      return dukeSchema.safeParse(data.duke).success
    case 'Rampa Manual Individualizada':
      return slopeSchema.safeParse(data.slope).success
    default:
    case 'Polar - OwnIndex':
      return ownIndexSchema.safeParse(data.ownIndex).success
  }
}
