import { createContext } from 'react'

import {
  InvitationLink,
  ListInvitationLinksDTO,
} from '~/modules/invitationLinks/types'

type InvitationLinksContextData = {
  data?: ListInvitationLinksDTO
  addNewInvitationLink: (payload: InvitationLink) => void
}

export const invitationLinksContext = createContext<InvitationLinksContextData>(
  {} as InvitationLinksContextData,
)
