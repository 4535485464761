import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { documentNumberMask } from '~/components/TextInput/utils/mask'

import {
  LoginFormType,
  schema,
} from '../../../../../modules/authentication/schema'

type Props = {
  onSubmit: (payload: LoginFormType) => Promise<void>
}

export const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>({
    resolver: zodResolver(schema),
  })

  const handleSubmitForm = useCallback(
    (formData: LoginFormType) => {
      setLoading(true)
      onSubmit && onSubmit(formData)
      setLoading(false)
    },
    [onSubmit],
  )

  return (
    <form
      className='flex flex-col w-full gap-3'
      onSubmit={handleSubmit(handleSubmitForm)}
      autoComplete='off'
    >
      <Controller
        control={control}
        name='documentNumber'
        render={({ field }) => (
          <TextInput
            error={errors.documentNumber?.message}
            label='CPF'
            value={field.value}
            onChange={(e) => field.onChange(documentNumberMask(e.target.value))}
          />
        )}
      />
      <TextInput
        {...register('password')}
        autoCapitalize='off'
        error={errors.password?.message}
        label='Senha'
        type='password'
      />
      <Button disabled={loading} loading={loading}>
        Entrar
      </Button>
      <span className='ml-auto text-gpa-blue-500'>Esqueci minha senha</span>
    </form>
  )
}
