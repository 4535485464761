import { BrowserRouter } from 'react-router-dom'

import { authenticationAtom } from '~/modules/authentication/data'
import { useAtom } from '~/observers/jotai'

import { AuthenticatedRoutes } from './Authenticated'
import { OpenRoutes } from './Open'

export const AppRouter: React.FC = () => {
  const [session] = useAtom(authenticationAtom)

  return (
    <BrowserRouter>
      {session ? <AuthenticatedRoutes role={session.role} /> : <OpenRoutes />}
    </BrowserRouter>
  )
}
