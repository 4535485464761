import { PropsWithChildren, createContext, useCallback, useRef } from 'react'

import { Header } from './Header'
import { InfoBoards } from './InfoBoards'

type FormsLayoutContextData = {
  scrollToTop: () => void
}

export const formsLayoutContext = createContext<FormsLayoutContextData>(
  {} as FormsLayoutContextData,
)

export const FormsLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const scrollToTop = useCallback(() => {
    containerRef.current?.scrollTo({ top: 0 })
  }, [])

  return (
    <formsLayoutContext.Provider
      value={{
        scrollToTop,
      }}
    >
      <div className='flex gap-5 px-4 w-full'>
        <div
          ref={containerRef}
          className='flex min-w-[280px] overflow-y-auto flex-col gap-6 py-7'
        >
          <Header />
          {children}
          {/* <div className='min-h-7 flex'></div> */}
        </div>
        <InfoBoards />
      </div>
    </formsLayoutContext.Provider>
  )
}
