import zod from 'zod'

import { validateCPF } from '~/utils/validateCPF'

import { licenseOperationsEnum } from './variables'

export const createPersonalTrainerSchema = zod.object({
  name: zod
    .string()
    .nonempty('Campo obrigatório')
    .trim()
    .min(3, 'Deve ter ao menos 3 letras'),
  documentNumber: zod
    .string({ required_error: 'Campo obrigatório' })
    .nonempty('Campo obrigatório')
    .length(14, 'Número incompleto')
    .trim()
    .transform((value: string) => value.replace(/\D/g, ''))
    .refine((value) => validateCPF(value), { message: 'CPF inválido' }),
  email: zod.string().email('Deve ser um e-mail válido').trim(),
  maximumNumberOfCustomers: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .gt(0, 'Valor não pode ser nulo ou negativo'),
  password: zod
    .string({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(8, 'Deve ter ao menos 8 caracteres'),
})

export const updatePersonalTrainerAdminSchema = zod.object({
  name: zod
    .string()
    .nonempty('Campo obrigatório')
    .trim()
    .min(3, 'Deve ter ao menos 3 letras'),
  documentNumber: zod
    .string({ required_error: 'Campo obrigatório' })
    .nonempty('Campo obrigatório')
    .length(14, 'Número incompleto')
    .trim()
    .transform((value: string) => value.replaceAll(/\D/g, ''))
    .refine((value) => validateCPF(value), { message: 'CPF inválido' }),
  email: zod.string().email('Deve ser um e-mail válido').trim(),
  password: zod
    .string({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(8, 'Deve ter ao menos 8 caracteres')
    .optional()
    .or(zod.literal('')),
})

export const createPersonalTrainerLicenseChangeSchema = zod.object({
  operation: zod.enum(licenseOperationsEnum, {
    errorMap: () => {
      return { message: 'Campo Obrigatório' }
    },
  }),
  amountChanged: zod
    .number({ invalid_type_error: 'Deve ser um número' })
    .min(1, 'Valor não pode ser negativo ou nulo'),
})

export type CreatePersonalTrainerDTO = zod.infer<
  typeof createPersonalTrainerSchema
>

export type UpdatePersonalTrainerAdminDTO = zod.infer<
  typeof updatePersonalTrainerAdminSchema
>

export type PersonalTrainerLicenseChangeDTO = zod.infer<
  typeof createPersonalTrainerLicenseChangeSchema
>
