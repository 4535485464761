import { useCallback, useMemo, useState } from 'react'

export const RadioButtonArray: React.FC<RadioButtonArrayProps> = ({
  containerClassName = '',
  options,
  onChange,
  value,
}) => {
  const keyId = useMemo(() => Math.random(), [])
  const [currentValue, setCurrentValue] = useState<any>(value)

  const handleChange = useCallback(
    (value: any) => {
      setCurrentValue(value)
      onChange && onChange(value)
    },
    [onChange],
  )

  return (
    <div
      className={`${containerClassName ? containerClassName : 'flex  gap-4'}`}
    >
      {options.map((item, index) => (
        <button
          key={`${keyId}-${index}`}
          type='button'
          onClick={() => handleChange(item.value)}
          className='flex gap-2'
        >
          <div className='relative flex w-[25px] h-[25px] border focus-within:border-2 border-gpa-blue-500 justify-center items-center rounded-[15px]'>
            {currentValue === item.value ? (
              <div className='w-[15px] h-[15px] rounded-full bg-gpa-blue-500' />
            ) : null}
          </div>
          {item.label}
        </button>
      ))}
    </div>
  )
}
