import { useCallback, useMemo, useState } from 'react'
import { FiX } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { SelectInput } from '~/components/SelectInput'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  patchEvaluation,
  patchEvaluationAtom,
} from '~/modules/evalutation/services'
import { PeriodizedSeries } from '~/modules/evalutation/types'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import { usePeriodizedSeries } from '~/modules/standardizedSeries/usePeriodizedSeries'
import { useAtom } from '~/observers/jotai'
import { EvaluationRouteParams } from '~/routes/types'
import { Option } from '~/types/types'

type Props = {
  visible: boolean
  onClose: () => void
  data: PeriodizedSeries
}

type TabValues = 'exercise' | 'standardized'

export const ExercisesModal: React.FC<Props> = ({ visible, onClose, data }) => {
  const [personalTrainerExercises] = useAtom(personalTrainerExercisesAtom)
  const [evaluation] = useAtom(evaluationAtom)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const { addExerciseToSeries, addStandardizedExercisesToSeries } =
    usePeriodizedSeries(evaluation)

  const [loading, setLoading] = useState<boolean>(false)
  const [tab, setTab] = useState<TabValues>('exercise')
  const [strengthGroup, setStrengthGroup] = useState<string>('')
  const [standardizedGroup, setStandardizedGroup] = useState<string>('')

  console.log(strengthGroup, standardizedGroup)

  const strengthExercisesOptions = useMemo<Option<string>[]>(
    () =>
      !personalTrainerExercises?.strengthExercises
        ? []
        : personalTrainerExercises?.strengthExercises.map<Option<string>>(
            (e) => ({
              label: e.groupName,
              value: e.groupName,
            }),
          ),
    [personalTrainerExercises],
  )
  const standardizedExercisesOptions = useMemo<Option<string>[]>(
    () =>
      !personalTrainerExercises?.standardizedSeries
        ? []
        : personalTrainerExercises?.standardizedSeries.map<Option<string>>(
            (e) => ({
              label: e.groupName,
              value: e.groupName,
            }),
          ),
    [personalTrainerExercises],
  )

  const strengthExercises = useMemo(
    () =>
      personalTrainerExercises?.strengthExercises.find(
        (s) => s.groupName === strengthGroup,
      )?.exercises ?? [],
    [strengthGroup, personalTrainerExercises],
  )

  const standardizedExercises = useMemo(
    () =>
      personalTrainerExercises?.standardizedSeries.find(
        (s) => s.groupName === standardizedGroup,
      )?.exercises ?? [],
    [standardizedGroup, personalTrainerExercises],
  )

  const handleClearAll = useCallback(() => {
    data.exercises = []
    patchEvaluationAtom({
      periodizedSeries: evaluationAtom.get()?.periodizedSeries ?? [],
    })
  }, [data])

  const handleAddStandardizedSeries = useCallback(async () => {
    if (!student_id || !evaluation_id) return
    setLoading(true)
    addStandardizedExercisesToSeries(data, standardizedExercises)
    patchEvaluation(student_id, evaluation_id, {
      periodizedSeries: evaluationAtom.get()?.periodizedSeries,
    }).then(() => {
      setLoading(false)
      onClose()
    })
  }, [
    standardizedExercises,
    data,
    addStandardizedExercisesToSeries,
    student_id,
    evaluation_id,
    onClose,
  ])

  const handleSavePeriodizedSeries = useCallback(() => {
    const periodizedSeries = evaluationAtom.get()?.periodizedSeries
    if (!periodizedSeries || !evaluation_id || !student_id) return

    patchEvaluation(student_id, evaluation_id, { periodizedSeries }).then(
      (data) => {
        data && onClose()
      },
    )
  }, [evaluation_id, student_id, onClose])

  const handleAddExerciseToSeries = useCallback(
    async (exercise: string) => {
      const periodizedSeries = addExerciseToSeries(exercise, data)
      if (periodizedSeries) {
        patchEvaluationAtom({ periodizedSeries })
      }
    },
    [addExerciseToSeries, data],
  )

  const handleRemoveExerciseFromSeries = useCallback(
    async (index: number) => {
      data.exercises.splice(index, 1)

      patchEvaluationAtom({
        periodizedSeries: evaluationAtom.get()?.periodizedSeries,
      })
    },
    [data],
  )

  return (
    <Modal visible={visible}>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-between'>
          <h2 className='text-xl font-medium'>Adicionar Exercícios</h2>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={onClose}
          >
            <FiX size={24} />
          </button>
        </div>

        <div className='flex gap-4'>
          <button
            data-active={tab === 'exercise'}
            className='data-[active=true]:font-semibold data-[active=true]:bg-gpa-blue-50 px-2 py-1 rounded-lg'
            onClick={() => setTab('exercise')}
          >
            Exercícios
          </button>
          <button
            data-active={tab === 'standardized'}
            className='data-[active=true]:font-semibold data-[active=true]:bg-gpa-blue-50 px-2 py-1 rounded-lg'
            onClick={() => setTab('standardized')}
          >
            Séries Padronizadas
          </button>
        </div>

        {tab === 'exercise' ? (
          <div key='strength' className='flex flex-col gap-3'>
            <div>
              <p>
                Selecione o grupo de exercícios para adicionar suas atividades
              </p>
              <SelectInput
                options={strengthExercisesOptions}
                value={strengthGroup}
                onChange={setStrengthGroup}
              />
            </div>
            <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
              {strengthExercises.map((e, i) => (
                <ExerciseTag
                  key={`exercise-${data.letter}-${i}`}
                  exercise={e}
                  onAdd={() => handleAddExerciseToSeries(e)}
                />
              ))}
            </div>

            <p>
              Exercícios desta série (clique nos botões para removê-los da
              série)
            </p>
            <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
              {data.exercises.map((e, i) => (
                <ExerciseTag
                  key={`exercise-series-${i}-${data.letter}`}
                  exercise={e.name}
                  onDelete={() => handleRemoveExerciseFromSeries(i)}
                />
              ))}
            </div>

            <div className='flex justify-between'>
              <Button
                className='bg-gpa-red enabled:active:bg-red-500'
                onClick={handleClearAll}
              >
                Limpar tudo
              </Button>
              <Button onClick={handleSavePeriodizedSeries}>Salvar</Button>
            </div>
          </div>
        ) : (
          <div key='standardized' className='flex flex-col gap-3'>
            <div>
              <p>
                Selecione a série padronizada para adicionar suas atividades
              </p>
              <SelectInput
                options={standardizedExercisesOptions}
                value={standardizedGroup}
                onChange={setStandardizedGroup}
              />
            </div>
            <div>
              <p className='mb-3'>
                Exercícios presentes nesta série padronizada (Ao adicionar uma
                série padronizada, todos seus exercícios serão transferidos e
                substituirão os atuais nesta série de exercícios &quot;Serie{' '}
                {data.letter}&quot;
              </p>
              <div className='grid grid-cols-[repeat(auto-fit,_minmax(_180px,auto))] gap-3 min-h-[80px] bg-slate-100 p-3 rounded-lg max-h-[300px] overflow-y-auto items-center'>
                {standardizedExercises?.map((e, i) => (
                  <p
                    className='bg-blue-100 p-2 rounded-lg text-center'
                    key={`exercise-series-${i}-${e.replace(' ', '_')}`}
                  >
                    {e}
                  </p>
                ))}
              </div>
            </div>

            <div className='flex justify-between'>
              <Button
                className='bg-gpa-red enabled:active:bg-red-500'
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                loading={loading}
                onClick={handleAddStandardizedSeries}
              >
                Adicionar Exercícios
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
