import { useCallback, useState } from 'react'

import { Button } from '~/components/Button'

import { ActivitiesModal } from './ActivitiesModal'

export const ActivitiesManager: React.FC = () => {
  const [showAddActivityModal, setShowAddActivityModal] =
    useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowAddActivityModal((state) => !state)
  }, [])

  return (
    <div className='flex flex-col gap-2 bg-gpa-blue-50 rounded-lg p-3'>
      <h2 className='text-xl font-semibold'>Atividades para a semana</h2>
      <div className='flex flex-wrap gap-2 justify-between items-center'>
        <p>
          Aqui você pode gerenciar suas atividades na semana. Clique no botão
          &quot;Gerenciar&quot;
        </p>
        <Button onClick={handleToggleModal}>Gerenciar</Button>
      </div>

      <ActivitiesModal
        visible={showAddActivityModal}
        onClose={handleToggleModal}
      />
    </div>
  )
}
