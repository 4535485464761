import { useCallback, useState } from 'react'

import { Button } from '~/components/Button'
import {
  patchPersonalTrainerExercises,
  removeStandardizedSeries,
} from '~/modules/personalTrainerExercises/services'

type Props = {
  group: string | undefined
  onDelete: () => void
}

export const DeleteStandardizedSeriesButton: React.FC<Props> = ({
  group,
  onDelete,
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleConfirmDeleteSeries = useCallback(async () => {
    if (!group) return

    const standardizedSeries = removeStandardizedSeries(group)
    if (standardizedSeries) {
      setLoading(true)
      patchPersonalTrainerExercises({ standardizedSeries }).then(() => {
        setLoading(false)
        onDelete()
      })
    }
  }, [group, onDelete])

  return (
    <Button
      disabled={loading}
      loading={loading}
      color='error'
      onClick={handleConfirmDeleteSeries}
    >
      Apagar {group}
    </Button>
  )
}
