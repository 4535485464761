import zod from 'zod'

import { validateCPF } from '~/utils/validateCPF'

export const updatePersonalTrainerSchema = zod
  .object({
    name: zod.string().nonempty('Campo obrigatório').trim(),
    password: zod
      .string()
      .trim()
      .optional()
      .transform((data) => (!data?.length ? undefined : data))
      .refine((data) => !(data?.length && data.length < 8), {
        message: 'Deve ter no mínimo 8 caracteres',
      }),
    confirmPassword: zod
      .string()
      .trim()
      .nullish()
      .optional()
      .transform((data) => (!data?.length ? undefined : data))
      .refine((data) => !(data?.length && data.length < 8), {
        message: 'Deve ter no mínimo 8 caracteres',
      }),
    documentNumber: zod
      .string()
      .nonempty('Campo obrigatório')
      .length(14, 'Número incompleto')
      .trim()
      .transform((value: string) => value.replace(/\D/g, ''))
      .refine((value) => validateCPF(value), { message: 'CPF inválido' }),
    email: zod.string().email('Deve ser um e-mail válido').trim(),
  })
  .strict()
  .refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não estão iguais',
    path: ['confirmPassword'],
  })

export const registerPersonalTrainerSchema = zod
  .object({
    name: zod.string().nonempty('Campo obrigatório').trim(),
    registrationLink: zod.string().uuid(),
    password: zod
      .string()
      .nonempty('Campo obrigatório')
      .trim()
      .transform((data) => (!data?.length ? undefined : data))
      .refine((data) => !(data?.length && data.length < 8), {
        message: 'Deve ter no mínimo 8 caracteres',
      }),
    confirmPassword: zod
      .string()
      .nonempty('Campo obrigatório')
      .trim()
      .nullish()
      .transform((data) => (!data?.length ? undefined : data))
      .refine((data) => !(data?.length && data.length < 8), {
        message: 'Deve ter no mínimo 8 caracteres',
      }),
    documentNumber: zod
      .string()
      .nonempty('Campo obrigatório')
      .length(14, 'Número incompleto')
      .trim()
      .transform((value: string) => value.replace(/\D/g, ''))
      .refine((value) => validateCPF(value), { message: 'CPF inválido' }),
    email: zod.string().email('Deve ser um e-mail válido').trim(),
  })
  .strict()
  .refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não estão iguais',
    path: ['confirm_password'],
  })

export type UpdatePersonalTrainerDTO = zod.infer<
  typeof updatePersonalTrainerSchema
>

export type RegisterPersonalTrainerDTO = zod.infer<
  typeof registerPersonalTrainerSchema
>
