import { evaluationAtom } from '../evalutation/data'
import { Evaluation } from '../evalutation/types'
import { corporalEvaluationImageAtom } from './data'
import {
  coteWilmoreSchema,
  directSchema,
  faulknerSchema,
  penroeNelsonFischerSchema,
  pollockJacksonSevenfoldSchema,
  pollockJacksonTrifoldSchema,
  wetmanColSchema,
} from './schemas'

export function generateReportBody(): Array<[string, number]> {
  const data = evaluationAtom.get()
  if (!data) return []

  const {
    corporalEvaluationProtocol: protocol,
    direct,
    subscapular,
    triceptal,
    suprailiac,
    abdominal,
    abdomen,
    rightFist,
    hip,
    chest,
    medialThigh,
    axilla,
  } = data

  switch (protocol) {
    case 'Direto':
      return [['Percentual de Gordura', direct]]
    case 'Faulkner':
      return [
        ['Subscapular (mm)', subscapular.reduce((p, c) => p + c, 0) / 3],
        ['Tríceps (mm)', triceptal.reduce((p, c) => p + c, 0) / 3],
        ['Supra-ilíaca (mm)', suprailiac.reduce((p, c) => p + c, 0) / 3],
        ['Abdominal (mm)', abdominal.reduce((p, c) => p + c, 0) / 3],
      ]
    case 'Perímetros Coté e Wilmore Masculino':
      return [
        ['Abdômen (cm)', abdomen],
        ['Punho Direito (cm)', rightFist],
      ]
    case 'Perímetros Penroe, Nelson e Fisher (1985) Feminino':
      return [
        ['Abdômen (cm)', abdomen],
        ['Quadril (cm)', hip],
      ]
    case 'Pollock & Jackson 3 Dobras':
      return [
        ['Peitoral (mm)', chest.reduce((p, c) => p + c, 0) / 3],
        ['Abdominal (mm)', abdominal.reduce((p, c) => p + c, 0) / 3],
        ['Coxa Medial (mm)', medialThigh.reduce((p, c) => p + c, 0) / 3],
      ]
    case 'Pollock & Jackson 7 Dobras':
      return [
        ['Subscapular (mm)', subscapular.reduce((p, c) => p + c, 0) / 3],
        ['Tríceps (mm)', triceptal.reduce((p, c) => p + c, 0) / 3],
        ['Peitoral (mm)', chest.reduce((p, c) => p + c, 0) / 3],
        ['Axila Média (mm)', axilla.reduce((p, c) => p + c, 0) / 3],
        ['Supra-ilíaca (mm)', suprailiac.reduce((p, c) => p + c, 0) / 3],
        ['Abdominal (mm)', abdominal.reduce((p, c) => p + c, 0) / 3],
        ['Coxa Medial (mm)', medialThigh.reduce((p, c) => p + c, 0) / 3],
      ]
    case 'Wetman e col., para pessoas obesas':
    default:
      return [['Abdômen (cm)', abdomen]]
  }
}

export function setCorporalEvaluationImageIndex(index: number) {
  corporalEvaluationImageAtom.set(index)
}

export function validateCorporalEvaluation(data?: Evaluation) {
  if (!data?.corporalEvaluationProtocol) return false

  switch (data.corporalEvaluationProtocol) {
    case 'Pollock & Jackson 3 Dobras':
      return pollockJacksonTrifoldSchema.safeParse(data).success
    case 'Pollock & Jackson 7 Dobras':
      return pollockJacksonSevenfoldSchema.safeParse(data).success
    case 'Wetman e col., para pessoas obesas':
      return wetmanColSchema.safeParse(data).success
    case 'Faulkner':
      return faulknerSchema.safeParse(data).success
    case 'Perímetros Coté e Wilmore Masculino':
      return coteWilmoreSchema.safeParse(data).success
    case 'Perímetros Penroe, Nelson e Fisher (1985) Feminino':
      return penroeNelsonFischerSchema.safeParse(data).success
    case 'Direto':
    default:
      return directSchema.safeParse(data).success
  }
}
