import { motion } from 'framer-motion'
import { useCallback, useContext, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { MaskedInput } from '~/components/TextInput'
import { validateCorporalEvaluation } from '~/modules/corporalEvaluation/services'
import { CorporalEvaluation } from '~/modules/corporalEvaluation/types'
import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  patchEvaluation,
  patchEvaluationAtom,
} from '~/modules/evalutation/services'
import { Evaluation } from '~/modules/evalutation/types'
import { insertToast } from '~/modules/toasts/services'
import { useAtom } from '~/observers/jotai'
import { antropometryRoute, hemodynamicMeasuresRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import { Chart } from './Chart'
import { ImageGuide } from './components/ImageGuide'
import { Protocols } from './protocols'
import { Table } from './Table'

type Tabs = 'Tabela' | 'Gráfico'

export const CorporalEvaluationForm: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const [evaluation] = useAtom(evaluationAtom)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()

  const {
    proposedFatPercentage,
    sugerePercentGordura,
    classificacaoPercentGorduraIndex,
    linhaNaTabelaPercentualdeGordura,
  } = useCorporalEvaluation(evaluation)
  const [loading, setLoading] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<Tabs>('Tabela')

  const [suggestedValue, setSuggestedvalue] = useState<number>(() =>
    proposedFatPercentage ? +proposedFatPercentage?.toFixed(1) : 0,
  )

  const handleNavClick = useCallback((id: Tabs) => {
    setCurrentTab(id)
  }, [])

  const handleUpdateForm = useCallback(async () => {
    if (!student_id || !evaluation_id) return
    setLoading(true)

    let data: Partial<Evaluation> = {}
    if (evaluationAtom.get()) {
      data = { ...evaluationAtom.get() }
    }

    if (!validateCorporalEvaluation(evaluationAtom.get())) {
      insertToast({
        duration: 7,
        message: 'Por favor, termine de preencher o formulário',
        title: 'Formulário incompleto',
        type: 'error',
      })
      setLoading(false)
      return
    }

    patchEvaluation(student_id, evaluation_id, {
      corporalEvaluationProtocol: data.corporalEvaluationProtocol,
      abdominal: data.abdominal,
      axilla: data.axilla,
      chest: data.chest,
      direct: data.direct,
      proposedFatPercentage: data.proposedFatPercentage,
      weightAdjustment: data.weightAdjustment,
      medialThigh: data.medialThigh,
      suprailiac: data.suprailiac,
      triceptal: data.triceptal,
      subscapular: data.subscapular,
      abdomen: data.abdomen,
    } as CorporalEvaluation).then((success) => {
      if (success) {
        scrollToTop()
        navigate(
          hemodynamicMeasuresRoute
            .replace(':student_id', student_id)
            .replace(':evaluation_id', evaluation_id),
          { replace: true },
        )
      }
      setLoading(false)
    })
  }, [student_id, evaluation_id, navigate, scrollToTop])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      antropometryRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <div className='flex gpa-xs:flex-col'>
        <QuestionFrame isValid={false} title='Avaliação Corporal'>
          <div className='relative flex items-start gap-4 h-full'>
            <div className='sticky top-5 w-[250px] bg-slate-400 hidden lg:flex'>
              <ImageGuide />
            </div>

            <Protocols data={evaluation} />
          </div>
        </QuestionFrame>
      </div>

      <div className='border-2 border-gpa-gray-500 rounded-lg p-2 overflow-x-auto w-full'>
        <h1 className='font-bold mb-3'>
          Classificação do Percentual de Gordura
        </h1>

        <div className='overflow-x-auto rounded-lg overflow-hidden mb-3'>
          <table className='text-left table-fixed shrink-0 rounded-lg w-full min-w-[700px]'>
            <thead className='bg-gpa-blue-500 text-white'>
              <tr className='w-36'>
                <th className='p-2.5'>Superior</th>
                <th>Excelente</th>
                <th>Bom</th>
                <th>Rezoável</th>
                <th>Gordo</th>
                <th className='p-2.5'>Muito Gordo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {linhaNaTabelaPercentualdeGordura.map((cell, index) => (
                  <td
                    className={
                      index === classificacaoPercentGorduraIndex - 1
                        ? 'bg-cyan-400 px-2'
                        : 'bg-slate-100' + ' p-2'
                    }
                    key={`${cell[1]}`}
                  >
                    {cell[0]}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className='flex flex-col gap-3 '>
          <div className='flex gap-2 justify-between flex-wrap'>
            <div className='flex flex-1 shrink-0 min-w-60 justify-center'>
              <div className='self-start mr-2'>
                <MaskedInput
                  mode='horizontal'
                  inputMode='numeric'
                  placeholder='0,0'
                  label='Sugerido'
                  value={suggestedValue}
                  onChange={(e) => {
                    setSuggestedvalue(e)
                    patchEvaluationAtom({
                      proposedFatPercentage: e,
                    })
                  }}
                />
              </div>
              <Button
                onClick={() => {
                  setSuggestedvalue(+sugerePercentGordura.toFixed(2))
                  patchEvaluationAtom({
                    proposedFatPercentage: +sugerePercentGordura.toFixed(2),
                  })
                }}
              >
                Sugerir
              </Button>
            </div>
            <div className='self-start flex-1 shrink-0 min-w-60 justify-center'>
              <MaskedInput
                mode='horizontal'
                inputMode='numeric'
                placeholder='0,0'
                label='Aumentar MCM (em Kg)'
                onChange={(e) =>
                  patchEvaluationAtom({
                    weightAdjustment: e,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-3'>
        <nav className='flex max-w-[200px] p-1 bg-slate-100 gap-2 rounded-xl overflow-hidden'>
          {(['Tabela', 'Gráfico'] as Tabs[]).map((item) => (
            <div
              key={`navtab-${item}`}
              onClick={() => handleNavClick(item)}
              className='relative px-2 py-1 text-center w-full rounded-lg cursor-pointer'
            >
              <span className='relative z-10'>{item}</span>
              {item === currentTab ? (
                <motion.div
                  layoutId='nav-marker'
                  transition={{ type: 'spring', bounce: 0.1, duration: 0.4 }}
                  className='absolute inset-0 w-full rounded-lg bg-white'
                />
              ) : null}
            </div>
          ))}
        </nav>

        <div className='h-64'>
          {currentTab === 'Gráfico' ? (
            <Chart data={evaluation} />
          ) : (
            <Table data={evaluation} />
          )}
        </div>
      </div>

      <div className='flex justify-between'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleUpdateForm}
        >
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
