import { useCallback, useContext, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { SelectInput } from '~/components/SelectInput'
import { MaskedInput } from '~/components/TextInput'
import {
  AerobicPrescriptionDTO,
  aerobicPrescriptionSchema,
} from '~/modules/aerobicPrescription/schema'
import { useAerobicPrescription } from '~/modules/aerobicPrescription/useAerobicPrescription'
import {
  objectiveOptions,
  tableData,
  targetZoneOptions,
} from '~/modules/aerobicPrescription/variables'
import { evaluationAtom } from '~/modules/evalutation/data'
import {
  patchEvaluation,
  patchEvaluationAtom,
} from '~/modules/evalutation/services'
import { Evaluation } from '~/modules/evalutation/types'
import { insertToast } from '~/modules/toasts/services'
import { useAtom } from '~/observers/jotai'
import { aerobicCapabilitiesRoute, schedulerRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import { PromptModal } from './PromptModal'

export const AerobicPrescription: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const [evaluation] = useAtom(evaluationAtom)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [loading, setLoading] = useState<boolean>(false)

  const {
    intensityOptions,
    intensityZoneMin,
    intensityZoneMax,
    highlightedCellsIndex,
    minorTrainingZone,
    majorTrainingZone,
    minorTrainingZoneInWater,
    majorTrainingZoneInWater,
    trainingVO2,
    trainingMETS,
    initialSessionDuration,
    minimumKgcalForTrainging,
    energyPerMinute,
  } = useAerobicPrescription(evaluation)

  const handleSave = useCallback(() => {
    if (!student_id || !evaluation_id) return
    setLoading(true)

    if (!aerobicPrescriptionSchema.safeParse(evaluationAtom.get()).success) {
      insertToast({
        duration: 7,
        message: 'Por favor, termine de preencher o formulário',
        title: 'Formulário incompleto',
        type: 'error',
      })
      setLoading(false)
      return
    }

    let data: Partial<Evaluation> = {}
    if (evaluationAtom.get()) {
      data = { ...evaluationAtom.get() }
    }

    patchEvaluation(student_id, evaluation_id, {
      hasAerobicPrescription: data.hasAerobicPrescription,
      intensity: data.intensity,
      objectiveIndex: data.objectiveIndex,
      proposedEnergy: data.proposedEnergy,
      targetZone: data.targetZone,
    } as AerobicPrescriptionDTO).then((success) => {
      setLoading(false)
      if (!success) return
      scrollToTop()
      navigate(
        schedulerRoute
          .replace(':student_id', student_id)
          .replace(':evaluation_id', evaluation_id),
        { replace: true },
      )
    })
  }, [evaluation_id, student_id, navigate, scrollToTop])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      aerobicCapabilitiesRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <>
      <div className='flex flex-wrap px-6 py-9 gap-6 rounded-2xl bg-white'>
        <div className='grid gpa-xl:grid-cols-2 w-full gap-4'>
          <div className='w-full '>
            <div className='flex flex-col w-full'>
              <SelectInput
                label='Cálculo da Zona Alvo'
                options={targetZoneOptions}
                value={evaluation?.targetZone}
                onChange={(v) =>
                  patchEvaluationAtom({
                    targetZone: v,
                  })
                }
              />
              <SelectInput
                label='Selecione o objetivo do trabalho aeróbico'
                options={objectiveOptions}
                value={evaluation?.objectiveIndex}
                onChange={(v) =>
                  patchEvaluationAtom({
                    objectiveIndex: v,
                  })
                }
              />
              <SelectInput
                label={`Intensidade (${intensityZoneMin}-${intensityZoneMax})`}
                options={intensityOptions}
                value={evaluation?.intensity}
                onChange={(v) => patchEvaluationAtom({ intensity: v })}
              />
            </div>
            <div>
              <div className='flex gap-4 my-4 flex-wrap'>
                <p className='flex-1 p-1 font-bold text-center whitespace-nowrap text-white bg-red-700 rounded-md'>
                  Zona Alvo no solo {minorTrainingZone} - {majorTrainingZone}
                </p>
                {evaluationAtom.get()?.hasInWaterPrescription &&
                evaluationAtom.get()?.restingHeartFrequencyInWater ? (
                  <p className='flex-1 p-1 font-bold text-center whitespace-nowrap text-white bg-gpa-blue-500 rounded-md'>
                    Zona Alvo na água {minorTrainingZoneInWater} -{' '}
                    {majorTrainingZoneInWater}
                  </p>
                ) : null}
              </div>

              <div className='grid grid-cols-[repeat(auto-fit,_minmax(_191px,auto))] gap-4'>
                <div className='flex flex-col gap-2 p-2 bg-slate-50 rounded-md border border-slate-100 '>
                  <p>VO2 de Treino</p>
                  <p className='font-bold'>{trainingVO2}</p>
                </div>
                <div className='flex flex-col gap-2 p-2 bg-slate-50 rounded-md border border-slate-100'>
                  <p>METS de Treino</p>
                  <p className='font-bold'>{trainingMETS}</p>
                </div>

                <div className='flex flex-col gap-4 p-2 bg-slate-50 rounded-md border border-slate-100'>
                  <p>Demanda energética mínima sugerida(Kcal)</p>
                  <MaskedInput
                    mask='float'
                    inputMode='numeric'
                    value={evaluation?.proposedEnergy}
                    containerClassName='bg-white'
                    onChange={(e) =>
                      patchEvaluationAtom({
                        proposedEnergy: e,
                      })
                    }
                  />
                </div>
                <div className='flex flex-col gap-2 p-2 bg-slate-50 rounded-md border border-slate-100'>
                  <p>Duração inicial da Sessão(Min)</p>
                  <p className='font-bold'>{initialSessionDuration}</p>
                </div>
                <div className='flex flex-col gap-2 p-2 bg-slate-50 rounded-md border border-slate-100'>
                  <p>Demanda energética proposta(Kcal)</p>
                  <p className='font-bold'>{minimumKgcalForTrainging}</p>
                </div>
                <div className='flex flex-col gap-2 p-2 bg-slate-50 rounded-md border border-slate-100'>
                  <p>Demanda energética proposta(Kcal/Min)</p>
                  <p className='font-bold'>{energyPerMinute}</p>
                </div>
              </div>
            </div>
          </div>

          <div className='flex-1 p-4 bg-gray-50 rounded-md border overflow-x-auto w-full '>
            <p className='font-bold'>Percepção Subjetiva de Esforço</p>
            <table className='table-fixed w-full min-w-[400px]'>
              <thead>
                <tr>
                  <th className='p-2' />
                  <th className='p-2'>Borg</th>
                  <th className='p-2'>Adaptada</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((cellData, index) => (
                  <tr key={`cellData-${index}`}>
                    <td className='p-2 border'>{cellData[0]}</td>
                    <td
                      className={`p-2 border ${
                        highlightedCellsIndex?.includes(index)
                          ? 'bg-cyan-300'
                          : ''
                      }`}
                    >
                      {cellData[1]}
                    </td>
                    <td
                      className={`p-2 border ${
                        highlightedCellsIndex?.includes(index)
                          ? 'bg-cyan-300'
                          : ''
                      }`}
                    >
                      {cellData[2]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='flex justify-between w-full'>
          <Button
            type='button'
            loading={loading}
            disabled={loading}
            onClick={handleGoBack}
          >
            <FiChevronLeft size={24} />
            <span className='mb-1'>Voltar</span>
          </Button>
          <Button
            loadingMessage='Salvando...'
            loading={loading}
            disabled={loading}
            onClick={handleSave}
          >
            <span className='mb-1'>Salvar</span>
            <FiChevronRight size={24} />
          </Button>
        </div>
      </div>
      <PromptModal />
    </>
  )
}
