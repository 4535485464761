import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { Evaluation } from '~/modules/evalutation/types'

type Props = {
  data?: Evaluation
}

export const Table: React.FC<Props> = ({ data }) => {
  const {
    percentualDeGorduraMedido,
    pesoGordo,
    MCMPercent,
    CalcPesoSugerido,
    MCMCarencia,
    MCMSugerido,
    MCM,
    MCMPercentSugerido,
    pesoGordoSugerido,
    pesoGordoExcedenteDoProposto,
    peso,
  } = useCorporalEvaluation(data)

  return (
    <div className='h-full overflow-x-auto w-full '>
      <table className='h-full table-fixed w-full text-left rounded-lg overflow-hidden min-w-[600px]'>
        <thead className='bg-gpa-blue-500 text-white'>
          <tr>
            <th></th>
            <th>Atual</th>
            <th></th>
            <th>Sugerido</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='px-4'>% de Gordura</td>
            <td>{percentualDeGorduraMedido.toFixed(1)}%</td>
            <td></td>
            <td>{data?.proposedFatPercentage}%</td>
          </tr>
          <tr className='even:bg-slate-100'>
            <td className='px-4'>% MCM</td>
            <td>{MCMPercent}%</td>
            <td></td>
            <td>{MCMPercentSugerido.toFixed(1)}%</td>
          </tr>
          <tr className='even:bg-slate-100'>
            <td className='px-4'>MCM Kg</td>
            <td>{MCM.toFixed(1)}kg</td>
            <td>
              <span className='text-gpa-blue-500 text-sm'>
                {MCMCarencia.toFixed(1)}kg (Carência Muscular)
              </span>
            </td>
            <td>{MCMSugerido}kg</td>
          </tr>
          <tr className='even:bg-slate-100'>
            <td className='px-4'>Peso Gordo</td>
            <td>{pesoGordo} Kg</td>
            <td>
              <span className='text-gpa-red text-sm'>
                {pesoGordoExcedenteDoProposto}Kg (Gordura excedente)
              </span>
            </td>
            <td>{pesoGordoSugerido}kg</td>
          </tr>
          <tr className='even:bg-slate-100'>
            <td className='px-4'>Peso (Kg)</td>
            <td>{peso?.toFixed(1)}kg</td>
            <td></td>
            <td>
              {CalcPesoSugerido}
              kg
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
