import { useCallback, useMemo, useState } from 'react'

import { ExerciseTag } from '~/components/ExerciseTag'
import { SelectInput } from '~/components/SelectInput'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import {
  addExerciseToStandardizedSeriesGroup,
  patchPersonalTrainerExercises,
  removeExerciseFromStandardizedSeriesGroup,
} from '~/modules/personalTrainerExercises/services'
import { useAtom } from '~/observers/jotai'
import { Option } from '~/types/types'

import { CreateExerciseForm } from './CreateExerciseForm'

type Props = {
  group: string | undefined
}

export const EditGroupForm: React.FC<Props> = ({ group }) => {
  const [personalTrainerExercises] = useAtom(personalTrainerExercisesAtom)
  const [baseGroup, setBaseGroup] = useState<string>('')

  const strengthExercisesOptions = useMemo<Option<string>[]>(
    () =>
      personalTrainerExercises?.strengthExercises.map<Option<string>>((e) => ({
        label: e.groupName,
        value: e.groupName,
      })) ?? [],
    [personalTrainerExercises],
  )

  const strengthExercises = useMemo(
    () =>
      personalTrainerExercises?.strengthExercises.find(
        (se) => se.groupName === baseGroup,
      )?.exercises ?? [],
    [baseGroup, personalTrainerExercises],
  )

  const standardizedSeriesExercises = useMemo(
    () =>
      personalTrainerExercises?.standardizedSeries.find(
        (se) => se.groupName === group,
      )?.exercises ?? [],
    [group, personalTrainerExercises],
  )

  // const exercisesToSelect = useMemo(() => {
  //   const exercisesFromGroup =
  //     data?.exercises.find((e) => e.groupName === selectedExerciseGroup)
  //       ?.exercises ?? []

  //   const exercisesFromSeries =
  //     data?.standardizedSeries.find((e) => e.groupName === group)?.exercises ??
  //     []

  //   return exercisesFromGroup.filter(
  //     (e) => !exercisesFromSeries.find((s) => s === e),
  //   )
  // }, [selectedExerciseGroup, data, group])

  // const exercisesInSeries = useMemo(
  //   () =>
  //     data?.standardizedSeries.find((e) => e.groupName === group)?.exercises ??
  //     [],
  //   [data, group],
  // )

  console.log(personalTrainerExercises?.standardizedSeries)

  const handleAddExerciseToGroup = useCallback(
    async (exercise: string) => {
      if (!group) return

      const standardizedSeries = addExerciseToStandardizedSeriesGroup(
        exercise,
        group,
      )
      if (standardizedSeries) {
        await patchPersonalTrainerExercises({ standardizedSeries })
      }
    },
    [group],
  )

  const handleRemoveExerciseToGroup = useCallback(
    async (exercise: string) => {
      if (!group) return

      const standardizedSeries = removeExerciseFromStandardizedSeriesGroup(
        exercise,
        group,
      )
      if (standardizedSeries) {
        await patchPersonalTrainerExercises({ standardizedSeries })
      }
    },
    [group],
  )

  return (
    <div className='flex flex-col'>
      <p>Selecione um grupo para adicionar atividades</p>
      <div className='flex flex-col gap-3'>
        <SelectInput
          options={strengthExercisesOptions}
          value={baseGroup}
          onChange={setBaseGroup}
        />
        <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
          {strengthExercises.map((e) => (
            <ExerciseTag
              key={`exercise-${e.replace(' ', '_')}`}
              onAdd={() => handleAddExerciseToGroup(e)}
              exercise={e}
            />
          ))}
        </div>

        <div>
          <p>Adicione um novo exercício para a série (opcional)</p>
          <CreateExerciseForm group={group} />
        </div>

        <p>Exercícios da série</p>
        <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
          {standardizedSeriesExercises.map((e) => (
            <ExerciseTag
              key={`exercise-${e.replace(' ', '_')}`}
              onDelete={() => handleRemoveExerciseToGroup(e)}
              exercise={e}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
