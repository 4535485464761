import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'
import { weekDaysOrder } from '~/utils/weekdays'

import { validateQuestionsSchema } from '../../../../components/formsContainer/components/anamnesisForm/components/generalForm/schema'
import { Question } from '../Questions'

export const GeneralReportPreview: React.FC = () => {
  const [data] = useAtom(evaluationAtom)

  return (
    <>
      <h1 className='text-3xl h-9 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        Geral
      </h1>

      <div className='flex flex-col overflow-y-auto gap-2.5'>
        {validateQuestionsSchema
          .pick({ goals: true })
          .safeParse({ goals: data?.goals }).success ? (
          <Question
            index='1)'
            title='Objetivos a serem alcançados'
            value={data?.goals ? data.goals.join(', ') : ''}
          />
        ) : null}

        {validateQuestionsSchema
          .pick({ presentPhysicalConditioning: true })
          .safeParse({
            presentPhysicalConditioning: data?.presentPhysicalConditioning,
          }).success ? (
          <Question
            index='2)'
            title='Condicionamento físico atual'
            value={
              data?.presentPhysicalConditioning
                ? data.presentPhysicalConditioning
                : ''
            }
          />
        ) : null}

        {validateQuestionsSchema
          .pick({ physicalConditioningTimePeriod: true })
          .safeParse({
            physicalConditioningTimePeriod:
              data?.physicalConditioningTimePeriod,
          }).success ? (
          <Question
            index='3)'
            title='Há quanto tempo?'
            value={`${data?.physicalConditioningTimePeriod?.quantity} ${data?.physicalConditioningTimePeriod?.period}`}
          />
        ) : null}

        {validateQuestionsSchema
          .pick({ availableDaysPerWeek: true })
          .safeParse({
            availableDaysPerWeek: data?.availableDaysPerWeek,
          }).success ? (
          <Question
            index='4)'
            title='Quantos dias disponíveis por semana?'
            value={data?.availableDaysPerWeek || ''}
          />
        ) : null}

        {/* 5th */}
        {validateQuestionsSchema
          .pick({ availableTime: true })
          .safeParse({ availableTime: data?.availableTime }).success ? (
          <Question
            index='5)'
            title='Tempo disponível?'
            value={data?.availableTime || ''}
          />
        ) : null}
        {/* 6th */}
        {validateQuestionsSchema
          .pick({ availableWeekDaysForPhysical: true })
          .safeParse({
            availableWeekDaysForPhysical: data?.availableWeekDaysForPhysical,
          }).success ? (
          <Question
            index='6)'
            title='Marque os dias disponíveis para prática de atividade físicas'
            value={weekDaysOrder
              .filter((d) => data?.availableWeekDaysForPhysical?.includes(d))
              .join(', ')}
          />
        ) : null}
        {/* 7th */}
        {validateQuestionsSchema.pick({ currentExercises: true }).safeParse({
          currentExercises: data?.currentExercises,
        }).success ? (
          <Question
            index='7)'
            title='Marque os exercícios físicos realizados atualmente'
            value={
              (data?.currentExercises?.exercises.join(', ') || '') +
              (data?.currentExercises?.other
                ? (data.currentExercises.exercises.length ? ', ' : '') +
                  data?.currentExercises.other
                : '')
            }
          />
        ) : null}
        {/* 8th */}
        {validateQuestionsSchema.pick({ exercisesIntensity: true }).safeParse({
          exercisesIntensity: data?.exercisesIntensity,
        }).success ? (
          <Question
            index='8)'
            title='Intensidade das (atividades acima)'
            value={data?.exercisesIntensity || ''}
          />
        ) : null}
        {/* 9th */}
        {validateQuestionsSchema.pick({ exercisesLocale: true }).safeParse({
          exercisesLocale: data?.exercisesLocale,
        }).success ? (
          <Question
            index='9)'
            title='Onde prefere fazer atividade física?'
            value={data?.exercisesLocale || ''}
          />
        ) : null}
        {/* 10th */}
        {validateQuestionsSchema.pick({ regularMeals: true }).safeParse({
          regularMeals: data?.regularMeals,
        }).success ? (
          <Question
            index='10)'
            title='Refeições normalmente realizadas no dia'
            value={data?.regularMeals?.join(', ') || ''}
          />
        ) : null}
        {/* 11th */}
        {validateQuestionsSchema.pick({ diet: true }).safeParse({
          diet: data?.diet,
        }).success ? (
          <Question
            index='11)'
            title='Algum tipo de dieta?'
            value={data?.diet || ''}
          />
        ) : null}
        {/* 12th */}
        {data?.suplements &&
        validateQuestionsSchema.pick({ suplements: true }).safeParse({
          suplements: data?.suplements,
        }).success ? (
          <Question
            index='12)'
            title='Suplementos alimentares em uso'
            value={data?.suplements || ''}
          />
        ) : null}
        {/* 13th */}
        {validateQuestionsSchema.pick({ effortTest: true }).safeParse({
          effortTest: data?.effortTest,
        }).success ? (
          <Question
            index='13)'
            title='Já fez teste de esforço?'
            value={data?.effortTest || ''}
          />
        ) : null}
        {/* 14th */}
        {validateQuestionsSchema.pick({ alcoholicDrinks: true }).safeParse({
          alcoholicDrinks: data?.alcoholicDrinks,
        }).success ? (
          <Question
            index='14)'
            title='Bebidas alcóolicas (Fonte AUDIT)'
            value={data?.alcoholicDrinks || ''}
          />
        ) : null}
        {/* 15th */}
        {validateQuestionsSchema.pick({ litersPerDay: true }).safeParse({
          litersPerDay: data?.litersPerDay,
        }).success ? (
          <Question
            index='15)'
            title='Ingere líquidos com frequência? (2 litros é o recomendado pelo "Guia alimentar para população brasileira" - Min. da Saúde)'
            value={data?.litersPerDay || ''}
          />
        ) : null}
        {/* 16th */}
        {validateQuestionsSchema.pick({ diabetes: true }).safeParse({
          diabetes: data?.diabetes,
        }).success ? (
          <Question
            index='16)'
            title='Diabetes?'
            value={data?.diabetes || ''}
          />
        ) : null}
        {/* 17th */}
        {validateQuestionsSchema.pick({ glicemyLevel: true }).safeParse({
          glicemyLevel: data?.glicemyLevel,
        }).success ? (
          <Question
            index='17)'
            title='Nível de glicemia em jejum (Fonte: Laboratório Delboni Auriemo)'
            value={data?.glicemyLevel || ''}
          />
        ) : null}
        {/* 18th */}
        {validateQuestionsSchema.pick({ cholesterolLevels: true }).safeParse({
          cholesterolLevels: data?.cholesterolLevels,
        }).success ? (
          <Question
            index='18)'
            title='Nível de colesterol total (Fonte Laboratório Delboni Auriemo)'
            value={data?.glicemyLevel || ''}
          />
        ) : null}
        {/* 19th */}
        {validateQuestionsSchema.pick({ triglyceridesLevels: true }).safeParse({
          triglyceridesLevels: data?.triglyceridesLevels,
        }).success ? (
          <Question
            index='19)'
            title='Nível de triglicerídeos (Fonte Laboratório Delboni Auriemo)'
            value={data?.triglyceridesLevels || ''}
          />
        ) : null}
        {/* 20th */}
        {validateQuestionsSchema.pick({ tabagism: true }).safeParse({
          tabagism: data?.tabagism,
        }).success ? (
          <Question
            index='20)'
            title='Tabagismo'
            value={data?.tabagism || ''}
          />
        ) : null}
        {/* 21th */}
        {validateQuestionsSchema.pick({ diseases: true }).safeParse({
          diseases: data?.diseases,
        }).success ? (
          <Question
            index='21)'
            title='Doenças e queixas'
            value={
              data?.diseases?.diseases.join(', ') +
              (data?.diseases?.other
                ? (data?.diseases.diseases.length ? ', ' : '') +
                  data.diseases.other
                : '')
            }
          />
        ) : null}
        {/* 22th */}
        {validateQuestionsSchema.pick({ regularMedications: true }).safeParse({
          regularMedications: data?.regularMedications,
        }).success ? (
          <Question
            index='22)'
            title='Medicamentos utilizados regularmente'
            value={
              data?.regularMedications?.medications.join(', ') +
              (data?.regularMedications?.other
                ? (data.regularMedications.medications.length ? ', ' : '') +
                  data?.regularMedications.other
                : '')
            }
          />
        ) : null}
        {/* 23th */}
        {validateQuestionsSchema.pick({ jointMuscularDamage: true }).safeParse({
          jointMuscularDamage: data?.jointMuscularDamage,
        }).success ? (
          <Question
            index='23)'
            title='Lesões articulares ou musculares'
            value={data?.jointMuscularDamage?.join(', ') || ''}
          />
        ) : null}
        {/* 24th */}
        {validateQuestionsSchema.pick({ pedigree: true }).safeParse({
          pedigree: data?.pedigree,
        }).success ? (
          <Question
            index='24)'
            title='Histórico familiar'
            value={data?.pedigree?.join(', ') || ''}
          />
        ) : null}
        {/* 25th */}
        {data?.commentary &&
        validateQuestionsSchema.pick({ commentary: true }).safeParse({
          commentary: data?.commentary,
        }).success ? (
          <Question
            index='25)'
            title='Algo que deva ser levado em consideração'
            value={data?.commentary || ''}
          />
        ) : null}
      </div>
    </>
  )
}
