import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import {
  addStrengthExerciseToGroup,
  patchPersonalTrainerExercises,
} from '~/modules/personalTrainerExercises/services'
import { insertToast } from '~/modules/toasts/services'

import { FormType, schema } from './schema'

type Props = {
  exerciseGroup: string
}

export const NewExerciseForm: React.FC<Props> = ({ exerciseGroup }) => {
  const form = useForm<FormType>({
    defaultValues: {
      exerciseGroup,
    },
    resolver: zodResolver(schema),
  })

  const onFormSubmit = useCallback(
    async (payload: FormType) => {
      const strengthExercises = addStrengthExerciseToGroup(
        payload.exercise,
        payload.exerciseGroup,
      )

      if (strengthExercises) {
        await patchPersonalTrainerExercises({ strengthExercises }).then(
          (data) => {
            data && form.resetField('exercise')
          },
        )
      } else {
        insertToast({
          duration: 3,
          message: 'Exercício pode já estar cadastrado nesta série',
          title: 'Atenção!',
          type: 'warn',
        })
      }
    },
    [form],
  )

  return (
    <form onSubmit={form.handleSubmit(onFormSubmit)}>
      <div className='flex flex-wrap items-center gap-3'>
        <TextInput
          {...form.register('exercise')}
          label='Caso queira, você também pode adicionar novos exercício a esta lista'
          placeholder='Novo Exercício'
          error={form.formState.errors.exercise?.message}
        />
        <div className='mt-5'>
          <Button
            loading={form.formState.isSubmitting}
            disabled={form.formState.isSubmitting}
          >
            Adicionar
          </Button>
        </div>
      </div>
    </form>
  )
}
