import { useCallback, useState } from 'react'
import { FiPlus, FiX } from 'react-icons/fi'
import { IoIosArrowUp, IoIosArrowDown, IoIosClose } from 'react-icons/io'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { personalTrainerExercisesAtom } from '~/modules/personalTrainerExercises/data'
import {
  moveActivityInWeekDay,
  removeActivityFromWeekDay,
  toggleActivityInWeek,
} from '~/modules/personalTrainerExercises/services'

type Props = {
  dayIndex: number
  data: string[] | null
}

const weekadays = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
]

export const DayActivitiesList: React.FC<Props> = ({ data, dayIndex }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleToggleActivity = useCallback(
    (activity: string) => {
      toggleActivityInWeek(dayIndex, activity)
      setShowModal(false)
    },
    [dayIndex],
  )

  return (
    <div className='flex flex-col gap-2 border border-slate-200 rounded-md p-3 grow-0 w-full'>
      <p className='font-bold'>{weekadays[dayIndex]}</p>
      <ul className='flex flex-col gap-3 overflow-y-scroll bg-slate-100 rounded-md p-2 h-48'>
        {data?.map((activity, index) => (
          <li
            key={`activity-${dayIndex}-${index}`}
            className='flex bg-gpa-blue-50 p-1.5 rounded-md'
          >
            <span className='flex-1'>{activity}</span>
            <div className='flex gap-1'>
              {index !== 0 ? (
                <button
                  className='p-1 rounded-full bg-gpa-blue-500 active:bg-blue-500 h-6 w-6'
                  onClick={() => moveActivityInWeekDay('up', index, dayIndex)}
                >
                  <IoIosArrowUp className='text-white text-md' />
                </button>
              ) : null}
              {index + 1 !== data.length ? (
                <button
                  className='p-1 rounded-full bg-gpa-blue-500 h-6 w-6 active:bg-blue-500'
                  onClick={() => moveActivityInWeekDay('down', index, dayIndex)}
                >
                  <IoIosArrowDown className='text-white text-md' />
                </button>
              ) : null}

              <button
                className='flex justify-center items-center rounded-full bg-gpa-red h-6 w-6 active:bg-red-600'
                onClick={() => removeActivityFromWeekDay(index, dayIndex)}
              >
                <IoIosClose className='text-white text-lg' />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className='flex flex-wrap justify-center gap-3'>
        <Button className='flex-1' onClick={handleToggleModal}>
          Inserir atividade
        </Button>
      </div>

      <Modal visible={showModal}>
        <div className='flex flex-col'>
          <div className='flex items-center justify-between mb-3'>
            <h2 className='text-xl font-medium'>Inserir atividade</h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
              onClick={handleToggleModal}
            >
              <FiX size={24} />
            </button>
          </div>
          <div className='flex gap-2 flex-wrap'>
            {personalTrainerExercisesAtom
              .get()
              ?.activities?.filter((a) => !data?.some((d) => d === a))
              .map((activity, index) => (
                <button
                  key={`inner-activity-${index}`}
                  className='flex gap-2 p-2 bg-gpa-blue-50 rounded-md self-start hover:bg-blue-200 active:bg-blue-300'
                  onClick={() => handleToggleActivity(activity)}
                >
                  {activity}
                  <span className='p-1 rounded-full bg-blue-400'>
                    <FiPlus className='text-white' />
                  </span>
                </button>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}
