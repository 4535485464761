type Props = {
  title: string
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const SubnavItem: React.FC<Props> = ({
  title,
  active = false,
  disabled = false,
  onClick,
}) => {
  return (
    <button
      type='button'
      data-active={active}
      data-disabled={disabled}
      className={
        'flex shrink-0 pb-2 text-sm font-semibold py-[6px] px-3 rounded-lg bg-gpa-blue-50 text-gpa-blue-500 data-[active=false]:text-black data-[active=false]:bg-white data-[active=false]:hover:bg-gpa-gray-100 data-[disabled=true]:opacity-50'
      }
      onClick={disabled ? undefined : onClick}
    >
      {title}
    </button>
  )
}
