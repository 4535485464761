import GPALogo from '~/assets/img/gpaLogo.png'
import { phoneMask } from '~/components/TextInput/utils/mask'
import { evaluationReadOnlyAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'
import { computeAgeService } from '~/services/computeAgeService'
import { convertToUTC, normalizeDate } from '~/utils/normalizeDate'

export interface HeaderProps {
  title: string
  borderBottom?: boolean
}

export const Header: React.FC<HeaderProps> = ({ title, borderBottom }) => {
  const [data] = useAtom(evaluationReadOnlyAtom)
  const [antropometry] = useAtom(evaluationReadOnlyAtom)

  return (
    <div>
      <div className='flex flex-col-2 flex-wrap justify-around mb-4'>
        <img
          src={GPALogo}
          className='max-w-[130px] object-contain'
          alt='Logo Gallo personal systems'
        />
        <div className='flex flex-col items-center'>
          <h3 className='font-bold whitespace-nowrap'>
            Avaliação {new Date(data?.createdAt || '').toLocaleDateString()}
          </h3>
          <p className='text-xs'>{data?.student.name}</p>
          <p className='text-xs'>{phoneMask(data?.student.phoneNumber[0])}</p>
        </div>
      </div>
      <div className='flex justify-center bg-zinc-300 font-bold text-xl border-[1px] border-black mt-4'>
        {title}
      </div>

      <div
        className={`grid grid-cols-2 text-xs mt-3 pb-2 border-black ${
          borderBottom ? 'border-b' : ''
        }`}
      >
        <div>
          <div className='flex gap-2'>
            <span className='font-bold'>Data da Avaliação</span>
            <span>{new Date(data?.createdAt || '').toLocaleDateString()}</span>
          </div>
          <div className='flex gap-2'>
            <span className='font-bold'>Nome</span>
            <span>{data?.student.name}</span>
          </div>

          <div className='flex justify-between pr-12'>
            <div className='flex gap-2'>
              <span className='font-bold'>Sexo</span>
              <span>{data?.student.sex}</span>
            </div>
            <div className='flex gap-2'>
              <span className='font-bold'>Peso Atual (kg)</span>
              <span>{antropometry?.currentWeight}</span>
            </div>
          </div>
        </div>

        <div className='flex gap-6'>
          <div>
            <div className='text-white'>JG</div>
            <div className='flex gap-2'>
              <span className='font-bold'>Data de Nascimento</span>
              <span>
                {new Date(
                  convertToUTC(normalizeDate(data?.student.birthday || '')),
                ).toLocaleDateString()}
              </span>
            </div>

            <div className='flex gap-2'>
              <span className='font-bold'>Estatura (cm)</span>
              <span>{antropometry?.stature}</span>
            </div>
          </div>

          <div>
            <div className='text-white'>&nbsp;</div>
            <div className='flex gap-2'>
              <span className='font-bold'>Idade</span>
              <span className=''>
                {computeAgeService(data?.student.birthday)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
