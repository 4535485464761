import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { personalHomeRoute } from '~/routes/routes'

import { UpdatePersonalTrainerForm } from './components/updatePersonalTrainerForm'

export const PersonalTrainerProfile: React.FC = () => {
  const navigate = useNavigate()
  const handleSubmitForm = useCallback(() => {
    navigate(personalHomeRoute)
  }, [navigate])

  return (
    <div className='py-5 w-full overflow-y-auto'>
      <div className='bg-white max-w-[600px] rounded-lg mx-auto p-6'>
        <h2 className='text-xl font-bold mb-3'>Atualizar Perfil</h2>
        <UpdatePersonalTrainerForm onSubmit={handleSubmitForm} />
      </div>
    </div>
  )
}
