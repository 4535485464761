import { RefObject, createRef } from 'react'

import { Anamnesis } from '~/modules/anamnesis/types'

export const questionsRefs = new Map<
  keyof Anamnesis,
  RefObject<HTMLDivElement>
>([
  ['gastrointestinalDysfunction', createRef<HTMLDivElement>()],
  ['highBloodPressure', createRef<HTMLDivElement>()],
  ['highRestingHeartRate', createRef<HTMLDivElement>()],
  ['insomnia', createRef<HTMLDivElement>()],
  ['irritability', createRef<HTMLDivElement>()],
  ['lackingAppetite', createRef<HTMLDivElement>()],
  ['lackingFocus', createRef<HTMLDivElement>()],
  ['lowerLibido', createRef<HTMLDivElement>()],
  ['muscularDamage', createRef<HTMLDivElement>()],
])
