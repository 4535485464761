import { MaskedInput } from '~/components/TextInput'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluationAtom } from '~/modules/evalutation/services'
import { useAtom } from '~/observers/jotai'

export const PenroeNelsonFisher: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)

  return (
    <div className='flex gap-3'>
      <MaskedInput
        label='Abdômen (cm)'
        placeholder='0,0'
        inputMode='numeric'
        value={evaluation?.abdomen}
        mask='float'
        onChange={(v) =>
          patchEvaluationAtom({
            abdomen: v,
          })
        }
      />
      <MaskedInput
        label='Quadril (cm)'
        inputMode='numeric'
        value={evaluation?.hip}
        placeholder='0,0'
        mask='float'
        onChange={(v) =>
          patchEvaluationAtom({
            hip: v,
          })
        }
      />
    </div>
  )
}
