import { useCallback, useEffect, useState } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { setCorporalEvaluationImageIndex } from '~/modules/corporalEvaluation/services'
import { evaluationAtom } from '~/modules/evalutation/data'
import { patchEvaluationAtom } from '~/modules/evalutation/services'

export const WetmanCol: React.FC = () => {
  const data = evaluationAtom.get()
  const [loaded, setLoaded] = useState<boolean>(false)

  const updateData = useCallback((v: string) => {
    patchEvaluationAtom({ abdomen: +v })
  }, [])

  useEffect(() => {
    patchEvaluationAtom({ abdomen: evaluationAtom.get()?.abdomen })
    setLoaded(true)
  }, [])

  const handleFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(8)
  }, [])

  return (
    <div>
      {loaded ? (
        <MaskedInput
          inputMode='numeric'
          mode='horizontal'
          label='Abdômen (cm)'
          placeholder='0,0'
          value={data?.abdomen}
          onFocus={handleFocus}
          onChange={updateData}
        />
      ) : null}
    </div>
  )
}
