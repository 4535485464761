import { FiChevronRight } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'

import { EvaluationFormPath } from '~/pages/authenticated/personalTrainer/studentEvaluation/types'

type FormStepIndicatorProps = {
  title: string
  step: number
  seen?: boolean
  last?: boolean
  path: EvaluationFormPath
}

export const FormStepIndicator: React.FC<FormStepIndicatorProps> = ({
  title,
  step,
  path,
  seen = false,
  last = false,
}) => {
  const { pathname } = useLocation()

  return (
    <div
      data-current={pathname.includes(path.split('?')[0])}
      className='rounded-full p-1 data-[current=true]:bg-gpa-blue-50'
    >
      <div className='flex shrink-0 gap-1.5 items-center cursor-pointer'>
        <div
          className={
            (seen ? 'bg-gpa-blue-500' : 'bg-gpa-gray-800') +
            ' flex shrink-0 justify-center items-center h-[30px] w-[30px] rounded-full py-2.5 px-1'
          }
        >
          <span className='font-semibold text-white'>{step}</span>
        </div>
        <p className='whitespace-nowrap text-sm'>{title}</p>
        {last ? null : (
          <div>
            <FiChevronRight className='text-gpa-gray-800' />
          </div>
        )}
      </div>
    </div>
  )
}
