import { app_version } from '~/utils/version'

import backgroundImage from '../../../assets/img/loginAdmin.webp'
import { Form } from './Form'

export const AdminLogin: React.FC = () => {
  return (
    <div className='flex h-screen bg-left-top bg-cover bg-no-repeat backdrop-filter backdrop-contrast-100'>
      <img
        src={backgroundImage}
        role='presentation'
        className='absolute z-0 h-full w-full object-cover object-right-top'
        decoding='sync'
        loading='eager'
      />
      <div className='relative z-10 flex flex-col justify-center items-center px-5 h-full w-full max-w-[550px] bg-white'>
        <div className='flex flex-1 items-center w-full'>
          <Form />
        </div>
        <p className='self-end'>{app_version}</p>
      </div>
    </div>
  )
}
