import { Header } from './Header'
import { TableScheduling } from './TableScheduling'

export const Scheduling: React.FC = () => {
  return (
    <div className='flex flex-col px-8  py-4 w-full page-break'>
      <Header title='Agendamento' borderBottom />

      <TableScheduling />
    </div>
  )
}
