import zod from 'zod'

import {
  cholesterolEnum,
  dailyPhysicalActivityEnum,
  hereditarianismEnum,
  sexAndAgeEnum,
  smokeEnum,
  weightEnum,
} from './variables'

function errorMap() {
  return {
    message: 'Campo obrigatório',
  }
}

export const coronarianRiskFactorSchema = zod.object({
  dailyPhysicalActivity: zod.enum(dailyPhysicalActivityEnum, {
    errorMap,
  }),
  weight: zod.enum(weightEnum, {
    errorMap,
  }),
  sexAndAge: zod.enum(sexAndAgeEnum, {
    errorMap,
  }),
  smoke: zod.enum(smokeEnum, {
    errorMap,
  }),
  diastolicArterialPressure: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(1, 'Valor deve ser maior que 0')
    .nonnegative('Valor não pode ser negativo'),
  systolicArterialPressure: zod
    .number({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Deve ser um número',
    })
    .min(1, 'Valor deve ser maior que 0')
    .nonnegative('Valor não pode ser negativo'),
  hereditarianism: zod.enum(hereditarianismEnum, {
    errorMap,
  }),
  cholesterol: zod.enum(cholesterolEnum, {
    errorMap,
  }),
})

export type CoronarianRiskFactorFormType = zod.infer<
  typeof coronarianRiskFactorSchema
>
