import { Sex } from '~/modules/students/types'

import { AntropometryFormType } from '../schema'

import Female from '/images/Feminino.jpg'
import Male from '/images/Masculino.jpg'
import FemaleShoulder from '/images/PF1.jpg'
import FemaleRightLeg from '/images/PF10.jpg'
import FemaleLeftArmContracted from '/images/PF11.jpg'
import FemaleRightArmContracted from '/images/PF12.jpg'
import FemaleLeftArmRelaxed from '/images/PF13.jpg'
import FemaleRightArmRelaxed from '/images/PF14.jpg'
import FemaleLeftForearm from '/images/PF15.jpg'
import FemaleRightForearm from '/images/PF16.jpg'
import FemaleLeftFist from '/images/PF17.jpg'
import FemaleRightFist from '/images/PF18.jpg'
import FemalechestInflated from '/images/PF2.jpg'
import FemaleChestRegular from '/images/PF3.jpg'
import FemaleAbdomen from '/images/PF4.jpg'
import FemaleWaist from '/images/PF5.jpg'
import FemaleHip from '/images/PF6.jpg'
import FemaleLeftThigh from '/images/PF7.jpg'
import FemaleRightThigh from '/images/PF8.jpg'
import FemaleLeftLeg from '/images/PF9.jpg'
import MaleShoulder from '/images/PM1.jpg'
import MaleLeftLeg from '/images/PM10.jpg'
import MaleLeftArmContracted from '/images/PM11.jpg'
import MaleRightArmContracted from '/images/PM12.jpg'
import MaleRightArmRelaxed from '/images/PM13.jpg'
import MaleLeftArmRexaled from '/images/PM14.jpg'
import MaleLeftForearm from '/images/PM15.jpg'
import MaleRightForearm from '/images/PM16.jpg'
import MaleLeftFist from '/images/PM17.jpg'
import MaleRightFist from '/images/PM18.jpg'
import MaleChestInflated from '/images/PM2.jpg'
import MaleChestRegular from '/images/PM3.jpg'
import MaleWaist from '/images/PM4.jpg'
import MaleAbdomen from '/images/PM5.jpg'
import MaleHip from '/images/PM6.jpg'
import MaleLeftThigh from '/images/PM7.jpg'
import MaleRightThigh from '/images/PM8.jpg'
import MaleRightLeg from '/images/PM9.jpg'

type Area = keyof Omit<
  AntropometryFormType,
  'id' | 'evaluationId' | 'createdAt' | 'updatedAt'
>

export function getSupportImageService(sex?: Sex, area?: Area): string {
  if (!sex) return Male

  if (!area) return sex === 'masculino' ? Male : Female

  if (sex === 'feminino') {
    switch (area) {
      case 'shoulder':
        return FemaleShoulder
      case 'chestInflated':
        return FemalechestInflated
      case 'abdomen':
        return FemaleAbdomen
      case 'chestRegular':
        return FemaleChestRegular
      case 'hip':
        return FemaleHip
      case 'waist':
        return FemaleWaist
      case 'rightThigh':
        return FemaleRightThigh
      case 'rightLeg':
        return FemaleRightLeg
      case 'rightArmContracted':
        return FemaleRightArmContracted
      case 'rightArmRelaxed':
        return FemaleRightArmRelaxed
      case 'rightForearm':
        return FemaleRightForearm
      case 'rightFist':
        return FemaleRightFist
      case 'leftThigh':
        return FemaleLeftThigh
      case 'leftLeg':
        return FemaleLeftLeg
      case 'leftArmContracted':
        return FemaleLeftArmContracted
      case 'leftArmRelaxed':
        return FemaleLeftArmRelaxed
      case 'leftForearm':
        return FemaleLeftForearm
      case 'leftFist':
        return FemaleLeftFist
      case 'stature':
      case 'currentWeight':
      default:
        return Female
    }
  } else {
    switch (area) {
      case 'shoulder':
        return MaleShoulder
      case 'chestInflated':
        return MaleChestInflated
      case 'chestRegular':
        return MaleChestRegular
      case 'waist':
        return MaleWaist
      case 'abdomen':
        return MaleAbdomen
      case 'hip':
        return MaleHip
      case 'rightArmContracted':
        return MaleRightArmContracted
      case 'rightArmRelaxed':
        return MaleRightArmRelaxed
      case 'rightFist':
        return MaleRightFist
      case 'rightForearm':
        return MaleRightForearm
      case 'rightLeg':
        return MaleRightLeg
      case 'rightThigh':
        return MaleRightThigh
      case 'leftArmContracted':
        return MaleLeftArmContracted
      case 'leftArmRelaxed':
        return MaleLeftArmRexaled
      case 'leftFist':
        return MaleLeftFist
      case 'leftForearm':
        return MaleLeftForearm
      case 'leftThigh':
        return MaleLeftThigh
      case 'leftLeg':
        return MaleLeftLeg
      case 'currentWeight':
      case 'stature':
      default:
        return Male
    }
  }
}
