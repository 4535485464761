import { useSyncExternalStore } from 'react'

interface IAtom<AtomType> {
  get: () => AtomType
  set: (newValue: AtomType) => void
  subscribe: (callback: (newValue: AtomType) => void) => () => void
}

type AtomCallback<AtomType> = (newValue: AtomType) => void

export function atom<AtomType>(initialValue: AtomType): IAtom<AtomType> {
  let value = initialValue

  const subscribers = new Set<AtomCallback<AtomType>>()

  const get = () => value

  const set = (newValue: AtomType) => {
    value = newValue
    subscribers.forEach((callback) => callback(value))
  }

  const subscribe = (callback: AtomCallback<AtomType>) => {
    subscribers.add(callback)

    return () => {
      subscribers.delete(callback)
    }
  }

  return {
    get,
    set,
    subscribe,
  }
}

export function useAtom<AtomType>(
  atom: IAtom<AtomType>,
): [AtomType, AtomCallback<AtomType>] {
  return [useSyncExternalStore(atom.subscribe, atom.get), atom.set]
}
