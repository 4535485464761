import { useCallback, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { InvitationLinkForm } from '~/forms/InvitationLinkForm'
import { InvitationLink } from '~/modules/invitationLinks/types'

import { invitationLinksContext } from './context'

export const Header: React.FC = () => {
  const { addNewInvitationLink } = useContext(invitationLinksContext)
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleSubmit = useCallback(
    (payload: InvitationLink) => {
      addNewInvitationLink(payload)
      setShowModal(false)
    },
    [addNewInvitationLink],
  )

  return (
    <div className='flex items-center justify-between p-2 py-3 border-b-2 bg-white border-b-slate-300'>
      <div className='ml-auto'>
        <Button onClick={handleToggleModal}>Novo Convite</Button>
      </div>

      {createPortal(
        <Modal visible={showModal}>
          <div className='flex items-center justify-between'>
            <h1 className='text-xl'>Cadastrar Link de Convite</h1>
            <button
              type='button'
              className='p-2 rounded-full hover:bg-slate-50'
              onClick={handleToggleModal}
            >
              <FiX size={26} />
            </button>
          </div>

          <InvitationLinkForm onSubmit={handleSubmit} />
        </Modal>,
        document.getElementById('modals')!,
      )}
    </div>
  )
}
