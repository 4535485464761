import { AnimatePresence } from 'framer-motion'

import { toastsAtom } from '~/modules/toasts/data'
import { useAtom } from '~/observers/jotai'

import { Toast } from './components/Toast'

export const Toaster: React.FC = () => {
  const [toasts] = useAtom(toastsAtom)

  return (
    <div
      className='fixed z-50 right-0 top-0 flex flex-col gap-3 p-3 overflow-hidden overflow-y-auto  bg-opacity-25'
      style={{ maxHeight: window.innerHeight }}
    >
      <AnimatePresence>
        {toasts.map((toast) => (
          <Toast key={`toast-${toast.id}`} data={toast} />
        ))}
      </AnimatePresence>
    </div>
  )
}
