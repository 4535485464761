import { atom } from '~/observers/jotai'

import { PeriodizedSeries } from '../evalutation/types'
import { AerobicRow, SessionMetadata } from './types'

export const aerobicMacrocycleAtom = atom<AerobicRow[]>([])

export const strengthMacrocycleAtom = atom<
  Array<PeriodizedSeries & SessionMetadata>[]
>([])
