import { AnimationPlaybackControls, motion, useAnimate } from 'framer-motion'
import { useCallback, useRef } from 'react'
import { FiX, FiXCircle } from 'react-icons/fi'

import { removeToast } from '~/modules/toasts/services'
import { ToastDTO } from '~/modules/toasts/types'

type Props = {
  data: ToastDTO
}

export const Toast: React.FC<Props> = ({ data }) => {
  const [timeAnimationScope, timerAnimation] = useAnimate()
  const timerAnimationControlsRef = useRef<AnimationPlaybackControls>()

  const handleStartTimer = useCallback(async () => {
    timerAnimationControlsRef.current = timerAnimation(
      timeAnimationScope.current,
      {
        scaleX: 0,
        originX: 0,
      },
      {
        duration: data.duration,
        onComplete() {
          removeToast(data.id)
        },
      },
    )

    timerAnimationControlsRef.current.play()
  }, [timerAnimation, data, timeAnimationScope])

  const handleDismissToast = useCallback(() => {
    removeToast(data.id)
  }, [data])

  return (
    <div className='relative flex flex-col'>
      <motion.div
        initial={{ transform: 'translate3d(100%, 0, 0)' }}
        animate={{ transform: 'translate3d(0%, 0, 0)' }}
        exit={{ transform: 'translate3d(120%, 0, 0)' }}
        transition={{
          duration: 0.4,
          ease: [0, 0.71, 0.2, 1],
        }}
        onMouseOver={() => {
          timerAnimationControlsRef.current?.pause()
        }}
        onMouseLeave={() => {
          timerAnimationControlsRef.current?.play()
        }}
        onAnimationComplete={handleStartTimer}
        data-type={data.type}
        className='shadow-lg flex flex-col w-full max-w-[550px] min-w-[300px] rounded-md text-white bg-gpa-blue-500 data-[type=warn]:bg-yellow-400 data-[type=warn]:text-black data-[type=error]:bg-gpa-red overflow-hidden'
      >
        <div
          ref={timeAnimationScope}
          className='h-1 bg-white bg-opacity-65 w-full'
        />
        <div className='flex flex-1 gap-1.5 p-1.5 '>
          {data.type === 'error' ? (
            <FiXCircle size={24} className='shrink-0 mt-1' />
          ) : null}
          <div className='flex flex-1 flex-col gap-1.5'>
            <div className='flex justify-between'>
              <span className='text-lg font-bold'>{data.title}</span>
              <button
                className='text-[inherit] p-2 self-end'
                onClick={handleDismissToast}
              >
                <FiX size={16} className='shrink-0' />
              </button>
            </div>
            <span>{data.message}</span>
          </div>
        </div>
      </motion.div>
    </div>
  )
}
