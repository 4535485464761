import { useCallback, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'

import { DeleteStudentButton } from './DeleteStudentButton'
import { UpdateStudentForm } from './updateStudentForm'

export const UpdateStudent: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>()

  const handleToggleUpdateStudentForm = useCallback(() => {
    setIsModalVisible((state) => !state)
  }, [])

  const handleSubmitForm = useCallback(async () => {
    setIsModalVisible(false)
  }, [])

  return (
    <>
      <Button
        className='flex-1'
        mode='outlined'
        onClick={handleToggleUpdateStudentForm}
      >
        Editar Aluno
      </Button>
      <Modal visible={isModalVisible}>
        <div className='flex flex-col gap-3'>
          <div className='flex items-center justify-between'>
            <h2 className='text-xl font-medium'>Dados atuais do avaliado</h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
              onClick={handleToggleUpdateStudentForm}
            >
              <FiX size={24} />
            </button>
          </div>
          <UpdateStudentForm onSubmit={handleSubmitForm} />
          <div className='flex justify-between mt-3'>
            <DeleteStudentButton onSubmit={handleToggleUpdateStudentForm} />
            <Button mode='outlined' onClick={handleToggleUpdateStudentForm}>
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
