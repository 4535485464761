import zod from 'zod'

export const brunoBalkSchema = zod.object({
  time: zod.number().min(1),
  distance: zod.number().min(1),
})
export const cooperSchema = zod.object({
  distance: zod.number().min(1),
})
export const margariaSchema = zod.object({
  time: zod.number().min(1),
  distance: zod.number().min(1),
})
export const rockportSchema = zod.object({
  time: zod.number().min(1),
  heartRate: zod.number().min(1),
})
export const medicalSchema = zod.object({
  maxVO2Consumption: zod.number().min(1),
  heartRate: zod.number().min(1),
})
export const ukkSchema = zod.object({
  time: zod.number().min(1),
  heartRate: zod.number().min(1),
})
export const ownIndexSchema = zod.object({
  maxVO2Consumption: zod.number().min(1),
})
export const targetVO2SChema = zod.number().min(1)
export const dukeSchema = zod.array(zod.boolean()).length(12)
export const slopeSchema = zod.object({
  vsaq: zod.number().min(1),
  time: zod.string(),
  speed: zod.number().min(1),
  steepness: zod.number().min(1),
})

export type BrunoBalkProtocol = zod.infer<typeof brunoBalkSchema>
export type MargariaProtocol = zod.infer<typeof margariaSchema>
export type CooperProtocol = zod.infer<typeof cooperSchema>
export type RockportProtocol = zod.infer<typeof rockportSchema>
export type UKKProtocol = zod.infer<typeof ukkSchema>
export type MedicalProtocol = zod.infer<typeof medicalSchema>
export type OwnIndexProtocol = zod.infer<typeof ownIndexSchema>
export type DukeProtocol = zod.infer<typeof dukeSchema>
export type SlopeProtocol = zod.infer<typeof slopeSchema>
export type TargetVO2Protocol = zod.infer<typeof targetVO2SChema>
