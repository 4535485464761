import { useCallback, useState } from 'react'

import { Button } from '~/components/Button'
import {
  patchPersonalTrainerExercises,
  recoverDefaultActivities,
} from '~/modules/personalTrainerExercises/services'

export const RecoverStandardActivitiesButton: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleRecoverDefaultActivities = useCallback(async () => {
    const activities = recoverDefaultActivities()

    if (activities) {
      setLoading(true)
      patchPersonalTrainerExercises({ activities }).finally(() => {
        setLoading(false)
      })
    }
  }, [])
  return (
    <Button
      disabled={loading}
      loading={loading}
      type='button'
      onClick={handleRecoverDefaultActivities}
    >
      Recuperar atividades padrão do GPA
    </Button>
  )
}
